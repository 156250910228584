const responseError = (error : any) => {

    let message: string  = error.response.data.message;

    const status: number  = error.response.status;

    switch (status) {
        case 401:
            sessionStorage.removeItem("sys-token");
            window.location.replace("/sign");
            break;
        case 403:
            window.location.replace("/403");
            break;
        case 404:
            window.location.replace("/404");
            break;
        default:
            message = (typeof message === "object" ? message[0]:message);
            break;
    }

    return message;
}

export default responseError;