import {
  EditOutlined as EditOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from "@mui/icons-material";
import {
  Grid,
  Typography,
  Stack,
  List,
  useMediaQuery,
  ListItem,
} from "@mui/material";
import { Theme } from "@mui/material/styles";

import MainCard from "components/MainCard";
import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks";
import DeletLanguage from "./deleteSkillsDialog";
import EditLanguageDialog from "./editDialogSkills";
import { getAllLanguage } from "store/actions/main/basicInfo/languages";
import { Language } from "model/cv/language/language";

export function TableSkill({
  cvId,
  skillData,
  setLoading,
  loading,
  fetchGetOneSkill,
}: {
  setLoading: any;
  loading: any;
  fetchGetOneSkill: any;
  cvId: number;
  skillData: any;
}) {
  const languageArray = skillData.resume_skills;
  // Filter education certificates based on cvId
  console.log(languageArray);
  const matchDownMD = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );
  const [deletId, setDeletId] = useState(0);
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState<Language>();
  const [openDialog, setOpenDialog] = useState(false);
  const [languageName, setLanguageName] = useState("");
  const dispatch = useAppDispatch();

  const handleEdit = (id: number) => {
    setDeletId(id);
    setOpenDialog(true);
  };
  const handleDelete = (id: number) => {
    setDeletId(id);
    setOpen(true);
  };
  useEffect(() => {
    const fetchName = async () => {
      const action = await dispatch(getAllLanguage({ pageNumber: 1 }));
      const { data } = action.payload;

      // Process availability names
      const availabilityNames = languageArray.map((job: any) => {
        const availability = data.find((av: any) => av.id === job.language_id);
        return availability ? availability.name : "";
      });

      // Combine names into a single string or format as needed
      const combinedNames = availabilityNames.join(", ");
      setLanguageName(combinedNames);
    };

    fetchName();
  }, [skillData]);
  return (
    <>
      <Stack>
        {languageArray.map((skill: any, index: number) => (
          <Grid width={"57vw"} sx={{ mt: "10px" }}>
            <MainCard
              title="Skill"
              headerActions={
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <EditOutlinedIcon
                      sx={{
                        width: 20,
                        height: 20,
                        marginRight: 2,
                        cursor: "pointer",
                        color: "#4a934a",
                      }}
                      onClick={() => handleEdit(skill.id)}
                    />
                    <DeleteOutlinedIcon
                      sx={{
                        width: 20,
                        height: 20,
                        cursor: "pointer",
                        color: "#ff3333",
                        marginRight: "20px",
                      }}
                      onClick={() => handleDelete(skill.id)}
                    />
                  </div>
                  {/* Add additional controls here */}
                </Stack>
              }
            >
              <List>
                <ListItem divider={!matchDownMD}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography color="secondary">Skill Name</Typography>

                        <Typography>{skill.skill_name}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography color="secondary">Level</Typography>
                        <Typography>{skill.skill_level}</Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography color="secondary">
                          Years of Experienc
                        </Typography>
                        <Typography>{skill.experience_years}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography color="secondary">Last Used</Typography>
                        <Typography>{skill.last_used}</Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </MainCard>
          </Grid>
        ))}
      </Stack>
      {open && (
        <DeletLanguage
          open={open}
          setOpen={setOpen}
          id={deletId}
          fetchGetOneSkill={fetchGetOneSkill}
        />
      )}
      {openDialog && (
        <EditLanguageDialog
          resumeId={cvId}
          cvId={deletId}
          open={openDialog}
          setOpen={setOpenDialog}
          language={language}
          setLoading={setLoading}
          loading={loading}
          fetchGetOneSkill={fetchGetOneSkill}
          setLanguage={setLanguage}
        />
      )}
    </>
  );
}
