import axios from "axios";

const BASE_URL = "https://test.syriajob.com/public/api/admin/";

export const token = sessionStorage.getItem("token");
console.log(token);

const LANG = sessionStorage.getItem("lang");

const CONFIG = {
  Authorization: `Bearer ${token}`,
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Language: LANG,
};

export const AXIOS = axios.create({
  baseURL: BASE_URL,
  headers: CONFIG,
});
