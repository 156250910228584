import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Country, CountryState, ParentCountry } from "model/country";
import { RoleState, RolesModel } from "model/roles";
import {
  addNewRoles,
  deleteRoles,
  editRole,
  getAllRoles,
  getOneRole,
  getPermission,
} from "store/actions/main/roles";

const initialState: RoleState = {
  result: [],
  isOk: false,
  message: "",
  confirmCode: "",
};

const rolesSlice = createSlice({
  name: "roles",
  initialState: initialState,
  reducers: {
    resetStateStatus: (state) => {
      state.isOk = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllRoles.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getAllRoles.fulfilled.type,
        (state, action: PayloadAction<RolesModel[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewRoles.fulfilled.type,
        (state, action: PayloadAction<RolesModel[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewRoles.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getOneRole.fulfilled.type,
        (state, action: PayloadAction<RolesModel[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        getOneRole.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editRole.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editRole.fulfilled.type,
        (state, action: PayloadAction<RolesModel[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        deleteRoles.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        deleteRoles.fulfilled.type,
        (state, action: PayloadAction<RolesModel[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        getPermission.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getPermission.fulfilled.type,
        (state, action: PayloadAction<any>) => {
          state.result = action.payload;
        },
      );
  },
});

export const { resetStateStatus } = rolesSlice.actions;

export default rolesSlice;
