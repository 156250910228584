import { useEffect, useState, ChangeEvent } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  RadioGroup,
  Radio,
  Autocomplete,
  Chip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

// third-party
import _, { values } from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project imports
import AlertCustomerDelete from "./AlertCustomerDelete";
import IconButton from "components/@extended/IconButton";
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets
import { CameraOutlined, CloseOutlined, DeleteFilled } from "@ant-design/icons";

// types
import { ThemeMode } from "types/config";
import { SnackbarProps } from "types/snackbar";
import { CustomerList, Gender } from "types/customer";
import { useAppDispatch } from "hooks";
import {
  ParentCategories,
  addNewCategory,
  editOneCategory,
  getOneCategory,
} from "store/actions/main/categories";
import { ParentCat } from "model/categories";
import categories from "utils/url/categories";

interface StatusProps {
  value: number;
  label: string;
}

const avatarImage = require.context("assets/images/users", true);

const skills = [
  "Adobe XD",
  "After Effect",
  "Angular",
  "Animation",
  "ASP.Net",
  "Bootstrap",
  "C#",
  "CC",
  "Corel Draw",
  "CSS",
  "DIV",
  "Dreamweaver",
  "Figma",
  "Graphics",
  "HTML",
  "Illustrator",
  "J2Ee",
  "Java",
  "Javascript",
  "JQuery",
  "Logo Design",
  "Material UI",
  "Motion",
  "MVC",
  "MySQL",
  "NodeJS",
  "npm",
  "Photoshop",
  "PHP",
  "React",
  "Redux",
  "Reduxjs & tooltit",
  "SASS",
  "SCSS",
  "SQL Server",
  "SVG",
  "UI/UX",
  "User Interface Designing",
  "Wordpress",
];

// constant
const getInitialValues = (customer: CustomerList | null) => {
  const newCustomer = {
    parent_id: "",
    name: "",
    order: "",
    is_active: "",
  };
  if (customer) {
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};
const allStatus: StatusProps[] = [
  { value: 3, label: "Rejected" },
  { value: 1, label: "Verified" },
  { value: 2, label: "Pending" },
];

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormCustomerAdd = ({
  customer,
  id,
  loading,
  fetchData,
  setLoading,
  closeModal,
}: {
  loading: any;
  id: number;
  setLoading: any;
  fetchData: any;
  customer: CustomerList | null;
  closeModal: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [categories, setCategories] = useState<ParentCat[]>([]);
  const [selectedParentCat, setSelectedParentCat] = useState<ParentCat | null>(
    null,
  );

  const fetchCatData = (url?: string) => {
    dispatch(ParentCategories())
      .then((action) => {
        const { data, links, meta } = action.payload;
        const fetchedUserData: ParentCat[] = data;
        const rowsWithKeys = fetchedUserData.map((user) => ({
          ...user,
          key: user.id,
        }));
        setLoading(false);
        setCategories(rowsWithKeys);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  useEffect(() => {
    fetchCatData();
  }, [dispatch]);
  useEffect(() => {
    setLoading(false);

    // Initialize selectedParentCountryId and selectedParentCountry if customer exists
    if (customer && customer.parent_name) {
      const selectedCat = categories.find(
        (country) => country.name === customer.parent_name,
      );
      if (selectedCat) {
        setSelectedParentCat(selectedCat);
        setSelectedParentCategoryId(selectedCat.id);
      }
    }
  }, [dispatch, id, customer, categories]);

  const [selectedParentCategoryId, setSelectedParentCategoryId] = useState<
    number | null
  >(null);
  const CustomerSchema = Yup.object().shape({
    // parent_id: Yup.string().required("Parent Name is required"),
    name: Yup.string().required("Name is required"),
    order: Yup.number().required("Order is required"),
    is_active: Yup.string().required("Status is required"),
  });

  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };
  const handleChangeParentName = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value as string;
    const selectedCat = categories.find((cat) => cat.name === selectedValue);
    setSelectedParentCategoryId(selectedCat ? selectedCat.id : null);
    setSelectedParentCat(selectedCat); // Update selected parent country state
  };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editOneCategory({
              categoryId: customer.id || 0,
              data: {
                parent_id: selectedParentCategoryId,
                name: values.name,
                order: values.order,
                is_active: values.is_active,
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "category/editOneCategory/fulfilled") {
              openSnackbar({
                open: true,
                message: "Category edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(
            addNewCategory({
              parent_id: selectedParentCategoryId,
              name: values.name,
              order: values.order,
              is_active: values.is_active,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "category/addNewCategory/fulfilled") {
              openSnackbar({
                open: true,
                message: "Category added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;
  const { value, onChange, onBlur } = getFieldProps("");
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>
              {customer ? "Edit Category" : "New Category"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-parent_name">
                          Parent Name
                        </InputLabel>
                        <Select
                          id="customer-parent_name"
                          displayEmpty
                          value={
                            selectedParentCat
                              ? selectedParentCat.name
                              : customer?.parent_name
                          }
                          onChange={handleChangeParentName}
                          onBlur={onBlur}
                          input={
                            <OutlinedInput
                              id="select-column-hiding"
                              placeholder="Sort by"
                            />
                          }
                          renderValue={(selected) => {
                            if (!selected) {
                              return (
                                <Typography variant="subtitle1"></Typography>
                              );
                            }
                            const cat = categories.find(
                              (item) => item.name === selected,
                            );
                            return (
                              <Typography variant="subtitle2">
                                {cat ? cat.name : ""}
                              </Typography>
                            );
                          }}
                        >
                          {categories.map((column: any) => (
                            <MenuItem key={column.id} value={column.name}>
                              <ListItemText primary={column.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-name">Name</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-name"
                          placeholder="Enter Name"
                          {...getFieldProps("name")}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-order">Order</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-order"
                          placeholder="Enter Order"
                          {...getFieldProps("order")}
                          error={Boolean(touched.order && errors.order)}
                          helperText={touched.order && errors.order}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-is_active">
                          Status
                        </InputLabel>
                        <FormControl fullWidth>
                          <Select
                            id="customer-is_active"
                            displayEmpty
                            {...getFieldProps("is_active")}
                            onChange={(event: SelectChangeEvent<string>) => {
                              // Get the selected value from the event
                              const selectedValue = event.target
                                .value as string;
                              // Set the field value to the selected value
                              setFieldValue("is_active", selectedValue);
                            }}
                          >
                            <MenuItem value={false}>Inactive</MenuItem>
                            <MenuItem value={true}>Active</MenuItem>
                          </Select>
                        </FormControl>
                        {touched.is_active && errors.is_active && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{ pl: 1.75 }}
                          >
                            {errors.is_active}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton
                        onClick={() => setOpenAlert(true)}
                        size="large"
                        color="error"
                      >
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? "Edit" : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {customer && (
        <AlertCustomerDelete
          fetchData={fetchCatData}
          id={customer.id!}
          title={customer.name}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default FormCustomerAdd;
