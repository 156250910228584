const GET_ALL_CURRENCY = "currencies";

const ADD_CURRENCY = "currencies";

const GET_ONE_CURRENCY = "currencies/";

const EDIT_CURRENCY = "currencies/";

const DELETE_CURRENCY = "currencies/";

const MULTI_DELET_CURRENCY = "currencies/remove/bulk_delete";

export default {
  GET_ALL_CURRENCY,
  GET_ONE_CURRENCY,
  ADD_CURRENCY,
  EDIT_CURRENCY,
  DELETE_CURRENCY,
  MULTI_DELET_CURRENCY,
};
