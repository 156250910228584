import { RefObject, useEffect, useState } from "react";
import { useOutletContext } from "react-router";

// material-ui
import {
  Autocomplete,
  Box,
  Button,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project import
import { openSnackbar } from "api/snackbar";

import MainCard from "components/MainCard";
import { useAppDispatch } from "hooks";
import { getAllCompanies } from "store/actions/main/companies";
import { SnackbarProps } from "types/snackbar";
import { addNewRecoResumes } from "store/actions/main/cv/recomendation/recommendation";

// types
const validationSchema = Yup.object().shape({
  recommender_name: Yup.string().required("Recommender Name is required"),
  position: Yup.string().required("Position id required"),
  company_name: Yup.string().required("Company Name id required"),
  job_title: Yup.string().required("Job Title is required"),
  mobile: Yup.string().required("Mobile is required"),

  email: Yup.string().required("Email Year is required"),
});
function RecommendationsDialog({
  cvId,
  openReco,
  setOpenReco,
  setLoading,
  loading,
  fetchGetOneReco,
}: {
  setLoading: any;
  loading: any;
  fetchGetOneReco: any;
  cvId: any;
  openReco: boolean;
  setOpenReco: (open: boolean) => void;
}) {
  const handleClose = () => {
    setOpenReco(false);
  };
  const [recoData, setRecoData] = useState({
    resume_id: 0,
    user_id: 0,
    recommender_name: "",
    position: "",
    company_name: "",
    job_title: "",
    mobile: "",
    email: "",
    status: false,
    read_by_admin: false,
  });
  const [selectedStaus, setSelectedStatus] = useState(false);
  const [selectedRead, setSelectedRead] = useState(false);

  const dispatch = useAppDispatch();
  const fetchCompanyData = () => {
    dispatch(getAllCompanies({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setSelectedCompany(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };

  useEffect(() => {
    fetchCompanyData();
  }, [dispatch]);
  const [formErrors, setFormErrors] = useState({});
  const validate = async () => {
    try {
      // Combine all data to be validated
      const dataToValidate = {
        ...recoData,
        // Include selected job availability ID
        status: selectedStaus,
        read_by_admin: selectedRead,
      };

      // Validate the combined data against the validation schema
      await validationSchema.validate(dataToValidate, { abortEarly: false });
      setFormErrors({});
      return true; // Return true if validation succeeds
    } catch (errors) {
      // If validation fails, extract error messages
      const errorMessages = {};
      errors.inner.forEach((error) => {
        errorMessages[error.path] = error.message;
      });
      setFormErrors(errorMessages); // Set error messages for each field
      return false;
    }
  };

  const handleSave = async () => {
    // Log average value for debugging

    const isValid = await validate();
    if (isValid) setLoading(true);
    dispatch(
      addNewRecoResumes({
        resume_id: cvId,

        recommender_name: recoData.recommender_name,
        position: recoData.position,
        company_name: recoData.company_name,
        job_title: recoData.job_title,
        mobile: recoData.mobile,
        email: recoData.email,
        status: selectedStaus,
        read_by_admin: selectedRead,
      }),
    ).then((action) => {
      if (action.type === "recommendation/addNewRecoResumes/fulfilled") {
        openSnackbar({
          open: true,
          message: "Recommendation Added successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);
        setLoading(false);
        setOpenReco(false);
        fetchGetOneReco();
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
        setLoading(false);
      }
    });
  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setRecoData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Dialog open={openReco} onClose={handleClose}>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <DialogTitle style={{ fontWeight: "bold" }}>Refrences</DialogTitle>
        <CloseIcon
          style={{
            marginTop: "12px",
            marginRight: "20px",
            width: "20px",
            height: "20px",
            cursor: "pointer",
          }}
          onClick={() => setOpenReco(false)}
        />
      </Stack>

      <DialogContent>
        <Box sx={{ p: 2.5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-recommender_name">
                  Refrencer Name
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-recommender_name"
                  value={recoData?.recommender_name || ""}
                  name="recommender_name"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Recommender Name"
                  autoFocus
                  //inputRef={inputRef}
                />
                {formErrors.recommender_name && (
                  <FormHelperText error>
                    {formErrors.recommender_name}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-position">
                  Job Position
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-position"
                  value={recoData?.position || ""}
                  name="position"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Job Position"
                />
                {formErrors.position && (
                  <FormHelperText error>{formErrors.position}</FormHelperText>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-company_name">
                  Company Name
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-company_name"
                  value={recoData?.company_name || ""}
                  name="company_name"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Company Name"
                />
                {formErrors.company_name && (
                  <FormHelperText error>
                    {formErrors.company_name}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-job_title">Job Title</InputLabel>
                <TextField
                  fullWidth
                  id="personal-job_title"
                  value={recoData?.job_title || ""}
                  name="job_title"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Job Title"
                />
                {formErrors.job_title && (
                  <FormHelperText error>{formErrors.job_title}</FormHelperText>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-mobile">Mobile Number</InputLabel>
                <TextField
                  type="mobile"
                  fullWidth
                  value={recoData?.mobile || ""}
                  name="mobile"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  id="personal-email"
                  placeholder="Mobile Number"
                />
                {formErrors.mobile && (
                  <FormHelperText error>{formErrors.mobile}</FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-email">Email</InputLabel>
                <TextField
                  fullWidth
                  id="personal-email"
                  value={recoData?.email || ""}
                  name="email"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Email"
                />
                {formErrors.email && (
                  <FormHelperText error>{formErrors.email}</FormHelperText>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-staus">Status</InputLabel>
                <Select
                  id="staus"
                  value={selectedStaus} // Set the selected ID as the value of the Select component
                  onChange={(event) => setSelectedStatus(event.target.value)} // Handle the change event
                >
                  <MenuItem value={true}>true</MenuItem>
                  <MenuItem value={false}>false</MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-staus">Read By Admin</InputLabel>
                <Select
                  id="read_by_admin"
                  value={selectedRead} // Set the selected ID as the value of the Select component
                  onChange={(event) => setSelectedRead(event.target.value)} // Handle the change event
                >
                  <MenuItem value={true}>true</MenuItem>
                  <MenuItem value={false}>false</MenuItem>
                </Select>
              </Stack>
            </Grid>
          </Grid>
          <Stack display={"flex"} alignItems={"end"} marginTop={"20px"}>
            <Button type="submit" variant="contained" onClick={handleSave}>
              Add Refernce
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default RecommendationsDialog;
