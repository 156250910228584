//react
import { useState, useEffect } from "react";

//redux
import { useAppDispatch } from "../../hooks";
import {
  getAllCompanies,
  totalAllCompanies,
} from "store/actions/main/companies";
import { getAllUsers, totalUsers } from "store/actions/main/users";
import { totalALLCV } from "store/actions/main/cv/basicinfo/basicInfo";
import { totalALLCVOrder } from "store/actions/main/order/resumeOrder";
import { getAllResumes } from "store/actions/main/packages/resumesPackages";

const TOKEN: string | null = sessionStorage.getItem("token");
const useFetchPrimaryData = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    //fetch data
    async function fetchData() {
      try {
        //get data

        if (TOKEN) await dispatch(getAllCompanies({ pageNumber: 10 }));
        await dispatch(getAllUsers({ pageNumber: 10 }));
        await dispatch(totalUsers());
        await dispatch(totalALLCV());
        await dispatch(totalALLCVOrder());
        await dispatch(totalAllCompanies());
        await dispatch(getAllUsers({ pageNumber: 10 }));
        await dispatch(getAllResumes({ pageNumber: 10 }));

        //reset status

        // dispatch(resetPoliciesStatus());

        //set loading
        setLoading(true);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return [loading];
};

export default useFetchPrimaryData;
