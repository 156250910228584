import { createAsyncThunk } from "@reduxjs/toolkit";
import rolesURL from "../../../utils/url/roles";
//axios
import { AXIOS } from "../../../api/axios";

//error message
import responseError from "../../../utils/api/error-handling";

//get AllUser
export const getAllRoles = createAsyncThunk(
  "role/getAllRoles",
  async (_, thunkAPI) => {
    try {
      const response = await AXIOS.get(rolesURL.GET_ROLES);
      console.log(response.data.data);
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//GET ONE USER

export const getOneRole = createAsyncThunk(
  "role/getOneRole",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await AXIOS.get(`${rolesURL.GET_ROLE}${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editRole = createAsyncThunk(
  "role/editRole",
  async ({ id, data }: { id: number; data: any }, thunkAPI) => {
    try {
      const response = await AXIOS.post(`${rolesURL.EDIT_ROLE}${id}`, data);
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addNewRoles = createAsyncThunk(
  "role/addNewRoles",
  async (data: any, thunkAPI) => {
    try {
      const response = await AXIOS.post(rolesURL.ADD_ROLE, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteRoles = createAsyncThunk(
  "roles/deleteRoles",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(`${rolesURL.DELETE_ROLE}${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const getPermission = createAsyncThunk(
  "roles/getPermission",
  async (_, thunkAPI) => {
    try {
      const response = await AXIOS.get(rolesURL.PERMISSIONS);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
