const GET_BANNERS = "banners";

const ADD_BANNERS = "banners";
const GET_ONE_BANNER = "banners/";

const EDIT_BANNER = "banners/";

const DELETE_BANNER = "banners/";

const ACIVATE_BANNER = "banners/activate/";

const DEACTIVATE_BANNER = "banners/deactivate/";

const MULTI_DELET_BANNER = "banners/remove/bulk_delete";

export default {
  GET_BANNERS,
  ADD_BANNERS,
  GET_ONE_BANNER,
  DELETE_BANNER,
  EDIT_BANNER,

  ACIVATE_BANNER,

  DEACTIVATE_BANNER,

  MULTI_DELET_BANNER,
};
