const GET_VACANCIES_PACKAGES = "vacancy_packages";

const ADD_VACANCIES_PACKAGES = "vacancy_packages";

const EDIT_VACANCIES_PACKAGES = "vacancy_packages/";

const GET_ONE_VACANCIES_PACKAGES = "vacancy_packages/";

const DELETE_VACANCIES_PACKAGES = "vacancy_packages/";

const MULTI_DELET = "vacancy_packages/remove/bulk_delete";

export default {
  GET_VACANCIES_PACKAGES,

  GET_ONE_VACANCIES_PACKAGES,

  ADD_VACANCIES_PACKAGES,

  EDIT_VACANCIES_PACKAGES,

  DELETE_VACANCIES_PACKAGES,

  MULTI_DELET,
};
