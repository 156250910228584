import { createAsyncThunk } from "@reduxjs/toolkit";
import userUrl from "../../../utils/url/user";
//axios
import { AXIOS } from "../../../api/axios";

//error message
import responseError from "../../../utils/api/error-handling";

//get AllUser
export const getAllUsers = createAsyncThunk(
  "user/getUsers",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `${userUrl.GET_USER}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneUser = createAsyncThunk(
  "user/getOneUser",
  async ({ userId }: { userId: string }, thunkAPI) => {
    try {
      const response = await AXIOS.get(`${userUrl.GET_ONE_USER}${userId}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editUser = createAsyncThunk(
  "user/editOneUser",
  async ({ userId, data }: { userId: number; data: any }, thunkAPI) => {
    try {
      const response = await AXIOS.post(`${userUrl.EDIT_USER}${userId}`, data);
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addNewUser = createAsyncThunk(
  "user/addUser",
  async (data: any, thunkAPI) => {
    try {
      const response = await AXIOS.post(userUrl.GET_USER, data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async ({ userId }: { userId: number }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(`${userUrl.GET_ONE_USER}${userId}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const activeAccount = createAsyncThunk(
  "user/activeAccount",
  async ({ userId }: { userId: number }, thunkAPI) => {
    try {
      const response = await AXIOS.post(`${userUrl.ACIVATE_ACCOUNT}${userId}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deactivateAccount = createAsyncThunk(
  "user/deactivateAccount",
  async ({ userId }: { userId: number }, thunkAPI) => {
    try {
      const response = await AXIOS.post(
        `${userUrl.DEACTIVATE_ACCOUNT}${userId}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletUsers = createAsyncThunk(
  "user/multiDeletUsers",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(userUrl.MULTI_DELET, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const FilterUsers = createAsyncThunk(
  "user/filtertUsers",
  async (
    {
      account_status,
      account_language,
      created_at_start,
      created_at_end,
    }: {
      account_status: string;
      account_language?: string;
      created_at_start?: Date | null;
      created_at_end?: Date | null;
    },
    thunkAPI,
  ) => {
    try {
      const data = {
        account_status,
        account_language,
        created_at_start,
        created_at_end,
      };
      const response = await AXIOS.post(userUrl.FILTER_USER, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const SearchUsers = createAsyncThunk(
  "user/searchtUsers",
  async ({ data }: { data: any }, thunkAPI) => {
    try {
      const response = await AXIOS.get(`${userUrl.SEARCH_USERS}${data}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const totalUsers = createAsyncThunk(
  "user/totalUsers",
  async (_, thunkAPI) => {
    try {
      const response = await AXIOS.get(userUrl.GET_TOTAL);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
