import {
  EditOutlined as EditOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from "@mui/icons-material";
import {
  Grid,
  Typography,
  Stack,
  List,
  useMediaQuery,
  ListItem,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import MainCard from "components/MainCard";
import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks";
import { getOneExperience } from "store/actions/main/cv/experience/experience";
import DeletExperience from "./deleteExperienceDialog";
import EditExperienceDialog from "./editDialogExperience";
import { WorkExperience } from "model/cv/workExperience/workExperience";
import { getAllJobType } from "store/actions/main/basicInfo/jobTypes";
import { getAllCategories } from "store/actions/main/categories";
import Loader from "components/Loader";

export function TableExperience({
  cvId,
  setLoading,
  loading,
  educationCertificates,
  fetchGetOneExp,
}: {
  fetchGetOneExp: any;
  setLoading: any;
  loading: any;
  cvId: number;
  educationCertificates: any;
}) {
  const experience = educationCertificates.resume_experiences;
  // Filter education certificates based on cvId

  const matchDownMD = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );
  const [deletId, setDeletId] = useState(0);
  const [open, setOpen] = useState(false);
  const [experienceData, setExperienceData] = useState<WorkExperience>();
  const [openDialog, setOpenDialog] = useState(false);
  const [experiencName, setExperiencName] = useState("");
  const [cat, setCat] = useState("");
  const handleEdit = (id: number) => {
    setDeletId(id);
    setOpenDialog(true);
  };
  const handleDelete = (id: number) => {
    setDeletId(id);
    setOpen(true);
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchName = async () => {
      const action = await dispatch(getAllJobType({ pageNumber: 1 }));
      const { data } = action.payload;

      // Process availability names
      const availabilityNames = experience.map((job: any) => {
        const availability = data.find((av: any) => av.id === job.job_type_id);
        return availability ? availability.name : "";
      });

      // Combine names into a single string or format as needed
      const combinedNames = availabilityNames.join(", ");
      setExperiencName(combinedNames);
    };
    const fetchDegree = async () => {
      const action = await dispatch(getAllCategories({ pageNumber: 1 }));
      const { data } = action.payload;

      // Process availability names
      const availabilityNames = experience.map((job: any) => {
        const availability = data.find((av: any) => av.id === job.category_id);
        return availability ? availability.name : "";
      });

      // Combine names into a single string or format as needed
      const combinedNames = availabilityNames.join(", ");
      setCat(combinedNames);
    };
    fetchName();
    fetchDegree();
  }, [experience]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Stack>
          {experience.map((experience: any, index: number) => (
            <Grid width={"57vw"} sx={{ mt: "10px" }}>
              <MainCard
                title="Work Experience"
                headerActions={
                  <Stack
                    direction={"row"}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <EditOutlinedIcon
                        sx={{
                          width: 20,
                          height: 20,
                          marginRight: 2,
                          cursor: "pointer",
                          color: "#4a934a",
                        }}
                        onClick={() => handleEdit(experience.id)}
                      />
                      <DeleteOutlinedIcon
                        sx={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          color: "#ff3333",
                          marginRight: "20px",
                        }}
                        onClick={() => handleDelete(experience.id)}
                      />
                    </div>
                    {/* Add additional controls here */}
                  </Stack>
                }
              >
                <List>
                  <ListItem divider={!matchDownMD}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Job Type</Typography>

                          <Typography>{experiencName}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">
                            Field of work
                          </Typography>
                          <Typography>{cat}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem divider={!matchDownMD}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Job Title</Typography>
                          <Typography>{experience.job_title}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">
                            Work Hours Weekly
                          </Typography>
                          <Typography>
                            {experience.work_hours_weekly}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem divider={!matchDownMD}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">
                            Company Name
                          </Typography>
                          <Typography>{experience.company_name}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Net Salary</Typography>
                          <Typography>{experience.salary}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem divider={!matchDownMD}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Country</Typography>
                          <Typography>{experience.country}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">City</Typography>
                          <Typography>{experience.city}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem divider={!matchDownMD}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Start Date</Typography>
                          <Typography>{experience.started_at}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">End Date</Typography>
                          <Typography>{experience.ended_at}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">
                            Responsibilties
                          </Typography>
                          <Typography>
                            {experience.job_responsibilties}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">
                            Company Description
                          </Typography>
                          <Typography>
                            {experience.company_description}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </MainCard>
            </Grid>
          ))}
        </Stack>
      )}

      {open && <DeletExperience open={open} setOpen={setOpen} id={deletId} />}
      {openDialog && (
        <EditExperienceDialog
          fetchGetOneExp={fetchGetOneExp}
          resumeId={cvId}
          cvId={deletId}
          open={openDialog}
          setOpen={setOpenDialog}
          experienceData={experienceData}
          setExperienceData={setExperienceData}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </>
  );
}
