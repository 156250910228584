import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import banersURL from "../../../../utils/url/ads/banners";

//get AllUser
export const getAllBanners = createAsyncThunk(
  "ads/getAllBanners",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${banersURL.GET_BANNERS}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneBanner = createAsyncThunk(
  "ads/getOneBanner",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(`${banersURL.GET_ONE_BANNER}${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editBanner = createAsyncThunk(
  "ads/editBanner",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${banersURL.EDIT_BANNER}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addBanners = createAsyncThunk(
  "ads/addBanners",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(banersURL.ADD_BANNERS, data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteBanner = createAsyncThunk(
  "ads/deleteBanner",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${banersURL.DELETE_BANNER}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const activeBanners = createAsyncThunk(
  "ads/activeAccount",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(`${banersURL.ACIVATE_BANNER}${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deactivateActiveBanners = createAsyncThunk(
  "ads/deactivateAccount",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${banersURL.DEACTIVATE_BANNER}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletBanners = createAsyncThunk(
  "ads/MultiDeletBanners",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(banersURL.MULTI_DELET_BANNER, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
