import PaymentIcon from "@mui/icons-material/Payment";

import { FormattedMessage } from "react-intl";

const usePaymentItems = () => {
  const icons = {
    PaymentIcon,
  };

  const paymeItems = {
    id: "group-payment",
    title: (
      <FormattedMessage id="payment-mangment">
        {(text) => (
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>{text}</span>
        )}
      </FormattedMessage>
    ),
    type: "group",
    children: [
      {
        id: "payment",
        title: <FormattedMessage id="payment" />,
        type: "item",
        url: "/payment",
        icon: icons.PaymentIcon,
        breadcrumbs: false,
      },
    ],
  };

  return paymeItems;
};

export default usePaymentItems;
