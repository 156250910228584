import axios from "axios";
import { setSession } from "contexts/JWTContext";
import { SET_TOKEN } from "contexts/auth-reducer/actions";

const USER_URL = "https://test.syriajob.com/public/api/";

export const token = sessionStorage.getItem("token");
console.log(token);

const LANG = sessionStorage.getItem("lang");
console.log(SET_TOKEN);
const CONFIG = {
  Authorization: `Bearer ${SET_TOKEN}`,
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Language: LANG,
};

export const USERAXIOS = axios.create({
  baseURL: USER_URL,
  headers: CONFIG,
});
