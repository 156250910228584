import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { JobResponse, JobTypeState } from "model/cv/targeJob/targetJob";
import {
  addNewTargetJob,
  deleteTargetJob,
  editTargetJob,
  getAllTargetJob,
  getOneTargetJob,
} from "store/actions/main/cv/requiredJob/requiredJob";

const initialState: JobTypeState = {
  result: [],
  isOk: false,
  message: "",
  confirmCode: "",
};

const reqJobSlice = createSlice({
  name: "reqJob",
  initialState: initialState,
  reducers: {
    resetStateStatus: (state) => {
      state.isOk = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllTargetJob.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getAllTargetJob.fulfilled.type,
        (state, action: PayloadAction<JobResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewTargetJob.fulfilled.type,
        (state, action: PayloadAction<JobResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewTargetJob.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getOneTargetJob.fulfilled.type,
        (state, action: PayloadAction<JobResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        getOneTargetJob.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editTargetJob.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editTargetJob.fulfilled.type,
        (state, action: PayloadAction<JobResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        deleteTargetJob.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        deleteTargetJob.fulfilled.type,
        (state, action: PayloadAction<JobResponse[]>) => {
          state.result = action.payload;
        },
      );
  },
});

export const { resetStateStatus } = reqJobSlice.actions;

export default reqJobSlice;
