import { CardHeader, Button, Stack, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EducationDialog from "./Education";
import { TableEducation } from "./TableEducation";

import { useEffect, useState } from "react";

import { useAppDispatch } from "hooks";
import { getAllEducation } from "store/actions/main/cv/education/education";
import { EducationSectionModel } from "model/cv/education/education";
import { EyeOutlined } from "@ant-design/icons";

const EducationSection = ({
  open,
  setOpen,
  cvId,
  basicInfo,
  setLoading,
  loading,
  fetchGetOneEdu,
}: {
  fetchGetOneEdu: any;
  loading: any;
  setLoading: any;
  basicInfo: any;
  open: any;
  setOpen: (open: boolean) => void;
  cvId: any;
}) => {
  const [rows, setRows] = useState<EducationSectionModel[]>([
    {
      resume_id: cvId,
      educational_institution_id: 0,
      degree_type_id: 0, // Update to match input component naming convention
      specialization: "",
      average: 0,
      country: "",
      city: "",
      graduation_year: "", // Assuming it can be null and it's a string (URL?)
    },
  ]);
  const dispatch = useAppDispatch();
  const [showTable, setShowTable] = useState(false);
  return (
    <>
      <Stack
        direction={"row"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "17px",
        }}
      >
        <Stack direction={"column"}>
          <CardHeader title="Education" />

          <Stack style={{ paddingLeft: "10px" }}>
            {/* Pass educationCertificates as prop to TableEducation */}
            <TableEducation
              cvId={cvId}
              educationCertificates={basicInfo}
              fetchGetOneEdu={fetchGetOneEdu}
              loading={loading}
              setLoading={setLoading}
            />
          </Stack>
        </Stack>

        {/* Other education-related fields */}
      </Stack>
      <div
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
          marginTop: rows.length > 0 ? "5%" : "0",
          marginRight: "17px",
          marginBottom: "10px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpen(true)}
        >
          Add Education
        </Button>
        {open && (
          <EducationDialog
            open={open}
            setOpen={setOpen}
            cvId={cvId}
            fetchGetOneEdu={fetchGetOneEdu}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </div>
    </>
  );
};

export default EducationSection;
