//import { Link as RouterLink } from 'react-router-dom';

// material-ui
import {  Stack, } from '@mui/material';

const Footer = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '24px 16px 0px', mt: 'auto' }}>
   
    <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
     
      
    </Stack>
  </Stack>
);

export default Footer;
