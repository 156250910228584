const GET_ALL_COURSERS = "resume/courses";

const ADD_COURSE = "resume/courses";

const EDIT_COURSE = "resume/courses/";

const GET_ONE_COURSE = "resume/courses/";

const DELETE_COURSE = "resume/courses/";

const MULTI_DELET_COURSE = "resume/courses/remove/bulk_delete";

export default {
  GET_ALL_COURSERS,
  ADD_COURSE,
  EDIT_COURSE,
  GET_ONE_COURSE,
  DELETE_COURSE,
  MULTI_DELET_COURSE,
};
