import axios, { AxiosRequestConfig } from "axios";
import { SET_TOKEN } from "contexts/auth-reducer/actions";

const axiosServices = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL || "https://test.syriajob.com/public/api/",
});

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //
console.log(SET_TOKEN);
axiosServices.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${SET_TOKEN}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      !window.location.href.includes("/login")
    ) {
      window.location.pathname = "/login";
    }
    return Promise.reject(
      (error.response && error.response.data) || "Wrong Services",
    );
  },
);

export default axiosServices;

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.get(url, { ...config });

  return res.data;
};

export const fetcherPost = async (
  args: string | [string, AxiosRequestConfig],
) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.post(url, { ...config });

  return res.data;
};
