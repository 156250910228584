import { useEffect, useState } from "react";
// material-ui
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useAppDispatch } from "hooks";
import { addNewEducation } from "store/actions/main/cv/education/education";
import { getAllEducational } from "store/actions/main/basicInfo/educational";
import { getAllDegree } from "store/actions/main/basicInfo/degree";
import {
  GetChildCountries,
  ParentCountries,
} from "store/actions/main/countries";
import * as Yup from "yup";
import { SnackbarProps } from "types/snackbar";
import { openSnackbar } from "api/snackbar";
import TextArea from "antd/es/input/TextArea";
const validationSchema = Yup.object().shape({
  educatioanl_institution_id: Yup.string().required(
    "Job availability is required",
  ),
  degree_type_id: Yup.string().required("Degree id required"),

  specialization: Yup.string().required("Specialization is required"),
  average: Yup.string().required("average is required"),

  graduation_year: Yup.string().required("Graduation Year is required"),
  certificate_name: Yup.string().required("Certificate Name Year is required"),
});
function EducationDialog({
  open,
  setOpen,
  cvId,
  fetchGetOneEdu,
  loading,
  setLoading,
}: {
  fetchGetOneEdu: any;
  loading: any;
  setLoading: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  cvId: number;
}) {
  const [educationData, setEducationData] = useState({
    resume_id: cvId,
    educatioanl_institution_id: "",
    degree_type_id: "", // Update to match input component naming convention
    specialization: "",
    average: "",
    country: "",
    city: "",
    description: "",
    certificate_name: "",
    graduation_year: "",
  });
  const [selectedEducationInst, setSelectedEducationInst] = useState([]);
  const [selectedEducationInstId, setSelectedEducationInstId] = useState("");
  const [selectedDegree, setSelectedDegree] = useState([]);
  const [selectedDegreeId, setSelectedDegreeId] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const dispatch = useAppDispatch();

  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [country, setCountry] = useState();

  const [childCities, setChildCities] = useState([]);
  const [selectedCityName, setSelectedCityName] = useState("");
  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value; // Get the selected country ID
    setSelectedCountry(selectedCountryId); // Update the selected country ID state

    // Find the selected country object
    const selectedCountryObject = country.find(
      (country) => country.id === selectedCountryId,
    );

    if (selectedCountryObject) {
      setSelectedCountryName(selectedCountryObject.name); // Set the selected country name

      // Set the selected city to "All Cities" if the selected country is not the first item in the list
      if (selectedCountryId == "1") {
        dispatch(GetChildCountries({ id: 1 })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        }); // Disable city selection

        setSelectedCity("");
      } else {
        dispatch(GetChildCountries({ id: 2 })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        });
        setSelectedCity("");
      }
    }
  };

  const fetchDataPackages = () => {
    dispatch(ParentCountries())
      .then((action) => {
        const { data } = action.payload;
        // Set user names as options for autocomplete
        setCountry(data);
        // Set default selected country to the first one in the list
        if (data.length > 0) {
          setSelectedCountry(data[0].id);
          setSelectedCountryName(data[0].name);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = childCities.find((city) => city.id === selectedCityId);
    setSelectedCity(selectedCityId);
    // Set the selected city name
    setSelectedCityName(selectedCity?.name || "");
  };
  useEffect(() => {
    fetchDataPackages();
    dispatch(GetChildCountries({ id: 1 })).then((action) => {
      const { data } = action.payload;
      setChildCities(data);
      if (data.length > 0) {
        setSelectedCity(data[0].id);
        setSelectedCityName(data[0].name);
      }
      // Set fetched child cities to state
    });
  }, [dispatch]);
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setEducationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const fetchEducationData = () => {
    dispatch(getAllEducational({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setSelectedEducationInst(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchDegreeData = () => {
    dispatch(getAllDegree({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setSelectedDegree(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const [formErrors, setFormErrors] = useState({});
  const validate = async () => {
    try {
      // Combine all data to be validated
      const dataToValidate = {
        ...educationData,
        educatioanl_institution_id: selectedEducationInstId, // Include selected job availability ID
        degree_type_id: selectedDegreeId, // Update to match input component naming convention: selectedCurrence,
        country: selectedCountry,
        city: selectedCity,
      };

      // Validate the combined data against the validation schema
      await validationSchema.validate(dataToValidate, { abortEarly: false });
      setFormErrors({});
      return true; // Return true if validation succeeds
    } catch (errors) {
      // If validation fails, extract error messages
      const errorMessages = {};
      errors.inner.forEach((error) => {
        errorMessages[error.path] = error.message;
      });
      setFormErrors(errorMessages); // Set error messages for each field
      return false;
    }
  };
  const handleSave = async () => {
    // Log average value for debugging

    const isValid = await validate();
    if (isValid) setLoading(true);
    dispatch(
      addNewEducation({
        resume_id: cvId,
        certificate_name: educationData.certificate_name,
        description: educationData.description,
        educatioanl_institution_id: selectedEducationInstId,
        specialization: educationData.specialization,
        degree_type_id: selectedDegreeId,
        average: educationData.average,
        country: selectedCountryName,
        city: selectedCityName,
        graduation_year: educationData.graduation_year,
      }),
    ).then((action) => {
      if (action.type === "education/addNewEducation/fulfilled") {
        openSnackbar({
          open: true,
          message: "Education Added successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);
        setLoading(false);
        setOpen(false);

        fetchGetOneEdu();
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
        setLoading(false);
      }
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEducationChange = (event) => {
    const selectedEdId = event.target.value; // Get the selected ID
    setSelectedEducationInstId(selectedEdId); // Set the selected ID to state
    const selectedItem = selectedEducationInst.find(
      (item) => item.id === selectedEdId,
    );
    if (selectedItem) {
      const selectedName = selectedItem.name;
    }
  };
  const handleDegereeChange = (event) => {
    const selectedId = event.target.value; // Get the selected ID
    setSelectedDegreeId(selectedId); // Set the selected ID to state
    const selectedItem = selectedDegree.find((item) => item.id === selectedId);
    if (selectedItem) {
      const selectedName = selectedItem.name;
    }
  };
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= currentYear - 90; i--) {
    years.push(i);
  }
  useEffect(() => {
    fetchEducationData();
    fetchDegreeData();
    fetchDataPackages();
  }, [dispatch]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <DialogTitle style={{ fontWeight: "bold" }}>Education</DialogTitle>
        <CloseIcon
          style={{
            marginTop: "12px",
            marginRight: "20px",
            width: "20px",
            height: "20px",
            cursor: "pointer",
          }}
          onClick={() => setOpen(false)}
        />
      </Stack>

      <DialogContent>
        <Box sx={{ p: 2.5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="educatioanl_institution_id">
                  Educational Instituation
                </InputLabel>
                <Select
                  id="educatioanl_institution_id"
                  value={selectedEducationInstId} // Set the selected ID as the value of the Select component
                  onChange={handleEducationChange} // Handle the change event
                >
                  {selectedEducationInst.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.educatioanl_institution_id && (
                  <FormHelperText error>
                    {formErrors.educatioanl_institution_id}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-certificate_name">
                  Degree Name
                </InputLabel>
                <TextField
                  fullWidth
                  value={educationData.certificate_name}
                  onChange={handleInputChange}
                  name="certificate_name"
                  // onBlur={handleBlur}
                  //onChange={handleChange}
                  id="certificate_name"
                  placeholder="Degree Name"
                />
                {formErrors.certificate_name && (
                  <FormHelperText error>
                    {formErrors.certificate_name}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="degree_type_id">Degree</InputLabel>
                <Select
                  id="degree_type_id"
                  value={selectedDegreeId} // Set the selected ID as the value of the Select component
                  onChange={handleDegereeChange} // Handle the change event
                >
                  {selectedDegree.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.degree_type_id && (
                  <FormHelperText error>
                    {formErrors.degree_type_id}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-email">Major</InputLabel>
                <TextField
                  fullWidth
                  value={educationData.specialization}
                  onChange={handleInputChange}
                  name="specialization"
                  // onBlur={handleBlur}
                  //onChange={handleChange}
                  id="specialization"
                  placeholder="Major"
                />
                {formErrors.specialization && (
                  <FormHelperText error>
                    {formErrors.specialization}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <Stack direction={"row"} display={"flex"}>
                  <InputLabel htmlFor="country">Country</InputLabel>
                  <InputLabel style={{ marginLeft: "130px" }} htmlFor="city">
                    City
                  </InputLabel>
                </Stack>
                <Stack direction={"row"}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    // alignItems="center"
                  >
                    <FormControl fullWidth>
                      <Select
                        style={{ width: "140px" }}
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        //  onBlur={handleBlur}
                        inputProps={{
                          name: "country",
                          id: "country",
                        }}
                      >
                        {country &&
                          country.map((country: any) => (
                            <MenuItem key={country.id} value={country.id}>
                              {country.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <FormControl fullWidth>
                      <Select
                        style={{ marginLeft: "20px", width: "140px" }}
                        labelId="city-label"
                        id="city"
                        value={selectedCity}
                        onChange={handleCityChange}
                        // onBlur={handleBlur}
                        name="city"
                      >
                        {childCities.map((city) => (
                          <MenuItem key={city.id} value={city.id}>
                            {city.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <Stack direction={"row"} display={"flex"}>
                  <InputLabel htmlFor="graduation_year">
                    Graduation Year
                  </InputLabel>
                  <InputLabel
                    htmlFor="personal-date"
                    style={{ marginLeft: "70px" }}
                  >
                    Rate
                  </InputLabel>
                </Stack>
                <Stack direction={"row"}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    // alignItems="center"
                    spacing={2}
                  >
                    <Select
                      style={{ width: "150px" }}
                      fullWidth
                      id="graduation_year"
                      value={educationData.graduation_year}
                      onChange={handleInputChange}
                      name="graduation_year"
                      placeholder="Graduation Year"
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                    {formErrors.graduation_year && (
                      <FormHelperText error>
                        {formErrors.graduation_year}
                      </FormHelperText>
                    )}
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <TextField
                      style={{ marginLeft: "20px", width: "150px" }}
                      fullWidth
                      id="average"
                      type="number"
                      value={educationData.average}
                      onChange={handleInputChange}
                      name="average"
                      //onBlur={handleBlur}
                      //onChange={handleChange}
                      placeholder="Rate"
                    />
                    {formErrors.average && (
                      <FormHelperText error>
                        {formErrors.average}
                      </FormHelperText>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-description">
                  Description
                </InputLabel>
                <TextArea
                  value={educationData.description}
                  onChange={handleInputChange}
                  name="description"
                  // onBlur={handleBlur}
                  //onChange={handleChange}
                  id="description"
                  placeholder="Description"
                />
              </Stack>
            </Grid>
          </Grid>
          <Stack display={"flex"} alignItems={"end"} marginTop={"20px"}>
            <Button type="submit" variant="contained" onClick={handleSave}>
              Add Education
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default EducationDialog;
