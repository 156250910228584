import { useEffect, useState, ChangeEvent } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Autocomplete,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// project imports
import IconButton from "components/@extended/IconButton";
import CircularWithPath from "components/@extended/progress/CircularWithPath";
import { openSnackbar } from "api/snackbar";
// assets
import { DeleteFilled } from "@ant-design/icons";
// types
import { SnackbarProps } from "types/snackbar";
import { useAppDispatch } from "hooks";
import { BasicInfo } from "model/basicInfo";
import { addJobType, editJobType } from "store/actions/main/basicInfo/jobTypes";

import {
  addLanguage,
  editLanguage,
} from "store/actions/main/basicInfo/languages";
import { addSkill, editSkill } from "store/actions/main/basicInfo/skills";
import AlertSkillsDelete from "./AlertSkillsDelete";

// constant
const getInitialValues = (customer: BasicInfo | null) => {
  if (customer) {
    return {
      name: customer.name || "",
      order: customer.order || "",
      status: customer.status || false,
    };
  } else {
    return {
      name: "",
      order: "",
      status: false,
    };
  }
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormSkillsAdd = ({
  customer,
  id,
  closeModal,
  fetchData,
  setLoading,
  loading,
}: {
  loading: any;
  setLoading: any;
  fetchData: any;
  id: number;
  customer: BasicInfo | null;
  closeModal: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const CustomerSchema = Yup.object().shape({
    name: Yup.string().required("User Name is required"),
    order: Yup.number().required("Order is required"),
  });
  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editSkill({
              id: customer.id || 0,
              data: values,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "basicInfo/editSkill/fulfilled") {
              openSnackbar({
                open: true,
                message: "Skill edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addSkill(values)).then((action) => {
            fetchData();
            if (action.type === "basicInfo/addSkill/fulfilled") {
              openSnackbar({
                open: true,
                message: "Skill added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;
  const { value, onChange, onBlur } = getFieldProps("");
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>{customer ? "Edit Skill" : "New Skill"}</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-name">Name</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-name"
                          placeholder="Enter Name"
                          {...getFieldProps("name")}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-order">Order</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-order"
                          placeholder="Enter Order"
                          {...getFieldProps("order")}
                          error={Boolean(touched.order && errors.order)}
                          helperText={touched.order && errors.order}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-status">
                          Status
                        </InputLabel>
                        <Select
                          id="customer-status"
                          displayEmpty
                          {...getFieldProps("status")}
                          onChange={(event: SelectChangeEvent<boolean>) => {
                            // Convert true to '1' and false to '0'
                            const selectedValue = event.target.value;

                            // Set the field value to the converted value
                            setFieldValue("status", selectedValue);
                          }}
                          // rest of the code
                        >
                          <MenuItem value={false}>Inactive</MenuItem>
                          <MenuItem value={true}>Active</MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton
                        onClick={() => setOpenAlert(true)}
                        size="large"
                        color="error"
                      >
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? "Edit" : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {customer && (
        <AlertSkillsDelete
          fetchData={fetchData}
          id={customer.id!}
          title={customer.name}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default FormSkillsAdd;
