const GET_VACANCIES = "vacancies";

const ADD_VACANCIES = "vacancies";

const EDIT_VACANCIES = "vacancies/";

const GET_ONE_VACANCIES = "vacancies/";

const DELETE_VACANCIES = "vacancies/";

const MULTI_DELET_VACACIENS = "vacancies/remove/bulk_delete";

export default {
  GET_VACANCIES,

  ADD_VACANCIES,

  EDIT_VACANCIES,

  GET_ONE_VACANCIES,

  DELETE_VACANCIES,

  MULTI_DELET_VACACIENS,
};
