const GET_RESUMES_SUB = "resume_subscriptions";

const ADD_RESUMES_SUB = "resume_subscriptions";

const EDIT_RESUMES_SUB = "resume_subscriptions/";

const GET_ONE_RESUMES_SUB = "resume_subscriptions/";

const DELETE_RESUMESSUB = "resume_subscriptions/";

const FILTER_RESUMES_SUB = "resume_subscriptions/resume/filter";

const MULTI_DELET = "resume_subscriptions/remove/bulk_delete";

export default {
  GET_RESUMES_SUB,

  ADD_RESUMES_SUB,

  EDIT_RESUMES_SUB,

  GET_ONE_RESUMES_SUB,

  FILTER_RESUMES_SUB,

  DELETE_RESUMESSUB,

  MULTI_DELET,
};
