import { useEffect, useState, ChangeEvent } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Autocomplete,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// project imports
import IconButton from "components/@extended/IconButton";
import CircularWithPath from "components/@extended/progress/CircularWithPath";
import { openSnackbar } from "api/snackbar";
// assets
import { DeleteFilled } from "@ant-design/icons";
// types
import { SnackbarProps } from "types/snackbar";
import { useAppDispatch } from "hooks";

import { ReportCandidate } from "model/basicInfo";

import AlertCommentActionsDelete from "./AlertReportDelete";
import { addReport, editReport } from "store/actions/main/basicInfo/reporet";
import AlertReportDelete from "./AlertReportDelete";

// constant
const getInitialValues = (customer: ReportCandidate | null) => {
  if (customer) {
    return {
      en_text: customer.en_text || "",
      ar_text: customer.ar_text || "",
    };
  } else {
    return {
      en_text: "",
      ar_text: "",
    };
  }
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormReportAdd = ({
  customer,
  id,
  closeModal,
  fetchData,
  setLoading,
  loading,
}: {
  loading: any;
  setLoading: any;
  fetchData: any;
  id: number;
  customer: ReportCandidate | null;
  closeModal: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const CustomerSchema = Yup.object().shape({
    en_text: Yup.string().required("English Text is required"),
    ar_text: Yup.string().required("Arabic Text is required"),
  });
  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };
  console.log(customer);
  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editReport({
              id: customer.id || 0,
              data: values,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "basicInfo/editReport/fulfilled") {
              openSnackbar({
                open: true,
                message: "Report Actions edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addReport(values)).then((action) => {
            fetchData();
            if (action.type === "basicInfo/addReport/fulfilled") {
              openSnackbar({
                open: true,
                message: "Report Actions added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;
  const { value, onChange, onBlur } = getFieldProps("");
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>
              {customer ? "Edit Report Candidant" : "New Report Candidant"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={10} marginLeft={"10%"}>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-en_value">
                          English Text
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-en_text"
                          placeholder="Enter Arabic Text"
                          {...getFieldProps("en_text")}
                          error={Boolean(touched.en_text && errors.en_text)}
                          helperText={touched.en_text && errors.en_text}
                          inputProps={{
                            maxLength: 100, // Limit to 100 characters
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-ar_text">
                          Arabic Text
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-ar_text"
                          placeholder="ادخل النص باللغة العربية"
                          {...getFieldProps("ar_text")}
                          error={Boolean(touched.ar_text && errors.ar_text)}
                          helperText={touched.ar_text && errors.ar_text}
                          inputProps={{
                            dir: "rtl",
                            style: { textAlign: "left" },
                            maxLength: 100,
                          }} // RTL for the text input
                          InputProps={{
                            inputProps: {
                              style: { textAlign: "right" },
                              maxLength: 100, // Align text to the right
                            },
                            classes: {
                              input: "placeholder-ltr", // Custom class for placeholder styling
                            },
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <></>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? "Edit" : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormReportAdd;
