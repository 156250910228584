import { CardHeader, Button, Stack, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { TableCourses } from "./TableCourse";

import { useEffect, useState } from "react";

import { useAppDispatch } from "hooks";

import { getAllCoursers } from "store/actions/main/cv/courses/courses";
import CoursesDialog from "./Courses";
import { Course, CourseResponse } from "model/cv/courses/course";
import { EyeOutlined } from "@ant-design/icons";

const CouurseSection = ({
  openCourses,
  setOpenCourses,
  cvId,
  basicInfo,
  setLoading,
  loading,
  fetchGetOneCourse,
}: {
  fetchGetOneCourse: any;
  basicInfo: any;
  setLoading: any;
  loading: any;
  openCourses: any;
  setOpenCourses: (open: boolean) => void;
  cvId: any;
}) => {
  const [rows, setRows] = useState<Course[]>([
    {
      resume_id: 0,
      additional_info: "",
      educational_institution_id: 0,
      course_name: "",
      country: "",
      city: "",
      certificate_date: "",
      course_field: "",
      course_descriptions: "",
      certificate: false,
      // Assuming it can be null and it's a string (URL?)
    },
  ]);
  const [showTable, setShowTable] = useState(false);

  return (
    <>
      <Stack
        direction={"row"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "17px",
        }}
      >
        <Stack direction={"column"}>
          <CardHeader title="Attended Coursers" />
          <Stack style={{ paddingLeft: "10px" }}>
            {/* Pass educationCertificates as prop to TableEducation */}
            <TableCourses
              cvId={cvId}
              coursCertificates={basicInfo}
              setLoading={setLoading}
              loading={loading}
              fetchGetOneCourse={fetchGetOneCourse}
            />
          </Stack>
        </Stack>

        {/* Other education-related fields */}
      </Stack>
      <div
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
          marginTop: rows.length > 0 ? "5%" : "0",
          marginRight: "17px",
          marginBottom: "10px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenCourses(true)}
        >
          Add Course
        </Button>
        {openCourses && (
          <CoursesDialog
            openCourses={openCourses}
            setOpenCourses={setOpenCourses}
            cvId={cvId}
            setLoading={setLoading}
            loading={loading}
            fetchGetOneCourse={fetchGetOneCourse}
          />
        )}
      </div>
    </>
  );
};

export default CouurseSection;
