// assets
import {
  LoginOutlined,
  ProfileOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { FormattedMessage } from "react-intl";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DescriptionIcon from "@mui/icons-material/Description";
import GroupIcon from "@mui/icons-material/Group";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
// icons

const useUsefulInfoItems = () => {
  const icons = {
    LoginOutlined,
    ProfileOutlined,
    FileTextOutlined,
    BusinessCenterOutlinedIcon,
    SettingsOutlinedIcon,
    CheckCircleOutlineIcon,
    DescriptionIcon,
    GroupIcon,
    ToggleOnIcon,
    ToggleOffIcon,
  };

  // ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

  const usefulInfo = {
    id: "useful-information",
    title: (
      <FormattedMessage id="useful-information">
        {(text) => (
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>{text}</span>
        )}
      </FormattedMessage>
    ),
    type: "group",
    children: [
      {
        id: "list-of-purshased-resumes",
        title: <FormattedMessage id="list-of-purshased-resumes" />,
        type: "item",
        url: "/list-of-purshased-resumes",
        icon: icons.GroupIcon,
        breadcrumbs: false,
      },
      {
        id: "list-of-send-resumes",
        title: <FormattedMessage id="list-of-send-resumes" />,
        type: "item",
        url: "/list-of-send-resumes",
        icon: icons.FileTextOutlined,
        breadcrumbs: false,
      },
      {
        id: "recommendations",
        title: <FormattedMessage id="recommendations" />,
        type: "item",
        url: "/recommendations",
        icon: icons.ToggleOnIcon,
        breadcrumbs: false,
      },
      {
        id: "recommendations-sent-mails",
        title: <FormattedMessage id="recommendations-sent-mails" />,
        type: "item",
        url: "/recommendations-sent-mails",
        icon: icons.ToggleOffIcon,
        breadcrumbs: false,
      },
      {
        id: "list-of-applications",
        title: <FormattedMessage id="rlist-of-applications" />,
        type: "item",
        url: "/list-of-applications",
        icon: icons.ProfileOutlined,
        breadcrumbs: false,
      },
    ],
  };
  return usefulInfo;
};

export default useUsefulInfoItems;
