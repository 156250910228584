import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// project import
import useAuth from 'hooks/useAuth';
// ==============================|| AUTH GUARD ||============================== //
var AuthGuard = function (_a) {
    var children = _a.children;
    var isLoggedIn = useAuth().isLoggedIn;
    var navigate = useNavigate();
    var location = useLocation();
    useEffect(function () {
        if (!isLoggedIn) {
            navigate('login', {
                state: {
                    from: location.pathname
                },
                replace: true
            });
        }
    }, [isLoggedIn, navigate, location]);
    return children;
};
export default AuthGuard;
