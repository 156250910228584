export const fieldConfigurations = [
  {
    label: "Country *",
    id: "personal-country",
    name: "country",
    placeholder: "Country",
  },
  {
    label: "City",
    id: "city",
    name: "city",
    placeholder: "City",
  },

  {
    label: "Street Name *",
    id: "personal-street_name",
    name: "street_name",
    placeholder: "Street Name",
  },
  {
    label: "Phone Number *",
    id: "personal-phone_number",
    name: "phone_number",
    placeholder: "Phone Number",
  },
  {
    label: "Account Photo",
    id: "personal-account_photo",
    name: "account_photo",
    placeholder: "Upload Photo",
  },
];
