import { CardHeader, Button, Stack, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RecommendationsDialog from "./Recommendations";
import { TableReco } from "./TableReco";
import { useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Recommendation } from "model/cv/recommendation/recommendations";

const RecommendationsSection = ({
  openReco,
  setOpenReco,
  basicInfo,
  cvId,
  setLoading,
  loading,
  fetchGetOneReco,
}: {
  setLoading: any;
  loading: any;
  fetchGetOneReco: any;
  basicInfo: any;
  cvId: any;
  openReco: any;
  setOpenReco: (openReco: boolean) => void;
}) => {
  const [showTable, setShowTable] = useState(false); // State to manage table visibility
  const [rows, setRows] = useState<Recommendation[]>([
    {
      resume_id: 0,
      user_id: 0,
      recommender_name: "",
      position: "",
      company_name: "",
      job_title: "",
      mobile: "",
      email: "",
      status: false,
      read_by_admin: false, // Assuming it can be null and it's a string (URL?)
    },
  ]);
  return (
    <>
      <Stack
        direction={"row"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "17px",
        }}
      >
        <Stack direction={"column"}>
          <CardHeader
            title="Refrences"
            // Add cursor pointer for indication
          />
          <Stack style={{ paddingLeft: "10px" }}>
            <TableReco
              cvId={cvId}
              recoData={basicInfo}
              setLoading={setLoading}
              loading={loading}
              fetchGetOneReco={fetchGetOneReco}
            />
          </Stack>
        </Stack>
      </Stack>
      <div
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
          marginTop: rows.length > 0 ? "5%" : "0",
          marginRight: "17px",
          marginBottom: "10px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenReco(true)}
        >
          Add Refrence
        </Button>
        {openReco && (
          <RecommendationsDialog
            openReco={openReco}
            setOpenReco={setOpenReco}
            cvId={cvId}
            setLoading={setLoading}
            loading={loading}
            fetchGetOneReco={fetchGetOneReco}
          />
        )}
      </div>
    </>
  );
};

export default RecommendationsSection;
