import { useEffect, useState, ChangeEvent } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

// third-party
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project imports
import IconButton from "components/@extended/IconButton";
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets
import { DeleteFilled } from "@ant-design/icons";

// types
import { SnackbarProps } from "types/snackbar";
import { CountryList } from "types/customer";
import { useAppDispatch } from "hooks";

import {
  ParentCountries,
  addNewCountry,
  editCountry,
} from "store/actions/main/countries";
import { ParentCountry } from "model/country";
import AlertCountryDelete from "./AlertCountryDelete";

// constant
const getInitialValues = (customer: CountryList | null) => {
  const newCustomer = {
    parent_name: "",
    name: "",
    nationality: "",
    order: 0,
    is_active: false,
  };
  if (customer) {
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormCountryAdd = ({
  customer,
  id,
  closeModal,
  fetchData,
}: {
  fetchData: any;
  id: number;
  customer: CountryList | null;
  closeModal: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [countries, setCountries] = useState<ParentCountry[]>([]);
  const [selectedParentCountry, setSelectedParentCountry] =
    useState<ParentCountry | null>(null);

  const fetchDataCountry = (url?: string) => {
    dispatch(ParentCountries())
      .then((action) => {
        const { data } = action.payload;
        const fetchedUserData: ParentCountry[] = data;
        const rowsWithKeys = fetchedUserData.map((user) => ({
          ...user,
          key: user.id,
        }));
        setCountries(rowsWithKeys);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  useEffect(() => {
    fetchDataCountry();
  }, [dispatch]);
  useEffect(() => {
    setLoading(false);

    // Initialize selectedParentCountryId and selectedParentCountry if customer exists
    if (customer && customer.parent_name) {
      const selectedCountry = countries.find(
        (country) => country.name === customer.parent_name,
      );
      if (selectedCountry) {
        setSelectedParentCountry(selectedCountry);
        setSelectedParentCountryId(selectedCountry.id);
      }
    }
  }, [dispatch, id, customer, countries]);

  const CustomerSchema = Yup.object().shape({
    //parent_name: Yup.string().max(255).required("Parent Name is required"),
    name: Yup.string().max(255).required("Name is required"),
    order: Yup.number().max(255),
    nationality: Yup.string().max(255).required("Nationality is required"),
  });

  const [openAlert, setOpenAlert] = useState(false);
  const [selectedParentCountryId, setSelectedParentCountryId] = useState<
    number | null
  >(null);
  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };
  const handleChangeParentName = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value as string;
    const selectedCountry = countries.find(
      (country) => country.name === selectedValue,
    );
    setSelectedParentCountryId(selectedCountry ? selectedCountry.id : null);
    setSelectedParentCountry(selectedCountry); // Update selected parent country state
  };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editCountry({
              countryId: customer.id || 0,
              data: {
                parent_id: selectedParentCountryId,
                name: values.name,
                order: values.order,
                nationality: values.nationality,
                is_active: values.is_active,
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "country/editCountry/fulfilled") {
              openSnackbar({
                open: true,
                message: "Country edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(
            addNewCountry({
              parent_id: selectedParentCountryId,
              name: values.name,
              order: values.order,
              is_active: values.is_active,
              nationality: values.nationality,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "country/addCountry/fulfilled") {
              openSnackbar({
                open: true,
                message: "Country added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;
  const { value, onChange, onBlur } = getFieldProps("parent_name");
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>
              {customer ? "Edit Country" : "New Country"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-parent_name">
                          Parent Name
                        </InputLabel>
                        <Select
                          id="customer-parent_name"
                          displayEmpty
                          value={
                            selectedParentCountry
                              ? selectedParentCountry.name
                              : customer?.parent_name
                          }
                          onChange={handleChangeParentName}
                          onBlur={onBlur}
                          input={
                            <OutlinedInput
                              id="select-column-hiding"
                              placeholder="Sort by"
                            />
                          }
                          renderValue={(selected) => {
                            if (!selected) {
                              return (
                                <Typography variant="subtitle1"></Typography>
                              );
                            }
                            const cat = countries.find(
                              (item) => item.name === selected,
                            );
                            return (
                              <Typography variant="subtitle2">
                                {cat ? cat.name : ""}
                              </Typography>
                            );
                          }}
                        >
                          {countries.map((column: any) => (
                            <MenuItem key={column.id} value={column.name}>
                              <ListItemText primary={column.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-name">Name</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-name"
                          placeholder="Enter Name"
                          {...getFieldProps("name")}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-order">Order</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-order"
                          placeholder="Enter Order"
                          {...getFieldProps("order")}
                          error={Boolean(touched.order && errors.order)}
                          helperText={touched.order && errors.order}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-nationality">
                          Nationality
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-nationality"
                          placeholder="Enter Nationality"
                          {...getFieldProps("nationality")}
                          error={Boolean(touched.order && errors.order)}
                          helperText={touched.order && errors.order}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-is_active">
                          Status
                        </InputLabel>
                        <FormControl fullWidth>
                          <Select
                            id="customer-is_active"
                            displayEmpty
                            {...getFieldProps("is_active")}
                            onChange={(event: SelectChangeEvent<boolean>) => {
                              // Convert true to '1' and false to '0'
                              const selectedValue = event.target.value
                                ? true
                                : false;
                              // Set the field value to the converted value
                              setFieldValue("is_active", selectedValue);
                            }}
                            // rest of the code
                          >
                            <MenuItem value={false}>Inactive</MenuItem>
                            <MenuItem value={true}>Active</MenuItem>
                          </Select>
                        </FormControl>
                        {touched.is_active && errors.is_active && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{ pl: 1.75 }}
                          >
                            {errors.is_active}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton
                        onClick={() => setOpenAlert(true)}
                        size="large"
                        color="error"
                      >
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? "Edit" : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {customer && (
        <AlertCountryDelete
          fetchData={fetchData}
          id={customer.id!}
          title={customer.name}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default FormCountryAdd;
