import { useEffect, useState, ChangeEvent } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

// third-party
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project imports
import IconButton from "components/@extended/IconButton";
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets
import { DeleteFilled } from "@ant-design/icons";

// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";
import Checkbox from "@mui/material/Checkbox";
import AlertCountryDelete from "./AlertRolesDelete";
import { UserList } from "types/customer";
import { addNewUser, editUser } from "store/actions/main/users";
import { RolesModel } from "model/roles";
import { addNewRoles, editRole, getPermission } from "store/actions/main/roles";

// constant
const getInitialValues = (customer: RolesModel | null) => {
  const newCustomer = {
    name: "",
    permissions: [],
  };
  if (customer) {
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormRolesAdd = ({
  customer,
  id,
  fetchData,
  permissions,
  loading,
  closeModal,
  setLoading,
}: {
  fetchData: any;
  permissions: any;
  loading: any;
  id: number;
  setLoading: any;
  customer: RolesModel | null;
  closeModal: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const CustomerSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    //  validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formData = new FormData();
        const { name, permissions } = values;

        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(editRole({ id: customer.id || 0, data: values })).then(
            (action) => {
              fetchData();
              if (action.type === "role/editRole/fulfilled") {
                openSnackbar({
                  open: true,
                  message: "Roles edited successfully",
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                  variant: "alert",
                  alert: {
                    color: "success",
                  },
                } as SnackbarProps);
                setSubmitting(false);
                closeModal();
              }
            },
          );
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addNewRoles(values)).then((action) => {
            fetchData();
            if (action.type === "role/addNewRoles/fulfilled") {
              openSnackbar({
                open: true,
                message: "Roles edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }

        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;
  const { value, onChange, onBlur } = getFieldProps("");


  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>{customer ? "Edit Role" : "New Role"}</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-name">
                          Role Name
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-name"
                          placeholder="Enter Name"
                          {...getFieldProps("name")}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel>Permissions</InputLabel>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <FormGroup row>
                            {permissions.map((permission: any) => (
                              <FormControlLabel
                                key={permission.key} // Assuming 'key' is a unique identifier for each permission
                                control={
                                  <Checkbox
                                    {...getFieldProps(
                                      `permissions.${permission.name}`,
                                    )}
                                    checked={formik.values.permissions.includes(
                                      permission.name,
                                    )}
                                    onChange={(event) => {
                                      const isChecked = event.target.checked;
                                      formik.setFieldValue(
                                        "permissions",
                                        isChecked
                                          ? [
                                              ...formik.values.permissions,
                                              permission.name,
                                            ]
                                          : formik.values.permissions.filter(
                                              (perm) =>
                                                perm !== permission.name,
                                            ),
                                      );
                                    }}
                                  />
                                }
                                label={permission.name} // Assuming 'name' is the permission name
                              />
                            ))}
                          </FormGroup>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton
                        onClick={() => setOpenAlert(true)}
                        size="large"
                        color="error"
                      >
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? "Edit" : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {customer && (
        <AlertCountryDelete
          fetchData={fetchData}
          id={customer.id!}
          title={customer.name}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default FormRolesAdd;
