const GET_LANGUAGE = "languages";

const ADD_LANGUAGE = "languages";

const GET_ONE_LANGUAGE = "languages/";

const EDIT_LANGUAGE = "languages/";

const DELETE_LANGUAGE = "languages/";

const MULTI_DELET_LANGUAGE = "languages/remove/bulk_delete";

export default {
  GET_LANGUAGE,
  GET_ONE_LANGUAGE,
  ADD_LANGUAGE,
  EDIT_LANGUAGE,
  DELETE_LANGUAGE,

  MULTI_DELET_LANGUAGE,
};
