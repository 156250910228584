import { useEffect, useState, ChangeEvent } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Autocomplete,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// project imports
import IconButton from "components/@extended/IconButton";
import CircularWithPath from "components/@extended/progress/CircularWithPath";
import { openSnackbar } from "api/snackbar";
// assets
import { DeleteFilled } from "@ant-design/icons";
// types
import { SnackbarProps } from "types/snackbar";
import { useAppDispatch } from "hooks";

import {
  addCommentActions,
  editCommentActions,
} from "store/actions/main/basicInfo/commentAction";
import AlertCommentActionsDelete from "./AlertReplyAppDelete";
import AlertReplyAppDelete from "./AlertReplyAppDelete";
import { ReplyApplicant } from "model/companyList/replyApp";
import {
  addReplayApplication,
  editReplyApplication,
} from "store/actions/main/companyList/replyApp";

// constant
const getInitialValues = (customer: ReplyApplicant | null) => {
  if (customer) {
    return {
      en_message: customer.en_message || "",
      ar_message: customer.ar_message || "",
      en_title: customer.en_title || "",
      ar_title: customer.ar_title || "",
    };
  } else {
    return {
      en_message: "",
      ar_message: "",
      ar_title: "",
      en_title: "",
    };
  }
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormReplyAppAdd = ({
  customer,
  id,
  closeModal,
  fetchData,
  setLoading,
  loading,
}: {
  loading: any;
  setLoading: any;
  fetchData: any;
  id: number;
  customer: ReplyApplicant | null;
  closeModal: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const CustomerSchema = Yup.object().shape({
    en_message: Yup.string().required("Message is required"),
    ar_message: Yup.string().required("Message Arabic is required"),
    en_title: Yup.string().required("Title is required"),
    ar_title: Yup.string().required("Title Arabic is required"),
  });
  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };
  console.log(customer);
  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editReplyApplication({
              id: customer.id || 0,
              data: values,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "companyList/editReplyApplication/fulfilled") {
              openSnackbar({
                open: true,
                message: "Reply Application edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addReplayApplication(values)).then((action) => {
            fetchData();
            if (action.type === "companyList/addReplayApplication/fulfilled") {
              openSnackbar({
                open: true,
                message: "Reply Application  added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;
  const { value, onChange, onBlur } = getFieldProps("");
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>
              {customer ? "Edit Reply Application " : "New Reply Application "}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-en_message">
                          English Message
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-en_message"
                          placeholder="Enter English Message"
                          {...getFieldProps("en_message")}
                          error={Boolean(
                            touched.en_message && errors.en_message,
                          )}
                          helperText={touched.en_message && errors.en_message}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-ar_message">
                          Arabic Message
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-ar_message"
                          placeholder="Enter Arabic Message"
                          {...getFieldProps("ar_message")}
                          error={Boolean(
                            touched.ar_message && errors.ar_message,
                          )}
                          helperText={touched.ar_message && errors.ar_message}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-en_title">
                          English Title
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-en_title"
                          placeholder="Enter English Title"
                          {...getFieldProps("en_title")}
                          error={Boolean(touched.en_title && errors.en_title)}
                          helperText={touched.en_title && errors.en_title}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-ar_title">
                          Arabic Title
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-ar_title"
                          placeholder="Enter Arabic Title"
                          {...getFieldProps("ar_title")}
                          error={Boolean(touched.ar_title && errors.ar_title)}
                          helperText={touched.ar_title && errors.ar_title}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton
                        onClick={() => setOpenAlert(true)}
                        size="large"
                        color="error"
                      >
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? "Edit" : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {customer && (
        <AlertReplyAppDelete
          fetchData={fetchData}
          id={customer.id!}
          title={customer.en_value}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default FormReplyAppAdd;
