import { CardHeader, Button, Stack, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { Course, CourseResponse } from "model/cv/courses/course";
import { useState } from "react";
import { Language } from "model/cv/language/language";
import LanguageDialog from "./Language";
import { TableLanguage } from "./TableLanguage";
import { EyeOutlined } from "@ant-design/icons";

const LanguageSection = ({
  cvId,
  basicInfo,
  setLoading,
  loading,
  openLanguage,
  setOpenLanguages,
  fetchGetOneLang,
}: {
  fetchGetOneLang: any;
  cvId: any;
  basicInfo: any;
  setLoading: any;
  loading: any;
  openLanguage: any;
  setOpenLanguages: (open: boolean) => void;
}) => {
  const [showTable, setShowTable] = useState(false);
  const [rows, setRows] = useState<Language[]>([
    {
      resume_id: 0,
      language_id: 0,
      reading_level: "",
      conversation_level: "",
      writing_level: "",
      // Assuming it can be null and it's a string (URL?)
    },
  ]);

  return (
    <>
      <Stack
        direction={"row"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "17px",
        }}
      >
        <Stack direction={"column"}>
          <CardHeader title="Languages" />

          <Stack style={{ paddingLeft: "10px" }}>
            {/* Pass educationCertificates as prop to TableEducation */}
            <TableLanguage
              cvId={cvId}
              languageData={basicInfo}
              setLoading={setLoading}
              loading={loading}
              fetchGetOneLang={fetchGetOneLang}
            />
          </Stack>
        </Stack>

        {/* Other education-related fields */}
      </Stack>
      <div
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
          marginTop: rows.length > 0 ? "5%" : "0",
          marginRight: "17px",
          marginBottom: "10px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenLanguages(true)}
        >
          Add Language
        </Button>
        {openLanguage && (
          <LanguageDialog
            openLanguage={openLanguage}
            setOpenLanguages={setOpenLanguages}
            cvId={cvId}
            setLoading={setLoading}
            loading={loading}
            fetchGetOneLang={fetchGetOneLang}
          />
        )}
      </div>
    </>
  );
};

export default LanguageSection;
