import { useEffect, useState, ChangeEvent } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

// third-party
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project imports
import IconButton from "components/@extended/IconButton";
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets
import { DeleteFilled } from "@ant-design/icons";

// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";

import AlertCompanyDelete from "./AlertVacancyDelete";
import { PackagesVacancies } from "model/packages";
import {
  addNewVacancy,
  editOneVacancy,
} from "store/actions/main/packages/vacanciesPackages";
import TextArea from "antd/es/input/TextArea";

// constant
const getInitialValues = (customer: PackagesVacancies | null) => {
  const newCustomer = {
    type: "",
    amount: "",
    price: 0,
    price_per_unit: 0,
    package_validity: 0,
    package_duration: 0,
    note: "",
    order: 0,
  };
  if (customer) {
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormVacancyAdd = ({
  customer,
  fetchData,
  id,
  closeModal,
}: {
  id: number;
  fetchData: any;
  customer: PackagesVacancies | null;
  closeModal: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const CustomerSchema = Yup.object().shape({
    type: Yup.string().required("Type is required"),
    amount: Yup.number().required("Number is required"),
    price: Yup.number().required("Cost is required"),
    price_per_unit: Yup.number().required("Cost Per Unit is required"),
    package_validity: Yup.number().required("Validity Per Unit is required"),
    package_duration: Yup.number().required("Duration Per Unit is required"),
    order: Yup.number().required("Order Per Unit is required"),
    note: Yup.string().required("Note Per Unit is required"),
  });

  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editOneVacancy({ id: customer.id || 0, data: values }),
          ).then((action) => {
            fetchData();
            if (action.type === "packages/editOneVacancy/fulfilled") {
              openSnackbar({
                open: true,
                message: "Vacancy Packages updated successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addNewVacancy(values)).then((action) => {
            fetchData();
            if (action.type === "packages/addNewVacancy/fulfilled") {
              openSnackbar({
                open: true,
                message: "Vacancy Packages added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;
  const { value, onChange, onBlur } = getFieldProps("");
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>
              {customer ? "Edit Vacancy Package" : "New Vacancy Package"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={10} marginLeft={"10%"}>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-type">Type</InputLabel>
                        <Select
                          id="customer-type"
                          displayEmpty
                          {...getFieldProps("type")}
                          onChange={(event: SelectChangeEvent<boolean>) => {
                            // Convert true to '1' and false to '0'
                            const selectedValue = event.target.value;

                            // Set the field value to the converted value
                            setFieldValue("type", selectedValue);
                          }}
                          // rest of the code
                        >
                          <MenuItem value={"LIMITED"}>LIMITED</MenuItem>
                          <MenuItem value={"UNLIMITED"}>UNLIMITED</MenuItem>
                          <MenuItem value={"CUSTOM"}>CUSTOM</MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-amount">
                          Count Vacancies
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-amount"
                          placeholder="Enter Amount"
                          {...getFieldProps("amount")}
                          error={Boolean(touched.amount && errors.amount)}
                          helperText={touched.amount && errors.amount}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-price_per_unit">
                          Fees One Unit
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-price_per_unit"
                          placeholder="Enter Price One Unit"
                          {...getFieldProps("price_per_unit")}
                          error={Boolean(
                            touched.price_per_unit && errors.price_per_unit,
                          )}
                          helperText={
                            touched.price_per_unit && errors.price_per_unit
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-cost">Price</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-price"
                          placeholder="Enter Price"
                          {...getFieldProps("price")}
                          error={Boolean(touched.price && errors.price)}
                          helperText={touched.price && errors.price}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customr-package_validity">
                          Vacancies Validity
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-package_validity"
                          placeholder="Enter Validity"
                          {...getFieldProps("package_validity")}
                          error={Boolean(
                            touched.package_validity && errors.package_validity,
                          )}
                          helperText={
                            touched.package_validity && errors.package_validity
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-package_duration">
                          Post Duration
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-package_duration"
                          placeholder="Enter Duration"
                          {...getFieldProps("package_duration")}
                          error={Boolean(
                            touched.package_duration && errors.package_duration,
                          )}
                          helperText={
                            touched.package_duration && errors.package_duration
                          }
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-order">Order</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-order"
                          placeholder="Order"
                          {...getFieldProps("order")}
                          error={Boolean(touched.order && errors.order)}
                          helperText={touched.order && errors.order}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-note">
                          English Note
                        </InputLabel>
                        <TextArea
                          id="customer-note"
                          placeholder="Note"
                          {...getFieldProps("note")}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-note">
                          Arabic Note
                        </InputLabel>
                        <TextArea
                          id="customer-note"
                          placeholder="ادخل الملاحظة"
                          {...getFieldProps("note")}
                          dir="rtl" // Set the direction to RTL
                          style={{ textAlign: "right" }} // Set text alignment to left
                          maxLength={100} // Set maximum length
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <></>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? "Edit" : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormVacancyAdd;
