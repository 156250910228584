import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS } from "api";
import responseError from "utils/api/error-handling";
import skillsURL from "../../../../utils/url/basicInfo/skills";

//get AllUser
export const getAllSkills = createAsyncThunk(
  "basicInfo/getAllSkills",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `${skillsURL.GET_SKILLS}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneSkill = createAsyncThunk(
  "basicInfo/getOneSkill",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await AXIOS.get(`${skillsURL.GET_ONE_SKILL}${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editSkill = createAsyncThunk(
  "basicInfo/editSkill",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await AXIOS.post(`${skillsURL.EDIT_SKILL}${id}`, data);
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addSkill = createAsyncThunk(
  "basicInfo/addSkill",
  async (data: any, thunkAPI) => {
    try {
      const response = await AXIOS.post(skillsURL.ADD_SKILL, data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deletSkill = createAsyncThunk(
  "basicInfo/deletSkill",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(`${skillsURL.DELETE_SKILL}${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletSkills = createAsyncThunk(
  "basicInfo/MultiDeletSkills",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(skillsURL.MULTI_DELET_SKILL, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
