import { Fragment, MouseEvent, useEffect, useMemo, useState } from "react";

// material-ui
import { alpha, useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import _debounce from "lodash/debounce";
// third-party
import { PatternFormat } from "react-number-format";
import {
  ColumnDef,
  HeaderGroup,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  useReactTable,
  SortingState,
  FilterFn,
} from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";
import { DatePicker, Select } from "antd";
// project-import
import ScrollX from "components/ScrollX";
import MainCard from "components/MainCard";
import IconButton from "components/@extended/IconButton";

import {
  CSVExport,
  HeaderSort,
  IndeterminateCheckbox,
  RowSelection,
  SelectColumnSorting,
  TablePagination,
} from "components/@extended/react-table";

// types

import { LabelKeyObject } from "react-csv/lib/core";

// assets
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useAppDispatch } from "hooks";
import ExpandingVacancyDetail from "./ExpandingVacancyDetails";
import AlertVacancyDelete from "./AlertVacancyDelete";
import VacancyModal from "./VacancyModal";
import {
  FilterVacancySub,
  MultiDeletVacancySub,
  getAllVacanciesSub,
} from "store/actions/main/subscription/vacancySub";
import { VacancySubSecription } from "model/subscription";
import Loader from "components/Loader";

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // store the ranking info
  addMeta(itemRank);

  // return if the item should be filtered in/out
  return itemRank.passed;
};

// ==============================|| REACT TABLE - LIST ||============================== //

function ReactTable({
  countPage,
  setPageSize,
  validation,
  handleDateChange,
  type,
  pageSize,
  currentPage,
  handlePageChange,

  handleApplyFilters,
  setSType,
  setValidtion,
  data,
  fetchData,
  columns,
  setCurrenttPage,
  modalToggler,
}: any) {
  const { RangePicker } = DatePicker;
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "id",
      desc: false,
    },
  ]);
  const [rowSelection, setRowSelection] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");

  const table = useReactTable({
    data,

    columns,
    state: {
      sorting,
      rowSelection,
      globalFilter,
    },

    enableRowSelection: true,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    getRowCanExpand: () => true,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    globalFilterFn: fuzzyFilter,
    debugTable: true,
  });

  const backColor = alpha(theme.palette.primary.lighter, 0.1);
  let headers: LabelKeyObject[] = [];
  columns.map(
    (columns: any) =>
      // @ts-ignore
      columns.accessorKey &&
      headers.push({
        label: typeof columns.header === "string" ? columns.header : "#",
        // @ts-ignore
        key: columns.accessorKey,
      }),
  );
  const dispatch = useAppDispatch();
  const selectedRowIds = useMemo(() => {
    // Get the selected rows from the table object
    const selectedRows = table
      .getSortedRowModel()
      .flatRows.filter((row) => row.getIsSelected());

    // Map over the selected rows to extract their IDs
    return selectedRows.map((row) => row.original.id);
  }, [table, rowSelection]);
  const handleMultiDelet = () => {
    dispatch(MultiDeletVacancySub({ ids: selectedRowIds }));
    fetchData();
  };

  return (
    <MainCard content={false}>
      <Grid direction={"column"}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            padding: 2,
            ...(matchDownSM && {
              "& .MuiOutlinedInput-root, & .MuiFormControl-root": {
                width: "100%",
              },
            }),
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="center"
            sx={{ width: { xs: "100%", sm: "auto" } }}
          >
            <SelectColumnSorting
              {...{
                getState: table.getState,
                getAllColumns: table.getAllColumns,
                setSorting,
              }}
            />
          </Stack>
          <Stack display={"flex"} justifyContent={"space-between"}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteOutlined />}
                onClick={handleMultiDelet}
                disabled={Object.keys(rowSelection).length === 0}
              >
                Delete Multi items
              </Button>
              <Button
                variant="contained"
                startIcon={<PlusOutlined />}
                onClick={modalToggler}
              >
                Add Vacancy Subscription
              </Button>
              <CSVExport
                {...{
                  data:
                    table
                      .getSelectedRowModel()
                      .flatRows.map((row: any) => row.original).length === 0
                      ? data
                      : table
                          .getSelectedRowModel()
                          .flatRows.map((row: any) => row.original),
                  headers,
                  filename: "customer-list.csv",
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            padding: 2,
            ...(matchDownSM && {
              "& .MuiOutlinedInput-root, & .MuiFormControl-root": {
                width: "100%",
              },
            }),
          }}
        >
          <Stack
            direction={"row"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Grid sx={{ mr: "10px" }}>
              <InputLabel id="account_status">Type</InputLabel>
              <FormControl style={{ width: "110px" }}>
                <Select
                  id="customer-account_sctatus"
                  value={type}
                  style={{ height: "40px" }}
                  onChange={(event: SelectChangeEvent<string>) => {
                    let selectedValue;
                    if (typeof event === "string") {
                      selectedValue = event;
                    } else {
                      selectedValue = event.target.value;
                    }
                    setSType(selectedValue);
                  }}
                  // rest of the code
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value={"LIMITED"}>LIMITED</MenuItem>
                  <MenuItem value={"UNLIMITED"}>UNLIMITED</MenuItem>
                  <MenuItem value={"CUSTOM"}>CUSTOM</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid>
              <InputLabel id="account_language">Validation</InputLabel>
              <FormControl style={{ width: "110px" }}>
                <TextField
                  value={validation}
                  onChange={(event: any) => {
                    setValidtion(event.target.value);
                  }}
                />
              </FormControl>
            </Grid>
          </Stack>
          <Stack
            direction={"row"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Grid sx={{ mr: "10px" }}>
              <RangePicker
                onChange={handleDateChange}
                style={{ height: "40px" }} //onChange={handleDateChange}
              />
            </Grid>
            <Stack>
              <Button
                sx={{ mr: "45px" }}
                variant="contained"
                startIcon={<PlusOutlined />}
                onClick={handleApplyFilters}
              >
                Apply Filter
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Grid>

      <ScrollX>
        <Stack>
          <RowSelection selected={Object.keys(rowSelection).length} />
          <TableContainer>
            <Table>
              <TableHead>
                {table
                  .getHeaderGroups()
                  .map((headerGroup: HeaderGroup<any>) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        if (
                          header.column.columnDef.meta !== undefined &&
                          header.column.getCanSort()
                        ) {
                          Object.assign(header.column.columnDef.meta, {
                            className:
                              header.column.columnDef.meta.className +
                              " cursor-pointer prevent-select",
                          });
                        }

                        return (
                          <TableCell
                            key={header.id}
                            {...header.column.columnDef.meta}
                            onClick={header.column.getToggleSortingHandler()}
                            {...(header.column.getCanSort() &&
                              header.column.columnDef.meta === undefined && {
                                className: "cursor-pointer prevent-select",
                              })}
                          >
                            {header.isPlaceholder ? null : (
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <Box>
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                                </Box>
                                {header.column.getCanSort() && (
                                  <HeaderSort column={header.column} />
                                )}
                              </Stack>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map((row) => (
                  <Fragment key={row.id}>
                    <TableRow>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          {...cell.column.columnDef.meta}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                    {row.getIsExpanded() && (
                      <TableRow
                        sx={{
                          bgcolor: backColor,
                          "&:hover": { bgcolor: `${backColor} !important` },
                        }}
                      >
                        <TableCell colSpan={row.getVisibleCells().length}>
                          <ExpandingVacancyDetail data={row.original} />
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <>
            <Divider />
            <Box sx={{ p: 2 }}>
              <TablePagination
                {...{
                  setPageSize: setPageSize,
                  setPageIndex: setCurrenttPage,
                  getState: table.getState,
                  getPageCount: () => countPage.last_page,
                  currentPage: currentPage,
                  handlePageChange: handlePageChange,
                  initialPageSize: pageSize,
                }}
              />
            </Box>
          </>
        </Stack>
      </ScrollX>
    </MainCard>
  );
}

// ==============================|| CUSTOMER LIST ||============================== //

const VacanciesSub = () => {
  const theme = useTheme();

  //const { customersLoading, customers: lists } = useGetCustomer();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [customerModal, setCustomerModal] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] =
    useState<VacancySubSecription | null>(null);
  const [customerDeleteId, setCustomerDeleteId] = useState<any>("");

  const handleClose = () => {
    setOpen(!open);
  };

  const columns = useMemo<ColumnDef<VacancySubSecription>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
      },
      {
        header: "#",
        accessorKey: "id",
        meta: {
          className: "cell-center",
        },
      },
      {
        header: "Company Name",
        accessorKey: "company_name",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as string}
              </Typography>
              <Typography color="text.secondary"></Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        header: "Type",
        accessorKey: "type",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as string}
              </Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        header: "Original Credit",
        accessorKey: "package_validation",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as string}
              </Typography>
              <Typography color="text.secondary"></Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        header: "Left Balance",
        accessorKey: "paid_amount",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as number}
              </Typography>
              <Typography color="text.secondary"></Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        header: "Start Date",
        accessorKey: "start_date",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as number}
              </Typography>
              <Typography color="text.secondary"></Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        header: "End Date",
        accessorKey: "end_date",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as number}
              </Typography>
              <Typography color="text.secondary"></Typography>
            </Stack>
          </Stack>
        ),
      },

      {
        header: "Actions",
        meta: {
          className: "cell-center",
        },
        disableSortBy: true,
        cell: ({ row, getValue }) => {
          const { id } = row.original;

          const collapseIcon =
            row.getCanExpand() && row.getIsExpanded() ? (
              <PlusOutlined
                style={{
                  color: theme.palette.error.main,
                  transform: "rotate(45deg)",
                }}
              />
            ) : (
              <EyeOutlined />
            );
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={0}
            >
              <Tooltip title="View">
                <IconButton
                  color="secondary"
                  onClick={row.getToggleExpandedHandler()}
                >
                  {collapseIcon}
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton
                  color="primary"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setSelectedCustomer(row.original);
                    setCustomerModal(true);
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    handleClose();
                    setCustomerDeleteId(row.original.id);
                  }}
                >
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [theme],
  );
  const [result, setResult] = useState<VacancySubSecription[]>([]);
  const [countPage, setCountPage] = useState({
    current_page: 0,

    last_page: 0,
  });
  const [currentPage, setCurrenttPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default value is 10, or you can set it to `initialPageSize` if available
  const [type, setSType] = useState("");
  const [validation, setValidtion] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const dispatch = useAppDispatch();
  const fetchData = () => {
    setLoading(true);
    dispatch(getAllVacanciesSub({ pageNumber: currentPage }))
      .then((action) => {
        const { data, meta } = action.payload;
        const fetchedUCountryData: VacancySubSecription[] = data;
        const rowsWithKeys = fetchedUCountryData.map((company) => ({
          ...company,
          key: company.id,
        }));
        setResult(rowsWithKeys);

        setCountPage(meta);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching user:", error);
      });
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, dispatch]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setCurrenttPage(page); // Update the currentPage state when the page changes
  };
  const handleToggleAccountStatus = (id: number, currentStatus: string) => {
    const updatedData = result.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          account_status: currentStatus === "active" ? "inactive" : "active",
        };
      }
      return item;
    });

    setResult(updatedData);

    // Dispatch action to toggle account status here if needed
  };
  const handleApplyFilters = () => {
    const startDate = selectedDate?.[0] ?? null;
    const endDate = selectedDate?.[selectedDate.length - 1] ?? null;

    // Dispatch the FilterVacancySub action with selected filter criteria
    dispatch(
      FilterVacancySub({
        start_date: startDate,
        end_date: endDate,
        type,
        package_validation: validation,
      }),
    )
      .then((action) => {
        const filteredcompanyData: VacancySubSecription[] =
          action.payload.data.map((company: any) => ({
            ...company,
            isActive: company.account_status === "active",
          }));
        setResult(filteredcompanyData);
      })
      .catch((error) => {
        console.error("Error filtering users:", error);
      });
  };

  const handleDateChange = (dates: any) => {
    setSelectedDate(dates);
  };
  // Function to handle selection of rows

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ReactTable
          handleDateChange={handleDateChange}
          type={type}
          validation={validation}
          handleApplyFilters={handleApplyFilters}
          setValidtion={setValidtion}
          setSType={setSType}
          setSelectedDate={setSelectedDate}
          pageSize={pageSize}
          setPageSize={setPageSize}
          fetchData={fetchData}
          data={result}
          columns={columns}
          modalToggler={() => {
            setCustomerModal(true);
            setSelectedCustomer(null);
          }}
          handleToggleAccountStatus={handleToggleAccountStatus}
          countPage={countPage}
          currentPage={currentPage}
          setCurrenttPage={setCurrenttPage}
          handlePageChange={handlePageChange}
        />
      )}

      <AlertVacancyDelete
        fetchData={fetchData}
        id={customerDeleteId}
        title={customerDeleteId}
        open={open}
        handleClose={handleClose}
      />
      <VacancyModal
        fetchData={fetchData}
        id={customerDeleteId}
        open={customerModal}
        modalToggler={setCustomerModal}
        customer={selectedCustomer}
      />
    </>
  );
};

export default VacanciesSub;
