  import { useEffect, useState, ChangeEvent } from 'react';
  import { Link } from 'react-router-dom';

  // material-ui
  import { useTheme } from '@mui/material/styles';
  import { Box, Divider, FormLabel, Grid, TextField, Menu, MenuItem, Stack, Typography } from '@mui/material';

  // project import
  import MainCard from 'components/MainCard';
  import Avatar from 'components/@extended/Avatar';

  import { facebookColor, linkedInColor, twitterColor } from 'config';

  // assets
  import { FacebookFilled, LinkedinFilled, MoreOutlined, TwitterSquareFilled, CameraOutlined } from '@ant-design/icons';
  import IconButton from 'components/@extended/IconButton';

  // types
  import { ThemeMode } from 'types/config';
import ProfileTab from 'layout/Dashboard/Header/HeaderContent/Profile/ProfileTab';

  const avatarImage = require.context('assets/images/users', true);

  // ==============================|| USER PROFILE - TAB CONTENT ||============================== //

  interface Props {
    focusInput: () => void;
  }

  const ProfileTabs = () => {
    const theme = useTheme();
    const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined);
    //const [avatar, setAvatar] = useState<string | undefined>(avatarImage(''));

    useEffect(() => {
      if (selectedImage) {
      //  setAvatar(URL.createObjectURL(selectedImage));
      }
    }, [selectedImage]);

    const [anchorEl, setAnchorEl] = useState<Element | (() => Element) | null | undefined>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
      setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <MainCard >
        <Grid container spacing={6} >
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <IconButton
                variant="light"
                color="secondary"
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <MoreOutlined />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <MenuItem
                  component={Link}
                  to="/apps/profiles/user/personal"
                  onClick={() => {
                    handleClose();
                    
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem onClick={handleClose} disabled>
                  Delete
                </MenuItem>
              </Menu>
            </Stack>
            <Stack spacing={2.5} alignItems="center">
              <FormLabel
                htmlFor="change-avtar"
                sx={{
                  position: 'relative',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  '&:hover .MuiBox-root': { opacity: 1 },
                  cursor: 'pointer'
                }}
              >
               
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    backgroundColor: theme.palette.mode === ThemeMode.DARK ? 'rgba(255, 255, 255, .75)' : 'rgba(0,0,0,.65)',
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Stack spacing={0.5} alignItems="center">
                    <CameraOutlined style={{ color: theme.palette.secondary.lighter, fontSize: '2rem' }} />
                    <Typography sx={{ color: 'secondary.lighter' }}>Upload</Typography>
                  </Stack>
                </Box>
              </FormLabel>
              <TextField
                type="file"
                id="change-avtar"
                placeholder="Outlined"
                variant="outlined"
                sx={{ display: 'none' }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSelectedImage(e.target.files?.[0])}
              />
              <Stack spacing={0.5} alignItems="center">
                <Typography variant="h5">Stebin Ben</Typography>
                <Typography color="secondary">Full Stack Developer</Typography>
              </Stack>
              <Stack direction="row" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
                <TwitterSquareFilled style={{ color: twitterColor }} />
                <FacebookFilled style={{ color: facebookColor }} />
                <LinkedinFilled style={{ color: linkedInColor }} />
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={3} sx={{ display: { sm: 'block', md: 'none' } }} />
          <Grid item xs={12} sm={6} md={12}>
            <Stack direction="row" justifyContent="space-around" alignItems="center">
              <Stack spacing={0.5} alignItems="center">
                <Typography variant="h5">86</Typography>
                <Typography color="secondary">Post</Typography>
              </Stack>
              <Divider orientation="vertical" flexItem />
              <Stack spacing={0.5} alignItems="center">
                <Typography variant="h5">40</Typography>
                <Typography color="secondary">Project</Typography>
              </Stack>
              <Divider orientation="vertical" flexItem />
              <Stack spacing={0.5} alignItems="center">
                <Typography variant="h5">4.5K</Typography>
                <Typography color="secondary">Members</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
          <ProfileTab/>
          </Grid>
        </Grid>
      </MainCard>
    );
  };

  export default ProfileTabs;
