import { useEffect, useState, ChangeEvent } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project imports
import IconButton from "components/@extended/IconButton";
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets
import { DeleteFilled } from "@ant-design/icons";

// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";

import { getAllCompanies } from "store/actions/main/companies";
import { getAllVacanciesOrder } from "store/actions/main/order/vacancyOrder";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { addBanners, editBanner } from "store/actions/main/ads/banners";
import { values } from "lodash";

import { getAllCategories } from "store/actions/main/categories";
import { addBestPlace, editBestPlace } from "store/actions/main/ads/bestPlace";
import { majorCompany } from "model/ads";
import {
  addMajorCompany,
  editMajorCompany,
} from "store/actions/main/ads/majorCompany";
import AlertMajorCompanyDelete from "../bestPlace/AlertBestPlaceDelete";

// constant
const getInitialValues = (customer: majorCompany | null) => {
  const newCustomer = {
    user_id: "",
    vacancy_order_id: "",
    block_number: "",
    status: "",
    end_date: "",
    start_date: "",
  };

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormMajorCompanyAdd = ({
  customer,
  id,
  loading,
  closeModal,
  setLoading,
  userName,
  Packages,
  fetchData,
}: {
  setLoading: any;
  userName: any;
  Packages: any;
  loading: any;
  id: number;

  customer: majorCompany | null;
  closeModal: () => void;
  fetchData: any;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const CustomerSchema = Yup.object().shape({
    user_id: Yup.string().required("Company Name is required"),
    vacancy_order_id: Yup.number().required("Vacancy Package is required"),
    //show_logo: Yup.boolean().required("Package Validation is required"),
    //status: Yup.string().required("Status is required"),
    // category_id: Yup.string().required("Category is required"),
  });

  const [openAlert, setOpenAlert] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  useEffect(() => {
    setLoading(false);

    // Format the start and end dates if they are provided as Dayjs objects
    if (customer) {
      const formattedStartDate = customer.start_date
        ? dayjs(customer.start_date).format("YYYY-MM-DD")
        : null;
      const formattedEndDate = customer.end_date
        ? dayjs(customer.end_date).format("YYYY-MM-DD")
        : null;
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
    } else {
      // If customer data doesn't exist, set default values for start and end dates
      setStartDate(null);
      setEndDate(null);
    }
  }, [dispatch]);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    setStartDate(formattedDate); // Update the state directly with the formatted date
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    setEndDate(formattedDate); // Update the state directly with the formatted date
  };
  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editMajorCompany({
              id: customer.id || 0,
              data: {
                user_id: values.user_id,
                block_number: values.block_number,
                status: values.status,
                vacancy_order_id: values.vacancy_order_id,
                start_date: startDate,
                end_date: endDate,
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "ads/editMajorCompany/fulfilled") {
              openSnackbar({
                open: true,
                message: "Major Company update successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(
            addMajorCompany({
              user_id: values.user_id,
              block_number: values.block_number,
              status: values.status,
              vacancy_order_id: values.vacancy_order_id,
              start_date: startDate,
              end_date: endDate,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "ads/addMajorCompany/fulfilled") {
              openSnackbar({
                open: true,
                message: "Major Company added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  const { value, onChange, onBlur } = getFieldProps("");
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>
              {customer ? "Edit VIP Company" : "New VIP Company"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-user_id">
                          Company Name
                        </InputLabel>
                        <Select
                          id="customer-user_id"
                          displayEmpty
                          {...getFieldProps("user_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedUser = userName.find(
                              (user) => user.id === selectedId,
                            );
                            const selectedUserName = selectedUser
                              ? selectedUser.user_name
                              : "";
                            setFieldValue("user_id", selectedId);
                            setFieldValue("user_name", selectedUserName); // Set user name value to another field
                          }}
                          value={getFieldProps("user_id").value || ""}
                        >
                          {userName &&
                            userName.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.user_name}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.user_id && errors.user_id && (
                          <FormHelperText error>
                            {errors.user_id}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-vacancy_order_id">
                          Vacancy Order Id
                        </InputLabel>
                        <Select
                          id="customer-vacancy_order_id"
                          displayEmpty
                          {...getFieldProps("vacancy_order_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedPackage = Packages.find(
                              (pkg: any) => pkg.id === selectedId,
                            );
                            const selectedType = selectedPackage
                              ? selectedPackage.id
                              : "";
                            setFieldValue("vacancy_order_id", selectedId);
                            setFieldValue("vacancy_order_id", selectedType); // Set type value to another field
                          }}
                          value={getFieldProps("vacancy_order_id").value || ""}
                        >
                          {Packages &&
                            Packages.map((pkg: any) => (
                              <MenuItem key={pkg.id} value={pkg.id}>
                                {pkg.id}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.vacancy_order_id &&
                          errors.vacancy_order_id && (
                            <FormHelperText error>
                              {errors.vacancy_order_id}
                            </FormHelperText>
                          )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-block_number">
                          Block Number
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-block_number"
                          placeholder="Block Number"
                          {...getFieldProps("block_number")}
                          error={Boolean(
                            touched.block_number && errors.block_number,
                          )}
                          helperText={
                            touched.block_number && errors.block_number
                          }
                        />
                        {touched.block_number && errors.block_number && (
                          <FormHelperText error>
                            {errors.block_number}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-status">
                          Status
                        </InputLabel>
                        <Select
                          id="customer-status"
                          displayEmpty
                          {...getFieldProps("status")}
                          onChange={(event: SelectChangeEvent<boolean>) => {
                            // Convert true to '1' and false to '0'
                            const selectedValue = event.target.value;

                            // Set the field value to the converted value
                            setFieldValue("status", selectedValue);
                          }}
                          // rest of the code
                        >
                          <MenuItem value={false}>Inactive</MenuItem>
                          <MenuItem value={true}>Active</MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-start_date">
                          Start Date
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={startDate ? dayjs(startDate) : null}
                            onChange={(date) => handleDateChange(date)}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-end_date">
                          End Date
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={endDate ? dayjs(endDate) : null}
                            onChange={(date) => handleEndDateChange(date)}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton
                        onClick={() => setOpenAlert(true)}
                        size="large"
                        color="error"
                      >
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? "Edit" : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {customer && (
        <AlertMajorCompanyDelete
          fetchData={fetchData}
          id={customer.id!}
          title={customer.id}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default FormMajorCompanyAdd;
