import { useEffect, useState } from "react";
import dayjs from "dayjs";
// material-ui
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch } from "hooks";

import { openSnackbar } from "api/snackbar";
import { SnackbarProps } from "types/snackbar";
import {
  editRecoResumes,
  getRecoResumes,
} from "store/actions/main/cv/recomendation/recommendation";

// types

function EditRecoDialog({
  open,
  cvId,
  setOpen,
  language,
  setLanguage,
  resumeId,
  setLoading,
  loading,
  fetchGetOneReco,
}: {
  setLoading: any;
  loading: any;
  fetchGetOneReco: any;
  resumeId: any;
  cvId: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  language: any;
  setLanguage: any;
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useAppDispatch();

  const [selectedStaus, setSelectedStatus] = useState(false);
  const [selectedRead, setSelectedRead] = useState(false);

  const fetchGetOne = () => {
    dispatch(getRecoResumes({ id: cvId })).then((action) => {
      const data = action.payload["result: "];
      setLanguage(data);
      setSelectedStatus(language?.status || "");
      setSelectedRead(language?.read_by_admin || "");
    });
  };

  useEffect(() => {
    fetchGetOne();
  }, [dispatch]);

  const handleSave = async () => {
    // Log average value for debugging
    setLoading(true);
    dispatch(
      editRecoResumes({
        id: cvId,
        data: {
          resume_id: resumeId,
          recommender_name: language.recommender_name,
          position: language.position,
          company_name: language.company_name,
          job_title: language.job_title,
          mobile: language.mobile,
          email: language.email,
          status: selectedStaus,
          read_by_admin: selectedRead,
        },
      }),
    ).then((action) => {
      if (action.type === "recommendation/editRecoResumes/fulfilled") {
        openSnackbar({
          open: true,
          message: "Recommendation Updated successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);

        setOpen(false);
        setLoading(false);
        fetchGetOneReco();
        //  window.location.reload();
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
        setOpen(false);
        setLoading(false);
      }
    });
  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setLanguage((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <DialogTitle style={{ fontWeight: "bold" }}>Refences</DialogTitle>
        <CloseIcon
          style={{
            marginTop: "12px",
            marginRight: "20px",
            width: "20px",
            height: "20px",
            cursor: "pointer",
          }}
          onClick={() => setOpen(false)}
        />
      </Stack>

      <DialogContent>
        <Box sx={{ p: 2.5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-recommender_name">
                  Refencer Name
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-recommender_name"
                  value={language?.recommender_name || ""}
                  name="recommender_name"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Recommender Name"
                  autoFocus
                  //inputRef={inputRef}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-position">
                  Job Position
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-position"
                  value={language?.position || ""}
                  name="position"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Job Position"
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-company_name">
                  Company Name
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-company_name"
                  value={language?.company_name || ""}
                  name="company_name"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Company Name"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-job_title">Job Title</InputLabel>
                <TextField
                  fullWidth
                  id="personal-job_title"
                  value={language?.job_title || ""}
                  name="job_title"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Job Title"
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-mobile">Mobile Number</InputLabel>
                <TextField
                  type="mobile"
                  fullWidth
                  value={language?.mobile || ""}
                  name="mobile"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  id="personal-email"
                  placeholder="Mobile Number"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-email">Email</InputLabel>
                <TextField
                  fullWidth
                  id="personal-email"
                  value={language?.email || ""}
                  name="email"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Email"
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-staus">Status</InputLabel>
                <Select
                  id="staus"
                  value={selectedStaus} // Set the selected ID as the value of the Select component
                  onChange={(event) => setSelectedStatus(event.target.value)} // Handle the change event
                >
                  <MenuItem value={true}>true</MenuItem>
                  <MenuItem value={false}>false</MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-staus">Read By Admin</InputLabel>
                <Select
                  id="read_by_admin"
                  value={selectedRead} // Set the selected ID as the value of the Select component
                  onChange={(event) => setSelectedRead(event.target.value)} // Handle the change event
                >
                  <MenuItem value={true}>true</MenuItem>
                  <MenuItem value={false}>false</MenuItem>
                </Select>
              </Stack>
            </Grid>
          </Grid>
          <Stack display={"flex"} alignItems={"end"} marginTop={"20px"}>
            <Button type="submit" variant="contained" onClick={handleSave}>
              EDIT
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default EditRecoDialog;
