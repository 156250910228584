const GET_EDUCATIONAL_TYPE = "educational_institutions";

const ADD_EDUCATIONAL_TYPE = "educational_institutions";

const GET_ONE_EDUCATIONAL_TYPE = "educational_institutions/";

const EDIT_EDUCATIONAL_TYPE = "educational_institutions/";

const DELETE_EDUCATIONAL_TYPE = "educational_institutions/";

const MULTI_DELET_EDUCATIONAL_TYPE =
  "educational_institutions/remove/bulk_delete";

export default {
  GET_EDUCATIONAL_TYPE,
  GET_ONE_EDUCATIONAL_TYPE,
  EDIT_EDUCATIONAL_TYPE,
  ADD_EDUCATIONAL_TYPE,
  DELETE_EDUCATIONAL_TYPE,

  MULTI_DELET_EDUCATIONAL_TYPE,
};
