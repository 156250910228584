import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  activeAccount,
  addNewCompany,
  deactivateAccount,
  deleteCompany,
  editCompany,
  FilterCompanies,
  getAllCompanies,
  getOneCompany,
  MultiDeletCompanies,
  SearchCompanies,
  toggleAccountStatus,
  totalAllCompanies,
} from "../../actions/main/companies";
import { Company, CompanyState } from "model/company";

const initialState: CompanyState = {
  result: [],
  isOk: false,
  message: "",
  confirmCode: "",
  addCompany: {
    user_name: "",
    account_language: "",
    password: "",
    account_status: "",
    account_type: "",
    email: "",
    mobile_number: "",
  },
};

const companySlice = createSlice({
  name: "company",
  initialState: initialState,
  reducers: {
    resetStateStatus: (state) => {
      state.isOk = false;
      state.message = "";
    },
    toggleAccountStatusSuccess: (state, action) => {
      const { id, status } = action.payload;
      // Ensure that state.result is an array
      if (Array.isArray(state.result)) {
        const companyIndex = state.result.findIndex(
          (company) => company.id === id,
        );
        if (companyIndex !== -1) {
          // Update the account_status of the company
          state.result[companyIndex].account_status = status;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllCompanies.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getAllCompanies.fulfilled.type,
        (state, action: PayloadAction<Company[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewCompany.fulfilled.type,
        (state, action: PayloadAction<Company[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewCompany.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getOneCompany.fulfilled.type,
        (state, action: PayloadAction<Company[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        getOneCompany.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editCompany.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editCompany.fulfilled.type,
        (state, action: PayloadAction<Company[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        deleteCompany.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        deleteCompany.fulfilled.type,
        (state, action: PayloadAction<Company[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        activeAccount.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        activeAccount.fulfilled.type,
        (state, action: PayloadAction<Company[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        deactivateAccount.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        deactivateAccount.fulfilled.type,
        (state, action: PayloadAction<Company[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        MultiDeletCompanies.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        MultiDeletCompanies.fulfilled.type,
        (state, action: PayloadAction<Company[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        FilterCompanies.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        FilterCompanies.fulfilled.type,
        (state, action: PayloadAction<Company[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        SearchCompanies.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        SearchCompanies.fulfilled.type,
        (state, action: PayloadAction<Company[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        totalAllCompanies.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        totalAllCompanies.fulfilled.type,
        (state, action: PayloadAction<Company[]>) => {
          state.result = action.payload;
        },
      );
  },
});

export const { resetStateStatus, toggleAccountStatusSuccess } =
  companySlice.actions;

export default companySlice;
