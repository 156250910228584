import { CardHeader, Button, Stack, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useAppDispatch } from "hooks";
import { JobApplicationModel } from "model/cv/reqJob/reqJob";
import { TableReqJob } from "./TableReqJob";

import RequiredJobDialog from "./RequiredJob";
import { Job } from "model/cv/targeJob/targetJob";
import { EditOutlined } from "@mui/icons-material";
import { EyeOutlined } from "@ant-design/icons";

const RequiredJobSection = ({
  openReqJob,
  setOpenReqJob,
  cvId,
  setLoading,
  loading,
  fetchGetOneReq,
  basicInfo,
}: {
  fetchGetOneReq: any;
  loading: any;
  setLoading: any;
  basicInfo: any;
  openReqJob: any;
  setOpenReqJob: (openReqJob: boolean) => void;
  cvId: any;
}) => {
  const [showTable, setShowTable] = useState(false);

  const [rows, setRows] = useState<Job[]>([
    {
      id: 0,
      resume_id: 0,
      job_availability_id: 0,
      goals: "",
      salary_expected: "",
      currency: "",
      countries: [],
      job_types: [],
      categories: [],
      confidential: false,
      // Assuming it can be null and it's a string (URL?)
    },
  ]);
  const dispatch = useAppDispatch();

  return (
    <>
      <Stack
        direction={"row"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "17px",
          marginBottom: "10px",
        }}
      >
        <Stack direction={"column"}>
          <CardHeader
            title="Targeted Job"
            // Add cursor pointer for indication
          />
          <Stack style={{ paddingLeft: "10px" }}>
            {/* Pass educationCertificates as prop to TableEducation */}
            <TableReqJob
              fetchGetOneReq={fetchGetOneReq}
              setShowTable={setShowTable}
              resumeId={cvId}
              reqJob={basicInfo}
              setLoading={setLoading}
              loading={loading}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default RequiredJobSection;
