import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// project import
import { APP_DEFAULT_PATH } from 'config';
import useAuth from 'hooks/useAuth';
// ==============================|| GUEST GUARD ||============================== //
var GuestGuard = function (_a) {
    var children = _a.children;
    var isLoggedIn = useAuth().isLoggedIn;
    var navigate = useNavigate();
    var location = useLocation();
    useEffect(function () {
        var _a, _b;
        if (isLoggedIn) {
            navigate(((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.from) ? (_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.from : APP_DEFAULT_PATH, {
                state: {
                    from: ''
                },
                replace: true
            });
        }
    }, [isLoggedIn, navigate, location]);
    return children;
};
export default GuestGuard;
