import { createAsyncThunk } from "@reduxjs/toolkit";
import categorUrl from "../../../utils/url/categories";
//axios
import { AXIOS } from "../../../api/axios";

//error message
import responseError from "../../../utils/api/error-handling";

//get AllCategories
export const getAllCategories = createAsyncThunk(
  "category/getAllCategories",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `${categorUrl.GET_CATEGORIES}?page=${pageNumber}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//get One Category

export const getOneCategory = createAsyncThunk(
  "category/getOneCategory",
  async ({ categoryId }: { categoryId: number }, thunkAPI) => {
    try {
      const response = await AXIOS.get(`${categorUrl.CATEGORIES}${categoryId}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//Edit One Category

export const editOneCategory = createAsyncThunk(
  "category/editOneCategory",
  async ({ categoryId, data }: { categoryId: number; data: any }, thunkAPI) => {
    // Adjusted payload creator function
    // Destructure categoryId and data from payload
    try {
      const response = await AXIOS.post(
        `${categorUrl.CATEGORIES}${categoryId}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//add New Category
export const addNewCategory = createAsyncThunk(
  "category/addNewCategory",
  async (data: any, thunkAPI) => {
    try {
      const response = await AXIOS.post(categorUrl.GET_CATEGORIES, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//delete Category

export const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async ({ categoryId }: { categoryId: number }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(
        `${categorUrl.CATEGORIES}${categoryId}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletCategories = createAsyncThunk(
  "category/MultiDeletCategories",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(categorUrl.MULTI_DELET, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const SearchCategories = createAsyncThunk(
  "categories/SearchCategories",
  async ({ data }: { data: any }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `${categorUrl.SEARCH_CATEGORIES}${data}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const ParentCategories = createAsyncThunk(
  "categories/ParentCategories",
  async (_, thunkAPI) => {
    try {
      const response = await AXIOS.get(categorUrl.GET_PARENT_CAT);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const GetChildCategories = createAsyncThunk(
  "categories/GetChildCategories",
  async (_, thunkAPI) => {
    try {
      const response = await AXIOS.get(categorUrl.CHILD_CAT);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
