import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import resumeOrderURL from "../../../../utils/url/orders/resumeOrder";

//get AllCategories
export const getAllResumeOrder = createAsyncThunk(
  "order/getAllResumeOrder",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${resumeOrderURL.GET_RESUME_ORDERS}?page=${pageNumber}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//get One Category

export const getOneResumeOrder = createAsyncThunk(
  "order/getOneResumeOrder",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${resumeOrderURL.GET_ONE_RESUME_ORDERS}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//Edit One Category

export const editOneResumeOrder = createAsyncThunk(
  "order/editOneResumeOrder",
  async ({ id, data }: { id: number; data: any }, thunkAPI) => {
    // Adjusted payload creator function
    // Destructure categoryId and data from payload
    try {
      const response = await USERAXIOS.post(
        `${resumeOrderURL.EDIT_RESUME_ORDERS}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//add New Category
export const addNewResumeOrder = createAsyncThunk(
  "order/addNewResumeOrder",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        resumeOrderURL.ADD_RESUME_ORDERS,
        data,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//delete Category

export const deleteResumeOrder = createAsyncThunk(
  "order/deleteResumeOrder",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${resumeOrderURL.DELETE_RESUME_ORDERS}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletResumeOrder = createAsyncThunk(
  "order/MultiDeletResumeOrder",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(resumeOrderURL.MULTI_DELET, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const FilterResumeOrder = createAsyncThunk(
  "order/FilterResumeOrder",
  async (
    {
      created_at_end,
      created_at_start,
      status,
    }: {
      created_at_end?: Date | null;
      created_at_start?: Date | null;

      status?: any;
    },
    thunkAPI,
  ) => {
    try {
      const data = {
        created_at_end,
        created_at_start,

        status,
      };
      const response = await USERAXIOS.post(
        resumeOrderURL.FILTER_RESUME_ORDER,
        data,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const totalALLCVOrder = createAsyncThunk(
  "resumes/totalALLCVOrder",
  async (_, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(resumeOrderURL.TOTAL_CV_ORDERS);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
