import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import targetJobURL from "../../../../../utils/url/cv/targetJob/targetJob";
//axios

//get AllBasicInfo
export const getAllTargetJob = createAsyncThunk(
  "reqJob/getAllTargetJob",
  async (_, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(targetJobURL.GET_ALL_TARGET_JOB);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//get One basicInfo

export const getOneTargetJob = createAsyncThunk(
  "reqJob/getOneTargetJob",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${targetJobURL.GET_ONE_TARGET_JOB}${id}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//Edit One Category

export const editTargetJob = createAsyncThunk(
  "reqJob/editTargetJob",
  async ({ id, data }: { id: number; data: any }, thunkAPI) => {
    // Adjusted payload creator function
    // Destructure categoryId and data from payload
    try {
      const response = await USERAXIOS.post(
        `${targetJobURL.EDIT_TARGET_JOB}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//add New basicIno
export const addNewTargetJob = createAsyncThunk(
  "reqJob/addNewTargetJob",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(targetJobURL.ADD_TARGET_JOB, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//delete basic Info

export const deleteTargetJob = createAsyncThunk(
  "reqJob/deleteTargetJob",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${targetJobURL.DELETE_ARGET_JOB}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
