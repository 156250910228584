const GET_ALL_EDUCATION = "resume/educations";

const ADD_EDUCATION = "resume/educations";

const EDIT_EDUCATION = "resume/educations/";

const GET_ONE_EDUCATION = "resume/educations/";

const DELETE_EDUCATION = "resume/educations/";

const MULTI_DELET_EDUCATION = "resume/educations/remove/bulk_delete";

export default {
  GET_ALL_EDUCATION,
  ADD_EDUCATION,

  EDIT_EDUCATION,

  GET_ONE_EDUCATION,
  DELETE_EDUCATION,
  MULTI_DELET_EDUCATION,
};
