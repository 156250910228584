import { useEffect, useState, ChangeEvent } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Autocomplete,
} from "@mui/material";

// third-party
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// project imports
import IconButton from "components/@extended/IconButton";
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets
import { DeleteFilled } from "@ant-design/icons";

// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";

import AlertCompanyDelete from "./AlertResumeDelete";

import {
  addNewVacancy,
  editOneVacancy,
  getAllVacancies,
} from "store/actions/main/packages/vacanciesPackages";
import { getAllCompanies } from "store/actions/main/companies";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { getAllResumes } from "store/actions/main/packages/resumesPackages";
import {
  addNewResSub,
  editOneResumeSub,
} from "store/actions/main/subscription/resumeSub";
import { ResumeOrder } from "model/order";
import AlertResumesDelete from "./AlertResumeDelete";
import {
  addNewResumeOrder,
  editOneResumeOrder,
} from "store/actions/main/order/resumeOrder";

// constant
const getInitialValues = (customer: ResumeOrder | null) => {
  const newCustomer = {
    user_id: "",
    resume_package_id: "",
    payment_id: "",

    status: 0,
  };
  if (customer) {
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormResumeAdd = ({
  customer,
  id,
  closeModal,
  fetchData,
}: {
  fetchData: any;
  id: number;
  customer: ResumeOrder | null;
  closeModal: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [userName, setUserName] = useState();
  const [Packages, setPackages] = useState();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectPackageID, setSelectPackageID] = useState(null);

  useEffect(() => {
    setLoading(false);
    fetchComData();
    fetchDataPackages();

    // Format the start and end dates if they are provided as Dayjs objects
  }, [dispatch]);

  const CustomerSchema = Yup.object().shape({
    user_id: Yup.string().required("Company Name is required"),
    resume_package_id: Yup.string().required("Resumes Package is required"),
    status: Yup.string().required("Status is required"),
    payment_id: Yup.number().required("Payment is required"),
  });

  const [openAlert, setOpenAlert] = useState(false);
  const fetchComData = () => {
    dispatch(getAllCompanies({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setUserName(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };

  const fetchDataPackages = () => {
    dispatch(getAllResumes({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setPackages(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editOneResumeOrder({
              id: customer.id || 0,
              data: {
                user_id: values.user_id,
                resume_package_id: values.resume_package_id,
                payment_id: values.payment_id,
                status: values.status === 1 ? true : false,
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "order/editOneResumeOrder/fulfilled") {
              openSnackbar({
                open: true,
                message: "Resume Orders updated successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            } else {
              openSnackbar({
                open: true,
                message: "some thing went wrong",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "error",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(
            addNewResumeOrder({
              user_id: values.user_id,
              resume_package_id: values.resume_package_id,
              payment_id: values.payment_id,
              status: values.status === 1 ? true : false,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "order/addNewResumeOrder/fulfilled") {
              openSnackbar({
                open: true,
                message: "Resume Orders added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            } else {
              openSnackbar({
                open: true,
                message: "some thing went wrong",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "error",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  const { value, onChange, onBlur } = getFieldProps("");
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>
              {customer
                ? "Edit Resume Subscriptiopn"
                : "New Resume Subscription"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={10} marginLeft={"10%"}>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-user_id">
                          Company Name
                        </InputLabel>
                        <Select
                          id="customer-user_id"
                          displayEmpty
                          {...getFieldProps("user_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedUser = userName.find(
                              (user) => user.id === selectedId,
                            );
                            const selectedUserName = selectedUser
                              ? selectedUser.user_name
                              : "";
                            setFieldValue("user_id", selectedId);
                            setFieldValue("user_name", selectedUserName); // Set user name value to another field
                          }}
                          value={getFieldProps("user_id").value || ""}
                        >
                          {userName &&
                            userName.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.user_name}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.user_id && errors.user_id && (
                          <FormHelperText error>
                            {errors.user_id}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-resume_package_id">
                          Packages
                        </InputLabel>
                        <Select
                          id="customer-resume_package_id"
                          displayEmpty
                          {...getFieldProps("resume_package_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedPackage = Packages.find(
                              (pkg: any) => pkg.id === selectedId,
                            );
                            const selectedType = selectedPackage
                              ? selectedPackage.id
                              : "";
                            setFieldValue("resume_package_id", selectedId);
                            setFieldValue("resume_package_id", selectedType); // Set type value to another field
                          }}
                          value={getFieldProps("resume_package_id").value || ""}
                        >
                          {Packages &&
                            Packages.map((pkg: any) => (
                              <MenuItem key={pkg.id} value={pkg.id}>
                                {pkg.id}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.resume_package_id &&
                          errors.resume_package_id && (
                            <FormHelperText error>
                              {errors.resume_package_id}
                            </FormHelperText>
                          )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-payment_id">
                          Payment
                        </InputLabel>
                        <Select
                          id="customer-payment_id"
                          displayEmpty
                          {...getFieldProps("payment_id")}
                          onChange={(event: SelectChangeEvent<boolean>) => {
                            // Convert true to '1' and false to '0'
                            const selectedValue = event.target.value;

                            // Set the field value to the converted value
                            setFieldValue("payment_id", selectedValue);
                          }}
                          // rest of the code
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={1}>11</MenuItem>
                        </Select>
                        {touched.payment_id && errors.payment_id && (
                          <FormHelperText error>
                            {errors.payment_id}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-type">Status</InputLabel>
                        <Select
                          id="customer-status"
                          displayEmpty
                          {...getFieldProps("status")}
                          onChange={(event: SelectChangeEvent<boolean>) => {
                            // Convert true to '1' and false to '0'
                            const selectedValue = event.target.value;

                            // Set the field value to the converted value
                            setFieldValue("status", selectedValue);
                          }}
                          // rest of the code
                        >
                          <MenuItem value={1}>ACTIVE</MenuItem>
                          <MenuItem value={0}>INACTIVE</MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton
                        onClick={() => setOpenAlert(true)}
                        size="large"
                        color="error"
                      >
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? "Edit" : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {customer && (
        <AlertResumesDelete
          id={customer.id!}
          title={customer.company_name}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default FormResumeAdd;
