// material-ui
import { useTheme } from "@mui/material/styles";
import {
  useMediaQuery,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";

// project import
import MainCard from "components/MainCard";

// types
interface StatusProps {
  value: number;
  label: string;
}

// ==============================|| EXPANDING TABLE - USER DETAILS ||============================== //

const ExpandingCurrencyDetail = ({ data }: any) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid
      container
      spacing={3.5}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Grid item xs={12} sm={7} md={12} xl={8.5}>
        <Stack spacing={2.5}>
          <MainCard title="Currency Details">
            <List sx={{ py: 0 }}>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Name</Typography>
                      <Typography>{data.name}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Arabic Name</Typography>
                      <Typography>{data.name_trans}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Created At</Typography>
                      <Typography>{data.created_at}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Updated At</Typography>
                      <Typography>{data.updated_at}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </MainCard>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ExpandingCurrencyDetail;
