import { createAsyncThunk } from "@reduxjs/toolkit";
import paymentUrl from "../../../utils/url/payment";

//error message
import responseError from "../../../utils/api/error-handling";
import { USERAXIOS } from "api";

//get AllUser
export const getAllPayment = createAsyncThunk(
  "payment/getAllPayment",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${paymentUrl.GET_PAYMENT}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOnePayment = createAsyncThunk(
  "payment/getOnePayment",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${paymentUrl.GET_ONE_PAYMENT}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editPayment = createAsyncThunk(
  "payment/editPayment",
  async ({ id, data }: { id: number; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${paymentUrl.EDIT_PAYMENT}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addPayment = createAsyncThunk(
  "payment/addPayment",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(paymentUrl.ADD_PAYMENT, data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deletePayment = createAsyncThunk(
  "payment/deletePayment",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${paymentUrl.DELET_PAYMENT}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletPayment = createAsyncThunk(
  "payment/MultiDeletPayment",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(paymentUrl.MULTI_DELET_PAYMENT, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
