// project import

import useResumeItems from "./resume";
import useWorkItems from "./work";
import usePackagesItem from "./packages";

import useADSItem from "./ads";
import useBasicInfoItems from "./basicInfo";
import usePaymentItems from "./payment";
import useEducationItem from "./education";
import userManagmentItems from "./userManagment";
import dashboardManagmentItems from "./dashboard";
import useCompanyItem from "./company";
import useServiceItem from "./serviceMang";
import useCustomerCornerItem from "./customerCorner";
import useVacacinesItem from "./vacacines";
import useUsefulInfoItems from "./usefulInfo";

// ==============================|| MENU ITEMS ||============================== //

// Functional component to use the hook and define the menu items
const Menu = () => {
  // Call useDashboardItems hook within the functional component
  const dashboard = dashboardManagmentItems();
  const user = userManagmentItems();
  const useful = useUsefulInfoItems();
  const resume = useResumeItems();
  const company = useCompanyItem();
  const service = useServiceItem();
  const work = useWorkItems();
  const packages = usePackagesItem();
  const basicInfo = useBasicInfoItems();
  const edu = useEducationItem();
  const ads = useADSItem();
  const customer = useCustomerCornerItem();
  const payment = usePaymentItems();
  const vacacines = useVacacinesItem();
  // Define the menu items
  const menuItems = {
    items: [
      dashboard,
      company,
      resume,
      work,
      vacacines,
      packages,
      customer,
      service,
      edu,
      useful,
      basicInfo,
      payment,
      ads,
      user,
    ],
  };

  return menuItems; // Return the menu items
};

export default Menu;
