import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import { FormattedMessage } from "react-intl";
const useServiceItem = () => {
  const icons = {
    CategoryOutlinedIcon,
    LocationCityOutlinedIcon,
  };

  const servicceItems = {
    id: "group-service-managment",
    title: (
      <FormattedMessage id="service-managment">
        {(text) => (
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>{text}</span>
        )}
      </FormattedMessage>
    ),
    type: "group",
    children: [
      {
        id: "service-persons",
        title: <FormattedMessage id="service-persons" />,
        type: "item",
        url: "/service-persons",
        icon: icons.CategoryOutlinedIcon,
        breadcrumbs: false,
      },
      {
        id: "user-services",
        title: <FormattedMessage id="user-services" />,
        type: "item",
        url: "/user-services",
        icon: icons.LocationCityOutlinedIcon,
        breadcrumbs: false,
      },
      {
        id: "user-service-request",
        title: <FormattedMessage id="user-service-request" />,
        type: "item",
        url: "/user-service-request",
        icon: icons.LocationCityOutlinedIcon,
        breadcrumbs: false,
      },
      {
        id: "company-service-request",
        title: <FormattedMessage id="company-service-request" />,
        type: "item",
        url: "/company-service-request",
        icon: icons.LocationCityOutlinedIcon,
        breadcrumbs: false,
      },
    ],
  };

  return servicceItems;
};

export default useServiceItem;
