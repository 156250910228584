import { RefObject, useEffect, useState } from "react";
import { useOutletContext } from "react-router";

// material-ui
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Select,
  FormHelperText,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// project import
import { openSnackbar } from "api/snackbar";

// types
import { useAppDispatch } from "hooks";

import {
  GetChildCountries,
  ParentCountries,
} from "store/actions/main/countries";
import TextArea from "antd/es/input/TextArea";
import * as Yup from "yup";
import { SnackbarProps } from "types/snackbar";
import { addNewCourse } from "store/actions/main/cv/courses/courses";
const validationSchema = Yup.object().shape({
  institute: Yup.string().required("Educatioanl institution is required"),
  course_name: Yup.string().required("Course Name is required"),
  course_descriptions: Yup.string().required("Course Description is required"),
  course_field: Yup.string().required("Course Field is required"),
});
function CoursesDialog({
  cvId,
  openCourses,
  setLoading,
  loading,
  fetchGetOneCourse,
  setOpenCourses,
}: {
  setLoading: any;
  loading: any;
  fetchGetOneCourse: any;
  cvId: number;
  openCourses: boolean;
  setOpenCourses: (openCourses: boolean) => void;
}) {
  const [courseData, setCourseData] = useState({
    resume_id: 0,
    educational_institution_id: 0,
    course_name: "",
    country: "",
    city: "",
    institute: "",
    additional_info: "",
    certificate_date: "",
    course_field: "",
    course_descriptions: "",
    certificate: false,
  });

  const handleClose = () => {
    setOpenCourses(false);
  };
  const dispatch = useAppDispatch();
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [country, setCountry] = useState();

  const [childCities, setChildCities] = useState([]);
  const [selectedCityName, setSelectedCityName] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [certificate, setcertificate] = useState(false);
  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value; // Get the selected country ID
    setSelectedCountry(selectedCountryId); // Update the selected country ID state

    // Find the selected country object
    const selectedCountryObject = country.find(
      (country) => country.id === selectedCountryId,
    );

    if (selectedCountryObject) {
      setSelectedCountryName(selectedCountryObject.name); // Set the selected country name

      // Set the selected city to "All Cities" if the selected country is not the first item in the list
      if (selectedCountryId == "1") {
        dispatch(GetChildCountries({ id: 1 })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        }); // Disable city selection

        setSelectedCity("");
      } else {
        dispatch(GetChildCountries({ id: 2 })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        });
        setSelectedCity("");
      }
    }
  };

  const fetchDataPackages = () => {
    dispatch(ParentCountries())
      .then((action) => {
        const { data } = action.payload;
        // Set user names as options for autocomplete
        setCountry(data);
        // Set default selected country to the first one in the list
        if (data.length > 0) {
          setSelectedCountry(data[0].id);
          setSelectedCountryName(data[0].name);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = childCities.find((city) => city.id === selectedCityId);
    setSelectedCity(selectedCityId);
    // Set the selected city name
    setSelectedCityName(selectedCity?.name || "");
  };
  useEffect(() => {
    fetchDataPackages();
    dispatch(GetChildCountries({ id: 1 })).then((action) => {
      const { data } = action.payload;
      setChildCities(data);
      if (data.length > 0) {
        setSelectedCity(data[0].id);
        setSelectedCityName(data[0].name);
      }
      // Set fetched child cities to state
    });
  }, [dispatch]);
  const [formErrors, setFormErrors] = useState({});

  const validate = async () => {
    try {
      // Combine all data to be validated
      const dataToValidate = {
        ...courseData,

        country: selectedCountry, // Include selected job availability ID
        city: selectedCity,
        certificate: certificate,
      };

      // Validate the combined data against the validation schema
      await validationSchema.validate(dataToValidate, { abortEarly: false });
      setFormErrors({});
      return true; // Return true if validation succeeds
    } catch (errors) {
      // If validation fails, extract error messages
      const errorMessages = {};
      errors.inner.forEach((error) => {
        errorMessages[error.path] = error.message;
      });
      setFormErrors(errorMessages); // Set error messages for each field
      return false;
    }
  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setCourseData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const isValid = await validate();
    if (isValid) setLoading(true);
    dispatch(
      addNewCourse({
        resume_id: cvId,
        institute: courseData.institute,
        course_name: courseData.course_name,
        country: selectedCountryName,
        city: selectedCityName,
        certificate_date: courseData.certificate_date,
        course_field: courseData.course_field,
        course_descriptions: courseData.course_descriptions,
        certificate: certificate,
      }),
    ).then((action) => {
      if (action.type === "course/addNewCourse/fulfilled") {
        openSnackbar({
          open: true,
          message: "Course Added successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);
        setOpenCourses(false);
        setLoading(false);
        fetchGetOneCourse();
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
        setOpenCourses(false);
        setLoading(false);
      }
    });
  };
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= currentYear - 90; i--) {
    years.push(i);
  }
  return (
    <Dialog open={openCourses} onClose={handleClose}>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <DialogTitle style={{ fontWeight: "bold" }}>
          Attended Courses
        </DialogTitle>
        <CloseIcon
          style={{
            marginTop: "12px",
            marginRight: "20px",
            width: "20px",
            height: "20px",
            cursor: "pointer",
          }}
          onClick={() => setOpenCourses(false)}
        />
      </Stack>

      <DialogContent>
        <Box sx={{ p: 2.5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="institute">Institute</InputLabel>
                <TextField
                  fullWidth
                  id="personal-institute"
                  value={courseData.institute}
                  name="institute"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Institute"
                  autoFocus
                  //inputRef={inputRef}
                />
                {formErrors.institute && (
                  <FormHelperText error>{formErrors.institute}</FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-course_name">
                  Course Name
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-course_name"
                  value={courseData.course_name}
                  name="course_name"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Course Name"
                  autoFocus
                  //inputRef={inputRef}
                />
                {formErrors.course_name && (
                  <FormHelperText error>
                    {formErrors.course_name}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-last-name">
                  Course Field
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-last-name"
                  value={courseData.course_field}
                  name="course_field"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Course Field"
                />
                {formErrors.course_field && (
                  <FormHelperText error>
                    {formErrors.course_field}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="certificate_date">
                  Certificate Date
                </InputLabel>
                <Select
                  fullWidth
                  id="certificate_date"
                  value={courseData.certificate_date}
                  onChange={handleInputChange}
                  name="certificate_date"
                  placeholder="Certificate Date"
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <Stack direction={"row"} display={"flex"}>
                  <InputLabel htmlFor="country">Country</InputLabel>
                  <InputLabel style={{ marginLeft: "130px" }} htmlFor="city">
                    City
                  </InputLabel>
                </Stack>
                <Stack direction={"row"}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    // alignItems="center"
                    spacing={2}
                  >
                    <FormControl fullWidth>
                      <Select
                        style={{ width: "140px" }}
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        //  onBlur={handleBlur}
                        inputProps={{
                          name: "country",
                          id: "country",
                        }}
                      >
                        {country &&
                          country.map((country: any) => (
                            <MenuItem key={country.id} value={country.id}>
                              {country.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <FormControl fullWidth>
                      <Select
                        style={{ marginLeft: "20px", width: "140px" }}
                        labelId="city-label"
                        id="city"
                        value={selectedCity}
                        onChange={handleCityChange}
                        // onBlur={handleBlur}
                        name="city"
                      >
                        {childCities.map((city) => (
                          <MenuItem key={city.id} value={city.id}>
                            {city.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel>Certificate</InputLabel>
                <RadioGroup
                  row
                  value={certificate}
                  onChange={(event) => setcertificate(event.target.value)}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Yes"
                    value="true"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="No"
                    value="false"
                  />
                </RadioGroup>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-course_descriptions">
                  Description
                </InputLabel>
                <TextArea
                  id="personal-course_descriptions"
                  value={courseData.course_descriptions}
                  name="course_descriptions"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Course Description"
                />
                {formErrors.course_descriptions && (
                  <FormHelperText error>
                    {formErrors.course_descriptions}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-additional_info">
                  Additional Info
                </InputLabel>
                <TextArea
                  id="personal-additional_info"
                  value={courseData.additional_info}
                  name="additional_info"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Additional Info"
                />
              </Stack>
            </Grid>
          </Grid>
          <Stack display={"flex"} alignItems={"end"} marginTop={"20px"}>
            <Button type="submit" variant="contained" onClick={handleSave}>
              Add Course
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CoursesDialog;
