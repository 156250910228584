import { createAsyncThunk } from "@reduxjs/toolkit";
import countryurl from "../../../utils/url/countries";
//axios
import { AXIOS } from "../../../api/axios";

//urls
//import aboutURL from '../../../utils/url/about';

//error message
import responseError from "../../../utils/api/error-handling";

//get AllUser
export const getAllCountries = createAsyncThunk(
  "country/getAllCountries",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `${countryurl.GET_CONTRIES}?page=${pageNumber}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//GET ONE USER

export const getOneCountry = createAsyncThunk(
  "country/getOneCountry",
  async ({ countryId }: { countryId: string }, thunkAPI) => {
    try {
      const response = await AXIOS.get(`${countryurl.GET_COUNTRY}${countryId}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editCountry = createAsyncThunk(
  "country/editCountry",
  async ({ countryId, data }: { countryId: number; data: any }, thunkAPI) => {
    try {
      const response = await AXIOS.post(
        `${countryurl.GET_COUNTRY}${countryId}`,
        data,
      );
      console.log(response.data);
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const addNewCountry = createAsyncThunk(
  "country/addCountry",
  async (data: any, thunkAPI) => {
    try {
      const response = await AXIOS.post(countryurl.GET_CONTRIES, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteCountry = createAsyncThunk(
  "country/deleteCountry",
  async ({ countryId }: { countryId: number }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(
        `${countryurl.GET_COUNTRY}${countryId}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletCountries = createAsyncThunk(
  "country/MultiDeletCountries",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(countryurl.MULTI_DELET, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const SearchCountries = createAsyncThunk(
  "countries/SearchCountries",
  async ({ data }: { data: any }, thunkAPI) => {
    try {
      const response = await AXIOS.get(`${countryurl.SEARCH_COUNTRIES}${data}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const ParentCountries = createAsyncThunk(
  "countries /ParentCountries",
  async (_, thunkAPI) => {
    try {
      const response = await AXIOS.get(countryurl.GET_PARENT_COUNTRY);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const GetChildCountries = createAsyncThunk(
  "countries /GetChildCountries",
  async ({ id }: { id: any }, thunkAPI) => {
    try {
      const response = await AXIOS.get(`${countryurl.CHILD_COUNTRY}${id}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
