import {
  EditOutlined as EditOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from "@mui/icons-material";
import {
  Grid,
  Typography,
  Stack,
  List,
  useMediaQuery,
  ListItem,
} from "@mui/material";
import { Theme } from "@mui/material/styles";

import MainCard from "components/MainCard";
import { useEffect, useState } from "react";

import { useAppDispatch } from "hooks";
import DeletLanguage from "./deleteLanguageDialog";
import EditLanguageDialog from "./editDialogLanguage";
import { getAllLanguage } from "store/actions/main/basicInfo/languages";
import { Language } from "model/cv/language/language";
import Loader from "components/Loader";

export function TableLanguage({
  cvId,
  languageData,
  setLoading,
  loading,
  fetchGetOneLang,
}: {
  setLoading: any;
  loading: any;
  fetchGetOneLang: any;
  cvId: number;
  languageData: any;
}) {
  const languageArray = languageData.resume_languages;
  // Filter education certificates based on cvId

  const matchDownMD = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );
  const [deletId, setDeletId] = useState(0);
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState<Language>();
  const [openDialog, setOpenDialog] = useState(false);
  const [languageName, setLanguageName] = useState("");
  const dispatch = useAppDispatch();

  const handleEdit = (id: number) => {
    setDeletId(id);
    setOpenDialog(true);
  };
  const handleDelete = (id: number) => {
    setDeletId(id);
    setOpen(true);
  };
  useEffect(() => {
    const fetchName = async () => {
      const action = await dispatch(getAllLanguage({ pageNumber: 1 }));
      const { data } = action.payload;

      // Process availability names
      const availabilityNames = languageArray.map((job: any) => {
        const availability = data.find((av: any) => av.id === job.language_id);
        return availability ? availability.name : "";
      });

      // Combine names into a single string or format as needed
      const combinedNames = availabilityNames.join(", ");
      setLanguageName(combinedNames);
    };

    fetchName();
  }, [languageData]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Stack>
          {languageArray.map((lang: any, index: number) => (
            <Grid width={"57vw"} sx={{ mt: "10px" }}>
              <MainCard
                title="Language"
                headerActions={
                  <Stack
                    direction={"row"}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <EditOutlinedIcon
                        sx={{
                          width: 20,
                          height: 20,
                          marginRight: 2,
                          cursor: "pointer",
                          color: "#4a934a",
                        }}
                        onClick={() => handleEdit(lang.id)}
                      />
                      <DeleteOutlinedIcon
                        sx={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          color: "#ff3333",
                          marginRight: "20px",
                        }}
                        onClick={() => handleDelete(lang.id)}
                      />
                    </div>
                    {/* Add additional controls here */}
                  </Stack>
                }
              >
                <List>
                  <ListItem divider={!matchDownMD}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Languge</Typography>

                          <Typography>{languageName}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Reading</Typography>
                          <Typography>{lang.reading_level}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Speaking</Typography>
                          <Typography>{lang.conversation_level}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Writing</Typography>
                          <Typography>{lang.writing_level}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </MainCard>
            </Grid>
          ))}
        </Stack>
      )}

      {open && (
        <DeletLanguage
          open={open}
          setOpen={setOpen}
          id={deletId}
          fetchGetOneLang={fetchGetOneLang}
        />
      )}
      {openDialog && (
        <EditLanguageDialog
          resumeId={cvId}
          cvId={deletId}
          open={openDialog}
          setOpen={setOpenDialog}
          language={language}
          setLanguage={setLanguage}
          setLoading={setLoading}
          loading={loading}
          fetchGetOneLang={fetchGetOneLang}
        />
      )}
    </>
  );
}
