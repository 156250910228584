import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import recommendationUrl from "../../../../../utils/url/cv/recommendations/recommendations";
//axios

//get AllBasicInfo
export const getAllRecoResumes = createAsyncThunk(
  "recommendation/getAllRecoResumes",
  async (_, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        recommendationUrl.GET_ALL_RECOMMENDATIONS,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//get One basicInfo

export const getRecoResumes = createAsyncThunk(
  "recommendation/getRecoResumes",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${recommendationUrl.GET_ONE_RECOMMENDATIONS}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//Edit One Category

export const editRecoResumes = createAsyncThunk(
  "recommendation/editRecoResumes",
  async ({ id, data }: { id: number; data: any }, thunkAPI) => {
    // Adjusted payload creator function
    // Destructure categoryId and data from payload
    try {
      const response = await USERAXIOS.post(
        `${recommendationUrl.EDIT_RECOMMENDATIONS}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//add New basicIno
export const addNewRecoResumes = createAsyncThunk(
  "recommendation/addNewRecoResumes",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        recommendationUrl.ADD_RECOMMENDATIONS,
        data,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//delete basic Info

export const deleteRecoResumes = createAsyncThunk(
  "recommendation/deleteRecoResumes",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${recommendationUrl.DELETE_RECOMMENDATIONS}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
