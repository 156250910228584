import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import { FormattedMessage } from "react-intl";
const useCustomerCornerItem = () => {
  const icons = {
    CategoryOutlinedIcon,
    LocationCityOutlinedIcon,
  };

  const servicceItems = {
    id: "group-customer-corner",
    title: (
      <FormattedMessage id="customer-corner">
        {(text) => (
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>{text}</span>
        )}
      </FormattedMessage>
    ),
    type: "group",
    children: [
      {
        id: "articles-list",
        title: <FormattedMessage id="articles-list" />,
        type: "item",
        url: "/articles-list",
        icon: icons.CategoryOutlinedIcon,
        breadcrumbs: false,
      },
      {
        id: "articles-types",
        title: <FormattedMessage id="articles-types" />,
        type: "item",
        url: "/articles-types",
        icon: icons.LocationCityOutlinedIcon,
        breadcrumbs: false,
      },

      {
        id: "comments-list",
        title: <FormattedMessage id="comments-list" />,
        type: "item",
        url: "/comments-list",
        icon: icons.LocationCityOutlinedIcon,
        breadcrumbs: false,
      },
    ],
  };

  return servicceItems;
};

export default useCustomerCornerItem;
