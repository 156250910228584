import { useEffect, useState } from "react";
import dayjs from "dayjs";
// material-ui
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { countriesAndCities } from "../../../../hooks/data/country";
import { useAppDispatch } from "hooks";
import { editEducation } from "store/actions/main/cv/education/education";
import TextArea from "antd/es/input/TextArea";
import { getAllEducational } from "store/actions/main/basicInfo/educational";
import {
  GetChildCountries,
  ParentCountries,
} from "store/actions/main/countries";
import {
  editCourse,
  getOneCourse,
} from "store/actions/main/cv/courses/courses";
import { openSnackbar } from "api/snackbar";
import { SnackbarProps } from "types/snackbar";
import {
  editLanguagesResumes,
  getLanguagesResumes,
} from "store/actions/main/cv/language/language";
import { getAllLanguage } from "store/actions/main/basicInfo/languages";

// types

function EditLanguageDialog({
  open,
  cvId,
  setOpen,
  language,
  setLanguage,
  resumeId,
  setLoading,
  loading,
  fetchGetOneLang,
}: {
  setLoading: any;
  loading: any;
  fetchGetOneLang: any;
  resumeId: any;
  cvId: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  language: any;
  setLanguage: any;
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useAppDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedLanguageId, setSelectedLanguageId] = useState("");
  const [selectedReading, setSelectedReading] = useState("");
  const [selectedConversation, setSelectedConversation] = useState("");
  const [selectedWriting, setSelectedWriting] = useState("");
  const fetchGetOne = () => {
    dispatch(getLanguagesResumes({ id: cvId })).then((action) => {
      const data = action.payload["result: "];
      setLanguage(data);
      setSelectedLanguageId(language?.language_id || "");
      setSelectedReading(language?.reading_level || "");
      setSelectedConversation(language?.conversation_level || "");
      setSelectedWriting(language?.writing_level || "");
    });
  };
  const fetchLanguageData = () => {
    dispatch(getAllLanguage({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setSelectedLanguage(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const handleLanguageChange = (event) => {
    const selectedEdId = event.target.value; // Get the selected ID
    setSelectedLanguageId(selectedEdId); // Set the selected ID to state
    const selectedItem = selectedLanguage.find(
      (item) => item.id === selectedEdId,
    );
    if (selectedItem) {
      const selectedName = selectedItem.name;
    }
  };
  useEffect(() => {
    fetchLanguageData();
    fetchGetOne();
  }, [dispatch]);

  const handleSave = async () => {
    // Log average value for debugging
    setLoading(true);
    dispatch(
      editLanguagesResumes({
        id: cvId,
        data: {
          resume_id: resumeId,
          language_id: selectedLanguageId,
          reading_level: selectedReading,
          conversation_level: selectedConversation,
          writing_level: selectedWriting,
        },
      }),
    ).then((action) => {
      if (action.type === "languages/editLanguagesResumes/fulfilled") {
        openSnackbar({
          open: true,
          message: "Languages Updated successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);
        setOpen(false);
        setLoading(false);
        fetchGetOneLang();
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
        setOpen(false);
        setLoading(false);
      }
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <DialogTitle style={{ fontWeight: "bold" }}>Languages</DialogTitle>
        <CloseIcon
          style={{
            marginTop: "12px",
            marginRight: "20px",
            width: "20px",
            height: "20px",
            cursor: "pointer",
          }}
          onClick={() => setOpen(false)}
        />
      </Stack>

      <DialogContent>
        <Box sx={{ p: 2.5 }}>
          <Grid container>
            <Grid item xs={12} sm={10} marginBottom={"20px"}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-language_id">Language</InputLabel>
                <Select
                  id="language_id"
                  value={selectedLanguageId} // Set the selected ID as the value of the Select component
                  onChange={handleLanguageChange} // Handle the change event
                >
                  {selectedLanguage.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
            <Stack direction={"row"}>
              <Grid item xs={12} width={"150px"} marginRight={"20px"}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="personal-reading_level">
                    Reading
                  </InputLabel>
                  <Select
                    id="reading_level"
                    value={selectedReading} // Set the selected ID as the value of the Select component
                    onChange={(event) => setSelectedReading(event.target.value)} // Handle the change event
                  >
                    <MenuItem value={"POOR"}>POOR</MenuItem>
                    <MenuItem value={"GOOD"}>GOOD</MenuItem>
                    <MenuItem value={"FLUENT"}>FLUENT</MenuItem>
                  </Select>
                </Stack>
              </Grid>
              <Grid item xs={12} width={"150px"} marginRight={"20px"}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="personal-conversation_level">
                    Speaking
                  </InputLabel>
                  <Select
                    id="conversation_level"
                    value={selectedConversation} // Set the selected ID as the value of the Select component
                    onChange={(event) =>
                      setSelectedConversation(event.target.value)
                    } // Handle the change event
                  >
                    <MenuItem value={"POOR"}>POOR</MenuItem>
                    <MenuItem value={"GOOD"}>GOOD</MenuItem>
                    <MenuItem value={"FLUENT"}>FLUENT</MenuItem>
                  </Select>
                </Stack>
              </Grid>

              <Grid item xs={12} width={"150px"} marginRight={"20px"}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="personal-writing_level">
                    Writing
                  </InputLabel>
                  <Select
                    id="writing_level"
                    value={selectedWriting} // Set the selected ID as the value of the Select component
                    onChange={(event) => setSelectedWriting(event.target.value)} // Handle the change event
                  >
                    <MenuItem value={"POOR"}>POOR</MenuItem>
                    <MenuItem value={"GOOD"}>GOOD</MenuItem>
                    <MenuItem value={"FLUENT"}>FLUENT</MenuItem>
                  </Select>
                </Stack>
              </Grid>
            </Stack>
          </Grid>
          <Stack display={"flex"} alignItems={"end"} marginTop={"20px"}>
            <Button type="submit" variant="contained" onClick={handleSave}>
              Edit Language
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default EditLanguageDialog;
