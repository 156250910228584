const GET_ALL_COMPANY_SUGESSTED = "suggested_companies";

const GET_ONE_COMPANY_SUGESSTED = "suggested_companies/";

const EDIT_COMPANY_SUGESSTED = "suggested_companies/";

const ADD_COPMANY_SUGESSTED = "suggested_companies";

const DELETE_COMPANY_SUGESSTED = "suggested_companies/";

const MULTI_DELETE_COMPANY_SUGESSTED = "suggested_companies/remove/bulk_delete";

export default {
  GET_ALL_COMPANY_SUGESSTED,
  GET_ONE_COMPANY_SUGESSTED,
  EDIT_COMPANY_SUGESSTED,
  ADD_COPMANY_SUGESSTED,
  DELETE_COMPANY_SUGESSTED,
  MULTI_DELETE_COMPANY_SUGESSTED,
};
