const GET_VACANCIES_SUB = "vacancy_subscriptions";

const ADD_VACANCIES_SUB = "vacancy_subscriptions";

const EDIT_VACANCIES_SUB = "vacancy_subscriptions/";

const GET_ONE_VACANCIES_SUB = "vacancy_subscriptions/";

const DELETE_VACANCIES_SUB = "vacancy_subscriptions/";

const FILTER_VACANCY_SUB = "vacancy_subscriptions/vacancy/filter";

const MULTI_DELET = "vacancy_subscriptions/remove/bulk_delete";

export default {
  GET_VACANCIES_SUB,

  ADD_VACANCIES_SUB,

  EDIT_VACANCIES_SUB,

  GET_ONE_VACANCIES_SUB,

  FILTER_VACANCY_SUB,

  DELETE_VACANCIES_SUB,

  MULTI_DELET,
};
