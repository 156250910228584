import { DashboardOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
const dashboardManagmentItems = () => {
  const icons = {
    DashboardOutlined,
  };

  const dashboardItems = {
    id: "group-dashboard",
    title: (
      <FormattedMessage id="dashboard">
        {(text) => (
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>{text}</span>
        )}
      </FormattedMessage>
    ),
    type: "group",
    children: [
      {
        id: "dashboard",
        title: <FormattedMessage id="dashboard" />,
        type: "item",
        url: "/dashboard",
        icon: icons.DashboardOutlined,
        breadcrumbs: false,
      },
    ],
  };

  return dashboardItems;
};

export default dashboardManagmentItems;
