const GET_CATEGORIES = "categories";

const CATEGORIES = "categories/";

const MULTI_DELET = "categories/remove/bulk_delete";

const SEARCH_CATEGORIES = "categories/category/search/";

const GET_PARENT_CAT = "categories/category/parents";

const CHILD_CAT = "categories/category/childs/";

export default {
  GET_CATEGORIES,

  MULTI_DELET,

  SEARCH_CATEGORIES,

  CATEGORIES,

  GET_PARENT_CAT,

  CHILD_CAT,
};
