const GET_BEST_PALCE = "best_places";
const ADD_BEST_PALCE = "best_places";
const GET_ONE_BEST_PALCE = "best_places/";

const EDIT_BEST_PALCE = "best_places/";

const DELETE_BEST_PALCE = "best_places/";

const ACIVATE_BEST_PALCE = "best_places/activate/";

const DEACTIVATE_BEST_PALCE = "best_places/deactivate/";

const MULTI_DELET_BEST_PALCE = "best_places/remove/bulk_delete";

export default {
  GET_BEST_PALCE,
  ADD_BEST_PALCE,
  GET_ONE_BEST_PALCE,

  EDIT_BEST_PALCE,

  ACIVATE_BEST_PALCE,

  DEACTIVATE_BEST_PALCE,

  MULTI_DELET_BEST_PALCE,
  DELETE_BEST_PALCE,
};
