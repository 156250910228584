import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import companyreplyURL from "../../../../utils/url/companyList/companyReply";

//get AllUser
export const getAllCompanyReply = createAsyncThunk(
  "companyList/getAllCompanyReply",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${companyreplyURL.GET_ALL_COMPANY_REPLY_APP}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneCompanyReply = createAsyncThunk(
  "companyList/getOneCompanyReply",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${companyreplyURL.GET_ONE_COMPANY_REPLY_APP}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editCompanyReply = createAsyncThunk(
  "companyList/editCompanyReply",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${companyreplyURL.EDIT_COMPANY_REPLY_APP}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addCompanyReply = createAsyncThunk(
  "companyList/addCompanyReply",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        companyreplyURL.ADD_COPMANY_REPLY_APP,
        data,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteCompanyReply = createAsyncThunk(
  "companyList/deleteCompanyReply",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${companyreplyURL.DELETE_COMPANY_REPLY_APP}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletCompanyReply = createAsyncThunk(
  "companyList/MultiDeletCompanyReply",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        companyreplyURL.MULTI_DELETE_COMPANY_REPLY_APP,
        {
          data: { ids },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
