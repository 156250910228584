import { createAsyncThunk } from "@reduxjs/toolkit";
import companyProfileUrl from "../../../utils/url/companyProfile";

//error message
import responseError from "../../../utils/api/error-handling";
import { USERAXIOS } from "api";

//get AllUser
export const getAllCompanyProfile = createAsyncThunk(
  "companyProfile/getAllCompanyProfile",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${companyProfileUrl.GET_COMPANY_PROFILE}?page=${pageNumber}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const getNOCLIENTProfile = createAsyncThunk(
  "companyProfile/getNOCLIENTProfile",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${companyProfileUrl.GET_NO_CLIENT}?page=${pageNumber}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const getYESCLIENTProfile = createAsyncThunk(
  "companyProfile/getYESCLIENTProfile",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${companyProfileUrl.GET_YES_CLIENT}?page=${pageNumber}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const getFREECLIENTProfile = createAsyncThunk(
  "companyProfile/getFREECLIENTProfile",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${companyProfileUrl.GET_FREE_CLIENT}?page=${pageNumber}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const getUndefinedCLIENTProfile = createAsyncThunk(
  "companyProfile/getUndefinedCLIENTProfile",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${companyProfileUrl.GET_UNDEFINED_CLIENT}?page=${pageNumber}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneCompanyProfile = createAsyncThunk(
  "companyProfile/getOneCompanyProfile",
  async ({ companyId }: { companyId: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${companyProfileUrl.GET_ONE_COMPANY_PROFILE}${companyId}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editCompanyProfile = createAsyncThunk(
  "companyProfile/editCompanyProfile",
  async ({ companyId, data }: { companyId: any; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${companyProfileUrl.EDIT_COMPANY_PROFILE}${companyId}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addNewCompanyProfile = createAsyncThunk(
  "companyProfile/addNewCompanyProfile",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        companyProfileUrl.ADD_COMPANY_PROFILE,
        data,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteCompanyProfile = createAsyncThunk(
  "companyProfile/deleteCompanyProfile",
  async ({ companyId }: { companyId: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${companyProfileUrl.DELETE_COMPANIES}${companyId}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletCompanyProfile = createAsyncThunk(
  "companyProfile/MultiDeletCompanyProfile",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        companyProfileUrl.MULTI_DELET_PROFILE,
        {
          data: { ids },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
