import { CardHeader, Button, Stack, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { Language } from "model/cv/language/language";
import { TableSkill } from "./TableSkill";
import SkillsDialog from "./Skill";
import { EyeOutlined } from "@ant-design/icons";

const SkillSection = ({
  cvId,
  basicInfo,
  openSkills,
  setOpenSkills,
  setLoading,
  loading,
  fetchGetOneSkill,
}: {
  setLoading: any;
  loading: any;
  fetchGetOneSkill: any;
  cvId: any;
  basicInfo: any;

  openSkills: any;
  setOpenSkills: (open: boolean) => void;
}) => {
  const [showTable, setShowTable] = useState(false);
  const [rows, setRows] = useState<Language[]>([
    {
      resume_id: 0,
      language_id: 0,
      reading_level: "",
      conversation_level: "",
      writing_level: "",
      // Assuming it can be null and it's a string (URL?)
    },
  ]);

  return (
    <>
      <Stack
        direction={"row"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "17px",
        }}
      >
        <Stack direction={"column"}>
          <CardHeader title="Skills" />

          <Stack style={{ paddingLeft: "10px" }}>
            {/* Pass educationCertificates as prop to TableEducation */}
            <TableSkill
              cvId={cvId}
              skillData={basicInfo}
              setLoading={setLoading}
              loading={loading}
              fetchGetOneSkill={fetchGetOneSkill}
            />
          </Stack>
        </Stack>

        {/* Other education-related fields */}
      </Stack>
      <div
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
          marginTop: rows.length > 0 ? "5%" : "0",
          marginRight: "17px",
          marginBottom: "10px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenSkills(true)}
        >
          Add Skill
        </Button>
        {openSkills && (
          <SkillsDialog
            openSkills={openSkills}
            setOpenSkills={setOpenSkills}
            cvId={cvId}
            setLoading={setLoading}
            loading={loading}
            fetchGetOneSkill={fetchGetOneSkill}
          />
        )}
      </div>
    </>
  );
};

export default SkillSection;
