import { CardHeader, Button, Stack, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { TableExperience } from "./TableExperience";

import { useEffect, useState } from "react";

import { useAppDispatch } from "hooks";

import { getAllExperience } from "store/actions/main/cv/experience/experience";
import ExperienceDialog from "./Experience";
import { WorkExperience } from "model/cv/workExperience/workExperience";
import { EyeOutlined } from "@ant-design/icons";

const ExperienceSection = ({
  openExp,
  setOpenExp,
  cvId,
  basicInfo,
  setLoading,
  loading,
  fetchGetOneExp,
}: {
  fetchGetOneExp: any;
  basicInfo: any;
  setLoading: any;
  loading: any;
  openExp: any;
  setOpenExp: (openExp: boolean) => void;
  cvId: any;
}) => {
  const [rows, setRows] = useState<WorkExperience[]>([
    {
      resume_id: 0,
      job_type_id: 0,
      category_id: 0,
      job_title: "",
      work_hours_weekly: 0,
      company_name: "",
      country: "",
      city: "",
      salary: 0,
      confidential: false,
      currency: "",
      started_at: "",
      ended_at: "",
      still_working: false,
      job_responsibilities: "",
      benefits: "",
      company_description: "", // Assuming it can be null and it's a string (URL?)
    },
  ]);

  return (
    <>
      <Stack
        direction={"row"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "17px",
        }}
      >
        <Stack direction={"column"}>
          <CardHeader title="Experiences" />

          <Stack style={{ paddingLeft: "10px" }}>
            {/* Pass educationCertificates as prop to TableEducation */}
            <TableExperience
              cvId={cvId}
              educationCertificates={basicInfo}
              setLoading={setLoading}
              loading={loading}
              fetchGetOneExp={fetchGetOneExp}
            />
          </Stack>
        </Stack>
      </Stack>
      <div
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
          marginTop: rows.length > 0 ? "5%" : "0",
          marginRight: "17px",
          marginBottom: "10px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenExp(true)}
        >
          Add Work Experience
        </Button>
        {openExp && (
          <ExperienceDialog
            setLoading={setLoading}
            loading={loading}
            openExp={openExp}
            setOpenExp={setOpenExp}
            cvId={cvId}
            fetchGetOneExp={fetchGetOneExp}
          />
        )}
      </div>
    </>
  );
};

export default ExperienceSection;
