import { useEffect, useState, ChangeEvent } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Autocomplete,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// project imports
import IconButton from "components/@extended/IconButton";
import CircularWithPath from "components/@extended/progress/CircularWithPath";
import { openSnackbar } from "api/snackbar";
// assets
import { DeleteFilled } from "@ant-design/icons";
// types
import { SnackbarProps } from "types/snackbar";
import { useAppDispatch } from "hooks";
import AlertCountryDelete from "./AlertCompanyProfileDelete";
import { UserList } from "types/customer";
import { addNewUser, editUser } from "store/actions/main/users";
import phoneNumber from "hooks/data/phoneNumber";

// constant
const getInitialValues = (customer: UserList | null) => {
  const newCustomer = {
    user_name: "",
    email: "",
    confirmPassword: "",
    countryCode: "",
    mobile_number: "",
    account_status: "inactive",
    account_language: "AR",
    account_type: "JOBSEEKER",
    password: "",
  };
  if (customer) {
    // Extract country code and mobile number parts from mobile number if present
    const mobileNumberParts = customer.mobile_number
      ? customer.mobile_number.split(" - ")
      : ["", ""];
    return {
      ...newCustomer,
      ...customer,
      countryCode: mobileNumberParts[0] || "", // Set countryCode to mobileNumberParts[0] if present, otherwise set it to an empty string
      mobile_number: mobileNumberParts[1] || "", // Set mobile_number to mobileNumberParts[1] if present, otherwise set it to an empty string
    };
  }

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormResumeAdd = ({
  customer,
  id,
  closeModal,
  fetchData,
}: {
  fetchData: any;
  id: number;
  customer: UserList | null;
  closeModal: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const CustomerSchema = Yup.object().shape({
    user_name: Yup.string().required("User Name is required"),
    email: Yup.string().required("Email is required"),
    account_language: Yup.string().required("Status is required"),
    mobile_number: Yup.number().required("Mobile Number is required"),
    account_status: Yup.string().required("Status is required"),
    countryCode: Yup.number().required("Country Code is required"),
    password: customer
      ? Yup.string()
      : Yup.string()
          .required("Password is required")
          .min(6, "Password must be at least 6 characters"),
    confirmPassword: customer
      ? Yup.string()
      : Yup.string()
          .oneOf([Yup.ref("password"), ""], "Passwords must match")
          .required("Confirm Password is required"),
  });
  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const zipCodePhoneNumber = `${values.countryCode} - ${values.mobile_number}`;

        // Set the combined value as the mobile_number field in your form data
        values.mobile_number = zipCodePhoneNumber;

        const { password, ...dataWithoutPassword } = values;

        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editUser({
              userId: customer.id || 0,
              data: password ? values : dataWithoutPassword,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "user/editUser/fulfilled") {
              openSnackbar({
                open: true,
                message: "Company deleted successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addNewUser(values)).then((action) => {
            fetchData();
            if (action.type === "user/addUser/fulfilled") {
              openSnackbar({
                open: true,
                message: "User edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
          openSnackbar({
            open: true,
            message: "User added successfully.",
            variant: "alert",
            alert: {
              color: "success",
            },
          } as SnackbarProps);
          fetchData();
          setSubmitting(false);
          closeModal();
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;
  const { value, onChange, onBlur } = getFieldProps("");
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>{customer ? "Edit User" : "New User"}</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-user_name">
                          JobSeeker Name
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-user_name"
                          placeholder="Enter User Name"
                          {...getFieldProps("user_name")}
                          error={Boolean(touched.user_name && errors.user_name)}
                          helperText={touched.user_name && errors.user_name}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-email">email</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-email"
                          placeholder="Enter Email"
                          {...getFieldProps("email")}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel htmlFor="customer-name">Mobile</InputLabel>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <Autocomplete
                          style={{ width: "200px" }}
                          id="personal-country-code"
                          options={phoneNumber}
                          value={phoneNumber.find(
                            (item) => item.dialCode === value.countryCode,
                          )}
                          onChange={(event, newValue) => {
                            setFieldValue(
                              "countryCode",
                              newValue === null ? "" : newValue.dialCode,
                            );
                          }}
                          autoHighlight
                          getOptionLabel={(option) => option.dialCode}
                          renderOption={(props, option) => (
                            <MenuItem
                              component="li"
                              sx={{ typography: "body2" }}
                              {...props}
                            >
                              ({option.dialCode})
                            </MenuItem>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="countryCode"
                              placeholder=""
                              error={Boolean(
                                touched.countryCode && errors.countryCode,
                              )}
                              helperText={
                                touched.countryCode && errors.countryCode
                              }
                            />
                          )}
                        />

                        <TextField
                          fullWidth
                          id="customer-mobile_number"
                          placeholder="999 999 999"
                          {...getFieldProps("mobile_number")}
                          error={Boolean(
                            touched.mobile_number && errors.mobile_number,
                          )}
                          helperText={
                            touched.mobile_number && errors.mobile_number
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-status">
                          Status
                        </InputLabel>
                        <Select
                          id="customer-account_status"
                          displayEmpty
                          {...getFieldProps("account_status")}
                          onChange={(event: SelectChangeEvent<boolean>) => {
                            // Convert true to '1' and false to '0'
                            const selectedValue = event.target.value;

                            // Set the field value to the converted value
                            setFieldValue("account_status", selectedValue);
                          }}
                          // rest of the code
                        >
                          <MenuItem value={"inactive"}>Inactive</MenuItem>
                          <MenuItem value={"active"}>Active</MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-password">
                          Password
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-password"
                          type="password"
                          placeholder="Enter Password"
                          {...getFieldProps("password")}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-confirm-password">
                          Confirm Password
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-confirm-password"
                          type="password"
                          placeholder="Confirm Password"
                          {...getFieldProps("confirmPassword")}
                          error={Boolean(
                            touched.confirmPassword && errors.confirmPassword,
                          )}
                          helperText={
                            touched.confirmPassword && errors.confirmPassword
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-account_language">
                          Language
                        </InputLabel>
                        <Select
                          id="customer-account_language"
                          displayEmpty
                          {...getFieldProps("account_language")}
                          onChange={(event: SelectChangeEvent<boolean>) => {
                            // Convert true to '1' and false to '0'
                            const selectedValue = event.target.value;

                            // Set the field value to the converted value
                            setFieldValue("account_language", selectedValue);
                          }}
                          // rest of the code
                        >
                          <MenuItem value={"EN"}>EN</MenuItem>
                          <MenuItem value={"AR"}>AR</MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-account_type">
                          Type
                        </InputLabel>
                        <Select
                          id="customer-account_type"
                          displayEmpty
                          {...getFieldProps("account_type")}
                          onChange={(event: SelectChangeEvent<boolean>) => {
                            // Convert true to '1' and false to '0'
                            const selectedValue = event.target.value;

                            // Set the field value to the converted value
                            setFieldValue("account_type", selectedValue);
                          }}
                          readOnly
                          // rest of the code
                        >
                          <MenuItem value={"JOBSEEKER"}>JOBSEEKER</MenuItem>
                          <MenuItem value={"COMPANY"}>COMPANY</MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                    {customer ? (
                      <Grid item xs={12} sm={12}>
                        <Stack spacing={1}>
                          <Typography color={"red"}>
                            *if you don't update the password the old one will
                            not be changed*
                          </Typography>
                        </Stack>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton
                        onClick={() => setOpenAlert(true)}
                        size="large"
                        color="error"
                      >
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? "Edit" : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {customer && (
        <AlertCountryDelete
          fetchData={fetchData}
          id={customer.id!}
          title={customer.user_name}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default FormResumeAdd;
