import { DashboardOutlined } from "@ant-design/icons";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import { FormattedMessage } from "react-intl";
const userManagmentItems = () => {
  const icons = {
    DashboardOutlined,
    PeopleOutlinedIcon,
    BusinessOutlinedIcon,
    GroupsOutlinedIcon,
  };

  const userItems = {
    id: "group-dashboard",
    title: (
      <FormattedMessage id="user-management">
        {(text) => (
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>{text}</span>
        )}
      </FormattedMessage>
    ),
    type: "group",
    children: [
      {
        id: "companies",
        title: <FormattedMessage id="companies" />,
        type: "item",
        url: "/companies",
        icon: icons.BusinessOutlinedIcon,
        breadcrumbs: false,
      },
      {
        id: "roles",
        title: <FormattedMessage id="roles" />,
        type: "item",
        url: "/roles",
        icon: icons.GroupsOutlinedIcon,
        breadcrumbs: false,
      },
      {
        id: "users",
        title: <FormattedMessage id="users" />,
        type: "item",
        url: "/users",
        icon: icons.PeopleOutlinedIcon,
        breadcrumbs: false,
      },
    ],
  };

  return userItems;
};

export default userManagmentItems;
