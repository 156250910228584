import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PackagesVacancies, PackagesVacanciesState } from "model/packages";
import {
  MultiDeletdeleteVacancies,
  addNewVacancy,
  deleteVacancy,
  editOneVacancy,
  getAllVacancies,
  getOneVacancy,
} from "store/actions/main/packages/vacanciesPackages";

const initialState: PackagesVacanciesState = {
  result: [],
  isOk: false,
  message: "",
  confirmCode: "",
};

const VacancyPackageSlice = createSlice({
  name: "packages",
  initialState: initialState,
  reducers: {
    resetStateStatus: (state) => {
      state.isOk = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllVacancies.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getAllVacancies.fulfilled.type,
        (state, action: PayloadAction<PackagesVacancies[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewVacancy.fulfilled.type,
        (state, action: PayloadAction<PackagesVacancies[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewVacancy.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getOneVacancy.fulfilled.type,
        (state, action: PayloadAction<PackagesVacancies[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        getOneVacancy.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editOneVacancy.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editOneVacancy.fulfilled.type,
        (state, action: PayloadAction<PackagesVacancies[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        deleteVacancy.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        deleteVacancy.fulfilled.type,
        (state, action: PayloadAction<PackagesVacancies[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        MultiDeletdeleteVacancies.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        MultiDeletdeleteVacancies.fulfilled.type,
        (state, action: PayloadAction<PackagesVacancies[]>) => {
          state.result = action.payload;
        },
      );
  },
});

export const { resetStateStatus } = VacancyPackageSlice.actions;

export default VacancyPackageSlice;
