const GET_RESUMES_PACKAGES = "resume_packages";

const ADD_RESUMES_PACKAGES = "resume_packages";

const EDIT_RESUMES_PACKAGES = "resume_packages/";

const GET_ONE_RESUMES_PACKAGES = "resume_packages/";

const DELETE_RESUMES_PACKAGES = "resume_packages/";

const MULTI_DELET = "resume_packages/remove/bulk_delete";

export default {
  GET_RESUMES_PACKAGES,

  ADD_RESUMES_PACKAGES,

  EDIT_RESUMES_PACKAGES,

  GET_ONE_RESUMES_PACKAGES,

  DELETE_RESUMES_PACKAGES,

  MULTI_DELET,
};
