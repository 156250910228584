const GET_QUESTIONS = "questions";

const ADD_QUESTIONS = "questions";

const GET_ONE_QUESTIONS = "questions/";

const EDIT_QUESTIONS = "questions/";

const DELETE_QUESTIONS = "questions/";

const MULTI_DELET_QUESTIONS = "questions/remove/bulk_delete";

export default {
  GET_QUESTIONS,
  ADD_QUESTIONS,
  GET_ONE_QUESTIONS,
  EDIT_QUESTIONS,
  DELETE_QUESTIONS,

  MULTI_DELET_QUESTIONS,
};
