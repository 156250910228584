const GET_ANSWER = "resume/answers";

const ADD_ANSWER = "resume/answers";

const GET_ONE_GET_ANSWER = "resume/answers/";

const EDIT_ANSWER = "resume/answers/";

const DELETE_GET_ANSWER = "resume/answers/";

const MULTI_DELET_GET_ANSWER = "resume/answers/remove/bulk_delete";

export default {
  GET_ANSWER,
  ADD_ANSWER,
  GET_ONE_GET_ANSWER,
  EDIT_ANSWER,
  DELETE_GET_ANSWER,

  MULTI_DELET_GET_ANSWER,
};
