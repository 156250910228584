import { useEffect, useState } from "react";
import dayjs from "dayjs";
// material-ui
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { countriesAndCities } from "../../../../hooks/data/country";
import { useAppDispatch } from "hooks";

import {
  editExperience,
  getOneExperience,
} from "store/actions/main/cv/experience/experience";
import TextArea from "antd/es/input/TextArea";
import { getAllJobType } from "store/actions/main/basicInfo/jobTypes";
import { getAllCategories } from "store/actions/main/categories";
import {
  GetChildCountries,
  ParentCountries,
} from "store/actions/main/countries";
import { openSnackbar } from "api/snackbar";
import { SnackbarProps } from "types/snackbar";

// types

function EditExperienceDialog({
  open,
  cvId,
  setLoading,
  loading,
  resumeId,
  setOpen,
  experienceData,
  setExperienceData,
  fetchGetOneExp,
}: {
  fetchGetOneExp: any;
  setLoading: any;
  loading: any;
  resumeId;
  cvId: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  experienceData: any;
  setExperienceData: any;
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const [selectedCountry, setSelectedCountry] = useState("");

  const dispatch = useAppDispatch();
  const fetchGetOne = () => {
    dispatch(getOneExperience({ id: cvId })).then((action) => {
      const data = action.payload["result: "];
      setExperienceData(data);
      setSelectedJobTypeId(experienceData?.job_type_id || "");
      setSelectedCategoryId(experienceData?.category_id || "");
      setSelectedCountry(experienceData?.country || "");
      setSelectedCity(experienceData?.city || "");
      setStillWorking(experienceData?.still_working || "");
      setConfidential(experienceData?.confidential || "");
    });
  };
  const handleDateChange = (fieldName: string, date: dayjs.Dayjs | null) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    setExperienceData((prevEducationData: any) => ({
      ...prevEducationData,
      [fieldName]: formattedDate,
    }));
  };
  const [selectedJobTypeId, setSelectedJobTypeId] = useState(""); // State to store the selected ID
  const [selectedCurrence, setSelectedCurrency] = useState("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState([]);
  const [confidential, setConfidential] = useState(false);
  const [stillWorking, setStillWorking] = useState(false);

  const [selectedCity, setSelectedCity] = useState("");

  const handleJobTypeChange = (event) => {
    const selectedId = event.target.value; // Get the selected ID
    setSelectedJobTypeId(selectedId); // Set the selected ID to state
    const selectedItem = selectedJobType.find((item) => item.id === selectedId);
    if (selectedItem) {
      const selectedName = selectedItem.name;
    }
  };
  console.log(cvId);
  const handleCategoryChange = (event) => {
    const selectedId = event.target.value; // Get the selected ID
    setSelectedCategoryId(selectedId); // Set the selected ID to state
    const selectedItem = selectedCategory.find(
      (item) => item.id === selectedId,
    );
    if (selectedItem) {
      const selectedName = selectedItem.name;
    }
  };
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [country, setCountry] = useState();

  const [childCities, setChildCities] = useState([]);
  const [selectedCityName, setSelectedCityName] = useState("");
  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value; // Get the selected country ID
    setSelectedCountry(selectedCountryId); // Update the selected country ID state

    // Find the selected country object
    const selectedCountryObject = country.find(
      (country) => country.id === selectedCountryId,
    );

    if (selectedCountryObject) {
      setSelectedCountryName(selectedCountryObject.name); // Set the selected country name

      // Set the selected city to "All Cities" if the selected country is not the first item in the list
      if (selectedCountryId == "1") {
        dispatch(GetChildCountries({ id: 1 })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        }); // Disable city selection

        setSelectedCity("");
      } else {
        dispatch(GetChildCountries({ id: 2 })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        });
        setSelectedCity("");
      }
    }
  };

  const fetchDataPackages = () => {
    dispatch(ParentCountries())
      .then((action) => {
        const { data } = action.payload;
        // Set user names as options for autocomplete
        setCountry(data);
        // Set default selected country to the first one in the list
        if (data.length > 0) {
          setSelectedCountry(data[0].id);
          setSelectedCountryName(data[0].name);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = childCities.find((city) => city.id === selectedCityId);
    setSelectedCity(selectedCityId);
    // Set the selected city name
    setSelectedCityName(selectedCity?.name || "");
  };
  useEffect(() => {
    fetchDataPackages();
    dispatch(GetChildCountries({ id: 1 })).then((action) => {
      const { data } = action.payload;
      setChildCities(data);
      if (data.length > 0) {
        setSelectedCity(data[0].id);
        setSelectedCityName(data[0].name);
      }
      // Set fetched child cities to state
    });
  }, [dispatch]);
  const fetchCategoryData = () => {
    dispatch(getAllCategories({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setSelectedCategory(data);
        setLoading(false); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setLoading(false);
      });
  };

  const fetchJobTypeData = () => {
    dispatch(getAllJobType({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setSelectedJobType(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };

  useEffect(() => {
    fetchGetOne();
    fetchDataPackages();
    fetchCategoryData();
    fetchJobTypeData();
  }, [dispatch]);
  const handleConfidentialChange = (event) => {
    setConfidential(event.target.checked); // Set the value of confidentiality based on checkbox state
  };
  const handleStillWorkingChange = (event) => {
    setStillWorking(event.target.checked); // Set the value of confidentiality based on checkbox state
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExperienceData((prevFormData: any) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSave = async () => {
    // Log average value for debugging
    setLoading(true);
    dispatch(
      editExperience({
        id: cvId,
        data: {
          resume_id: resumeId,
          job_type_id: selectedJobTypeId,
          category_id: selectedCategoryId,
          country: selectedCountryName,
          city: selectedCityName,
          job_title: experienceData.job_title,
          company_name: experienceData.company_name,
          salary: experienceData.salary,
          started_at: experienceData.started_at,
          ended_at: experienceData.ended_at,
          still_working: stillWorking,
          job_responsibilties: experienceData.job_responsibilties,
          benefites: experienceData.benefites,
          confidetial: confidential,
          currency: selectedCurrence,
          company_description: experienceData.company_description,
        },
      }),
    ).then((action) => {
      if (action.type === "experience/editExperience/fulfilled") {
        openSnackbar({
          open: true,
          message: "Experience Updated successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);
        setOpen(false);
        setLoading(false);
        fetchGetOneExp();
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
        setLoading(false);
      }
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <DialogTitle style={{ fontWeight: "bold" }}>Experience</DialogTitle>
        <CloseIcon
          style={{
            marginTop: "12px",
            marginRight: "20px",
            width: "20px",
            height: "20px",
            cursor: "pointer",
          }}
          onClick={() => setOpen(false)}
        />
      </Stack>

      <DialogContent>
        <Box sx={{ p: 2.5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="job_title">Job Title</InputLabel>
                <TextField
                  fullWidth
                  value={experienceData?.job_title}
                  name="job_title"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  id="job_title"
                  placeholder="job Title"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="category_id">Field of work</InputLabel>
                <Select
                  id="category_id"
                  value={selectedCategoryId} // Set the selected ID as the value of the Select component
                  onChange={handleCategoryChange} // Handle the change event
                >
                  {selectedCategory.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="job_type_id">Job Type</InputLabel>
                <Select
                  id="job_type_id"
                  value={selectedJobTypeId} // Set the selected ID as the value of the Select component
                  onChange={handleJobTypeChange} // Handle the change event
                >
                  {selectedJobType.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="work_hours_weekly">
                  Work Hours Weekly
                </InputLabel>
                <TextField
                  fullWidth
                  // type="number"
                  id="work_hours_weekly"
                  value={experienceData?.work_hours_weekly}
                  name="work_hours_weekly"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Work Hours Weekly"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <Stack direction={"row"} display={"flex"}>
                  <InputLabel htmlFor="country">Country</InputLabel>
                  <InputLabel style={{ marginLeft: "130px" }} htmlFor="city">
                    City
                  </InputLabel>
                </Stack>
                <Stack direction={"row"}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    // alignItems="center"
                    spacing={2}
                  >
                    <FormControl fullWidth>
                      <Select
                        style={{ width: "140px" }}
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        //  onBlur={handleBlur}
                        inputProps={{
                          name: "country",
                          id: "country",
                        }}
                      >
                        {country &&
                          country.map((country: any) => (
                            <MenuItem key={country.id} value={country.id}>
                              {country.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <FormControl fullWidth>
                      <Select
                        style={{ marginLeft: "20px", width: "140px" }}
                        labelId="city-label"
                        id="city"
                        value={selectedCity}
                        onChange={handleCityChange}
                        // onBlur={handleBlur}
                        name="city"
                      >
                        {childCities.map((city) => (
                          <MenuItem key={city.id} value={city.id}>
                            {city.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="company_name">Company Name</InputLabel>
                <TextField
                  fullWidth
                  // type="number"
                  id="company_name"
                  value={experienceData?.company_name}
                  name="company_name"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Company Name"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction={"row"}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="salary">Net Salary</InputLabel>
                  <TextField
                    fullWidth
                    // type="number"
                    id="salary"
                    value={experienceData?.salary}
                    name="salary"
                    //onBlur={handleBlur}
                    onChange={handleInputChange}
                    placeholder="Salary"
                  />
                </Stack>
                <Stack
                  spacing={1}
                  display={"flex"}
                  alignItems={"end"}
                  justifyContent={"end"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confidential}
                        onChange={handleConfidentialChange}
                        id="confidential-checkbox"
                      />
                    }
                    label="Confidential"
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="currency">Currency</InputLabel>
                <Select
                  id="currency"
                  value={selectedCurrence} // Set the selected ID as the value of the Select component
                  onChange={(event) => setSelectedCurrency(event.target.value)} // Handle the change event
                >
                  <MenuItem value={"DOLLAR"}>DOLLAR</MenuItem>
                  <MenuItem value={"SYP"}>SYP</MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="started_at">Starte Date</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={
                      experienceData?.started_at
                        ? dayjs(experienceData.started_at)
                        : null
                    }
                    onChange={(date) => handleDateChange("started_at", date)}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction={"row"}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="ended_at">End Date</InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={
                        experienceData?.ended_at
                          ? dayjs(experienceData.ended_at)
                          : null
                      }
                      disabled={stillWorking}
                      onChange={(date) => handleDateChange("ended_at", date)}
                    />
                  </LocalizationProvider>
                </Stack>
                <Stack
                  spacing={1}
                  display={"flex"}
                  alignItems={"end"}
                  justifyContent={"end"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={stillWorking}
                        onChange={handleStillWorkingChange}
                        id="stillWorking-checkbox"
                      />
                    }
                    label="stillWorking"
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="job_responsibilties">
                  Responsibilties
                </InputLabel>
                <TextArea
                  id="job_responsibilties"
                  value={experienceData?.job_responsibilties}
                  name="job_responsibilties"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Job Responsibilties"
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="benefites">Benefites</InputLabel>
                <TextArea
                  id="benefites"
                  value={experienceData?.benefites}
                  name="benefites"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Features"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="company_description">
                  Company Description
                </InputLabel>
                <TextArea
                  id="company_description"
                  value={experienceData?.company_description || ""}
                  name="company_description"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Company Description"
                />
              </Stack>
            </Grid>
          </Grid>
          <Stack display={"flex"} alignItems={"end"} marginTop={"20px"}>
            <Button type="submit" variant="contained" onClick={handleSave}>
              Edit Experience
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default EditExperienceDialog;
