import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import experienceURL from "../../../../../utils/url/cv/workExperience/workExperienc";
//axios

//get AllBasicInfo
export const getAllExperience = createAsyncThunk(
  "experience/getAllExperience",
  async (_, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(experienceURL.GET_ALL_EXPERIENCE);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//get One basicInfo

export const getOneExperience = createAsyncThunk(
  "experience/getOneExperience",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${experienceURL.GET_ONE_EXPERIENCE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//Edit One Category

export const editExperience = createAsyncThunk(
  "experience/editExperience",
  async ({ id, data }: { id: number; data: any }, thunkAPI) => {
    // Adjusted payload creator function
    // Destructure categoryId and data from payload
    try {
      const response = await USERAXIOS.post(
        `${experienceURL.EDIT_EXPERIENCE}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//add New basicIno
export const addNewExperience = createAsyncThunk(
  "experience/addNewExperience",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(experienceURL.ADD_EXPERIENCE, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//delete basic Info

export const deleteExperience = createAsyncThunk(
  "experience/deleteExperience",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${experienceURL.DELETE_EXPERIENCE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
