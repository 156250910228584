const GET_JOB_TYPE = "job_types";

const ADD_JOB___TYPE = "job_types";

const GET_ONE_JOB_TYPE = "job_types/";

const EDIT_JOB__TYPE = "job_types/";

const DELETE_JOB__TYPE = "job_types/";

const MULTI_DELET_JOB___TYPE = "job_types/remove/bulk_delete";

export default {
  GET_JOB_TYPE,
  ADD_JOB___TYPE,
  GET_ONE_JOB_TYPE,
  EDIT_JOB__TYPE,
  DELETE_JOB__TYPE,

  MULTI_DELET_JOB___TYPE,
};
