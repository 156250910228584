import { useEffect, useState, ChangeEvent } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Autocomplete,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project imports
import IconButton from "components/@extended/IconButton";
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets
import { DeleteFilled } from "@ant-design/icons";

// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";

import {
  ParentCountries,
  addNewCountry,
  editCountry,
} from "store/actions/main/countries";
import { ParentCountry } from "model/country";
import AlertCountryDelete from "./AlertBannerDelete";

import { addNewUser, editUser } from "store/actions/main/users";
import phoneNumber from "hooks/data/phoneNumber";
import { banners } from "model/ads";
import { getAllCompanies } from "store/actions/main/companies";
import { getAllVacanciesOrder } from "store/actions/main/order/vacancyOrder";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { addBanners, editBanner } from "store/actions/main/ads/banners";
import { values } from "lodash";
import { BasicInfo } from "model/basicInfo";
import { getAllBannerPlace } from "store/actions/main/basicInfo/bannerPlace";

// constant
const getInitialValues = (customer: banners | null) => {
  const newCustomer = {
    user_id: "",
    vacancy_order_id: "",
    title: "",
    status: false,
    banner_place: "",
    order: "",
    end_date: "",
    start_date: "",
  };

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormBannerAdd = ({
  customer,
  id,
  closeModal,
  Packages,
  userName,
  loading,
  fetchData,
  setLoading,
}: {
  fetchData: any;
  Packages: any;
  userName: any;
  loading: any;
  setLoading: any;
  id: number;
  customer: banners | null;
  closeModal: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const CustomerSchema = Yup.object().shape({
    user_id: Yup.string().required("Company Name is required"),
    vacancy_order_id: Yup.number().required("Vacancy Order Id is required"),
    title: Yup.string().required("Title is required"),
    order: Yup.string().required("Order is required"),
  });

  const [openAlert, setOpenAlert] = useState(false);
  const [startDate, setStartDate] = useState(null);

  const [bannerPlace, setBannerPlace] = useState<BasicInfo[]>([]);
  const [selectedBannerPlace, setSelectedBAnnerPlace] =
    useState<BasicInfo | null>(null);

  const fetchBannerData = (url?: string) => {
    dispatch(getAllBannerPlace({ pageNumber: 1 }))
      .then((action) => {
        const { data, links, meta } = action.payload;
        const fetchedUserData: BasicInfo[] = data;
        const rowsWithKeys = fetchedUserData.map((user) => ({
          ...user,
          key: user.id,
        }));
        setLoading(false);
        setBannerPlace(rowsWithKeys);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  useEffect(() => {
    fetchBannerData();
  }, [dispatch]);
  useEffect(() => {
    setLoading(false);

    // Initialize selectedParentCountryId and selectedParentCountry if customer exists
    if (customer && customer.banner_place) {
      const selectedCat = bannerPlace.find(
        (country) => country.name === customer.banner_place,
      );
      if (selectedCat) {
        setSelectedBAnnerPlace(selectedCat);
        setSelectedParentCategoryId(selectedCat.id);
      }
    }
  }, [dispatch, id, customer]);

  const [selectedParentCategoryId, setSelectedParentCategoryId] = useState<
    number | null
  >(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  useEffect(() => {
    setLoading(false);
    fetchData();

    // Format the start and end dates if they are provided as Dayjs objects
    if (customer) {
      const formattedStartDate = customer.start_date
        ? dayjs(customer.start_date).format("YYYY-MM-DD")
        : null;
      const formattedEndDate = customer.end_date
        ? dayjs(customer.end_date).format("YYYY-MM-DD")
        : null;
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
    } else {
      // If customer data doesn't exist, set default values for start and end dates
      setStartDate(null);
      setEndDate(null);
    }
  }, [dispatch]);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    setStartDate(formattedDate); // Update the state directly with the formatted date
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    setEndDate(formattedDate); // Update the state directly with the formatted date
  };
  const handleChangeParentName = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value as string;
    const selectedCat = bannerPlace.find((cat) => cat.name === selectedValue);
    setSelectedParentCategoryId(selectedCat ? selectedCat.id : null);
    setSelectedBAnnerPlace(selectedCat); // Update selected parent country state
  };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editBanner({
              id: customer.id || 0,
              data: {
                user_id: values.user_id,
                title: values.title,
                status: values.status,
                banner_place: values.banner_place,

                order: values.order,

                vacancy_order_id: values.vacancy_order_id,

                start_date: startDate,
                end_date: endDate,
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "ads/editBanner/fulfilled") {
              openSnackbar({
                open: true,
                message: "Banner edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(
            addBanners({
              user_id: values.user_id,
              title: values.title,
              status: values.status,
              banner_place: values.banner_place,

              order: values.order,

              vacancy_order_id: values.vacancy_order_id,

              start_date: startDate,
              end_date: endDate,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "ads/addBanners/fulfilled") {
              openSnackbar({
                open: true,
                message: "Banner added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }

        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  const { value, onChange, onBlur } = getFieldProps("");
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>{customer ? "Edit Banner" : "New Banner"}</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-user_id">
                          Company Name
                        </InputLabel>
                        <Select
                          id="customer-user_id"
                          displayEmpty
                          {...getFieldProps("user_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedUser = userName.find(
                              (user) => user.id === selectedId,
                            );
                            const selectedUserName = selectedUser
                              ? selectedUser.user_name
                              : "";
                            setFieldValue("user_id", selectedId);
                            setFieldValue("user_name", selectedUserName); // Set user name value to another field
                          }}
                          value={getFieldProps("user_id").value || ""}
                        >
                          {userName &&
                            userName.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.user_name}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.user_id && errors.user_id && (
                          <FormHelperText error>
                            {errors.user_id}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-vacancy_order_id">
                          Vacancy Order Id
                        </InputLabel>
                        <Select
                          id="customer-vacancy_order_id"
                          displayEmpty
                          {...getFieldProps("vacancy_order_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedPackage = Packages.find(
                              (pkg: any) => pkg.id === selectedId,
                            );
                            const selectedType = selectedPackage
                              ? selectedPackage.id
                              : "";
                            setFieldValue("vacancy_order_id", selectedId);
                            setFieldValue("vacancy_order_id", selectedType); // Set type value to another field
                          }}
                          value={getFieldProps("vacancy_order_id").value || ""}
                        >
                          {Packages &&
                            Packages.map((pkg: any) => (
                              <MenuItem key={pkg.id} value={pkg.id}>
                                {pkg.id}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.vacancy_order_id &&
                          errors.vacancy_order_id && (
                            <FormHelperText error>
                              {errors.vacancy_order_id}
                            </FormHelperText>
                          )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel htmlFor="customer-name">Title</InputLabel>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <TextField
                          fullWidth
                          id="customer-title"
                          placeholder="Enter Title"
                          {...getFieldProps("title")}
                          error={Boolean(touched.title && errors.title)}
                          helperText={touched.title && errors.title}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-status">
                          Status
                        </InputLabel>
                        <Select
                          id="customer-status"
                          displayEmpty
                          {...getFieldProps("status")}
                          onChange={(event: SelectChangeEvent<boolean>) => {
                            // Convert true to '1' and false to '0'
                            const selectedValue = event.target.value;

                            // Set the field value to the converted value
                            setFieldValue("status", selectedValue);
                          }}
                          // rest of the code
                        >
                          <MenuItem value={false}>Inactive</MenuItem>
                          <MenuItem value={true}>Active</MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-banner_place">
                          Banner Place
                        </InputLabel>
                        <Select
                          id="customer-parent_name"
                          displayEmpty
                          value={
                            selectedBannerPlace
                              ? selectedBannerPlace.name
                              : customer?.banner_place
                          }
                          onChange={handleChangeParentName}
                          onBlur={onBlur}
                          input={
                            <OutlinedInput
                              id="select-column-hiding"
                              placeholder="Sort by"
                            />
                          }
                          renderValue={(selected) => {
                            if (!selected) {
                              return (
                                <Typography variant="subtitle1"></Typography>
                              );
                            }
                            const cat = bannerPlace.find(
                              (item) => item.name === selected,
                            );
                            return (
                              <Typography variant="subtitle2">
                                {cat ? cat.name : ""}
                              </Typography>
                            );
                          }}
                        >
                          {bannerPlace.map((column: any) => (
                            <MenuItem key={column.id} value={column.name}>
                              <ListItemText primary={column.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-order">Order</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-order"
                          placeholder="order"
                          {...getFieldProps("order")}
                          error={Boolean(touched.order && errors.order)}
                          helperText={touched.order && errors.order}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-start_date">
                          Start Date
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={startDate ? dayjs(startDate) : null}
                            onChange={(date) => handleDateChange(date)}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-end_date">
                          End Date
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={endDate ? dayjs(endDate) : null}
                            onChange={(date) => handleEndDateChange(date)}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton
                        onClick={() => setOpenAlert(true)}
                        size="large"
                        color="error"
                      >
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? "Edit" : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {customer && (
        <AlertCountryDelete
          fetchData={fetchData}
          id={customer.id!}
          title={customer.title}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default FormBannerAdd;
