import { RefObject, useEffect, useState } from "react";
import { useOutletContext } from "react-router";

// material-ui
import {
  Autocomplete,
  Box,
  Button,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import type { DatePickerProps } from "antd";
import { DatePicker, Space } from "antd";
import AddIcon from "@mui/icons-material/Add";
// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project import
import { openSnackbar } from "api/snackbar";

import MainCard from "components/MainCard";
import { useAppDispatch } from "hooks";
import {
  addLanguage,
  getAllLanguage,
} from "store/actions/main/basicInfo/languages";
import { SnackbarProps } from "types/snackbar";
import { addNewLanguagesResumes } from "store/actions/main/cv/language/language";

// types
const validationSchema = Yup.object().shape({
  language_id: Yup.string().required("Language is required"),
  reading_level: Yup.string().required("Reading Level is required"),
  conversation_level: Yup.string().required("Conversation Level is required"),
  writing_level: Yup.string().required("Writing Level is required"),
});
function LanguageDialog({
  cvId,
  openLanguage,
  setOpenLanguages,
  setLoading,
  loading,
  fetchGetOneLang,
}: {
  setLoading: any;
  loading: any;
  fetchGetOneLang: any;
  cvId: any;
  openLanguage: boolean;
  setOpenLanguages: (openLanguage: boolean) => void;
}) {
  const handleClose = () => {
    setOpenLanguages(false);
  };

  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedLanguageId, setSelectedLanguageId] = useState("");
  const [selectedReading, setSelectedReading] = useState("");
  const [selectedConversation, setSelectedConversation] = useState("");
  const [selectedWriting, setSelectedWriting] = useState("");
  const dispatch = useAppDispatch();
  const fetchLanguageData = () => {
    dispatch(getAllLanguage({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setSelectedLanguage(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const handleLanguageChange = (event) => {
    const selectedEdId = event.target.value; // Get the selected ID
    setSelectedLanguageId(selectedEdId); // Set the selected ID to state
    const selectedItem = selectedLanguage.find(
      (item) => item.id === selectedEdId,
    );
    if (selectedItem) {
      const selectedName = selectedItem.name;
    }
  };
  useEffect(() => {
    fetchLanguageData();
  }, [dispatch]);
  const [formErrors, setFormErrors] = useState({});
  const validate = async () => {
    try {
      // Combine all data to be validated
      const dataToValidate = {
        language_id: selectedLanguageId,
        reading_level: selectedReading, // Include selected job availability ID
        writing_level: selectedWriting,
        conversation_level: selectedConversation,
      };

      // Validate the combined data against the validation schema
      await validationSchema.validate(dataToValidate, { abortEarly: false });
      setFormErrors({});
      return true; // Return true if validation succeeds
    } catch (errors) {
      // If validation fails, extract error messages
      const errorMessages = {};
      errors.inner.forEach((error) => {
        errorMessages[error.path] = error.message;
      });
      setFormErrors(errorMessages); // Set error messages for each field
      return false;
    }
  };
  const handleSave = async () => {
    // Log average value for debugging

    const isValid = await validate();
    if (isValid) setLoading(true);
    dispatch(
      addNewLanguagesResumes({
        resume_id: cvId,
        language_id: selectedLanguageId,
        reading_level: selectedReading,
        conversation_level: selectedConversation,
        writing_level: selectedWriting,
      }),
    ).then((action) => {
      if (action.type === "languages/addNewLanguagesResumes/fulfilled") {
        openSnackbar({
          open: true,
          message: "Education Added successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);
        setOpenLanguages(false);
        setLoading(false);
        fetchGetOneLang();
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
        setOpenLanguages(false);
        setLoading(false);
      }
    });
  };

  return (
    <Dialog open={openLanguage} onClose={handleClose}>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <DialogTitle style={{ fontWeight: "bold" }}>Languages</DialogTitle>
        <CloseIcon
          style={{
            marginTop: "12px",
            marginRight: "20px",
            width: "20px",
            height: "20px",
            cursor: "pointer",
          }}
          onClick={() => setOpenLanguages(false)}
        />
      </Stack>

      <DialogContent>
        <Box sx={{ p: 2.5 }}>
          <Grid container>
            <Grid item xs={12} sm={10}>
              <Stack spacing={1} marginBottom={"20px"}>
                <InputLabel htmlFor="personal-language_id">Language</InputLabel>
                <Select
                  id="language_id"
                  value={selectedLanguageId} // Set the selected ID as the value of the Select component
                  onChange={handleLanguageChange} // Handle the change event
                >
                  {selectedLanguage.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.language_id && (
                  <FormHelperText error>
                    {formErrors.language_id}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Stack direction={"row"}>
              <Grid item xs={12} width={"150px"} marginRight={"20px"}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="personal-reading_level">
                    Reading
                  </InputLabel>
                  <Select
                    id="reading_level"
                    value={selectedReading} // Set the selected ID as the value of the Select component
                    onChange={(event) => setSelectedReading(event.target.value)} // Handle the change event
                  >
                    <MenuItem value={"POOR"}>POOR</MenuItem>
                    <MenuItem value={"GOOD"}>GOOD</MenuItem>
                    <MenuItem value={"FLUENT"}>FLUENT</MenuItem>
                  </Select>
                  {formErrors.reading_level && (
                    <FormHelperText error>
                      {formErrors.reading_level}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} width={"150px"} marginRight={"20px"}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="personal-conversation_level">
                    Speaking
                  </InputLabel>
                  <Select
                    id="conversation_level"
                    value={selectedConversation} // Set the selected ID as the value of the Select component
                    onChange={(event) =>
                      setSelectedConversation(event.target.value)
                    } // Handle the change event
                  >
                    <MenuItem value={"POOR"}>POOR</MenuItem>
                    <MenuItem value={"GOOD"}>GOOD</MenuItem>
                    <MenuItem value={"FLUENT"}>FLUENT</MenuItem>
                  </Select>
                  {formErrors.conversation_level && (
                    <FormHelperText error>
                      {formErrors.conversation_level}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} width={"150px"} marginRight={"20px"}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="personal-writing_level">
                    Writing
                  </InputLabel>
                  <Select
                    id="writing_level"
                    value={selectedWriting} // Set the selected ID as the value of the Select component
                    onChange={(event) => setSelectedWriting(event.target.value)} // Handle the change event
                  >
                    <MenuItem value={"POOR"}>POOR</MenuItem>
                    <MenuItem value={"GOOD"}>GOOD</MenuItem>
                    <MenuItem value={"FLUENT"}>FLUENT</MenuItem>
                  </Select>
                  {formErrors.writing_level && (
                    <FormHelperText error>
                      {formErrors.writing_level}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
            </Stack>
          </Grid>
          <Stack display={"flex"} alignItems={"end"} marginTop={"20px"}>
            <Button type="submit" variant="contained" onClick={handleSave}>
              Add Language
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default LanguageDialog;
