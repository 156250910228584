const GET_ALL_COMPANY_COMMENTS = "company_comments";

const GET_ONE_COMPANY_COMMENTS = "company_comments/";

const EDIT_COMPANY_COMMENTS = "company_comments/";

const ADD_COPMANY_COMMENTS = "company_comments";

const DELETE_COMPANY_COMMENTS = "company_comments/";

const MULTI_DELETE_COMPANY_COMMENTS = "company_comments/remove/bulk_delete";

export default {
  GET_ALL_COMPANY_COMMENTS,
  GET_ONE_COMPANY_COMMENTS,
  EDIT_COMPANY_COMMENTS,
  ADD_COPMANY_COMMENTS,
  DELETE_COMPANY_COMMENTS,
  MULTI_DELETE_COMPANY_COMMENTS,
};
