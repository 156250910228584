import { useEffect, useState, ChangeEvent } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

// third-party
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project imports
import IconButton from "components/@extended/IconButton";
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets
import { DeleteFilled } from "@ant-design/icons";

// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";

import { addNewCompany, editCompany } from "store/actions/main/companies";
import AlertCompanyDelete from "./AlertResumeDelete";
import { PackagesResumes } from "model/packages";
import {
  addNewResumePacakges,
  editOneResume,
} from "store/actions/main/packages/resumesPackages";
import TextArea from "antd/es/input/TextArea";

// constant
const getInitialValues = (customer: PackagesResumes | null) => {
  const newCustomer = {
    number_cv: "",
    cost: 0,
    package_validity: 0,
    package_duration: 0,
    order: 0,
    note: "",
  };
  if (customer) {
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormResumeAdd = ({
  customer,
  id,
  fetchData,
  closeModal,
}: {
  id: number;
  fetchData: any;
  customer: PackagesResumes | null;
  closeModal: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const CustomerSchema = Yup.object().shape({
    number_cv: Yup.number().required("Cv Number is required"),
    cost: Yup.number().required("Cost is required"),
    package_validity: Yup.string().required("Validity is required"),
    order: Yup.number().required("order is required"),
  });

  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    //  validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editOneResume({ id: customer.id || 0, data: values }),
          ).then((action) => {
            fetchData();
            if (action.type === "packages/editOneResume/fulfilled") {
              openSnackbar({
                open: true,
                message: "Resume Package updated successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addNewResumePacakges(values)).then((action) => {
            fetchData();
            if (action.type === "packages/addNewResumePacakges/fulfilled") {
              openSnackbar({
                open: true,
                message: "Resume Package added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;
  const { value, onChange, onBlur } = getFieldProps("");
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>
              {customer ? "Edit Resume Package" : "New  Resume Package"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={10} marginLeft={"10%"}>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-number_cv">
                          Count Resume
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-number_cv"
                          placeholder="Enter Amount"
                          {...getFieldProps("amount")}
                          error={Boolean(touched.number_cv && errors.number_cv)}
                          helperText={touched.number_cv && errors.number_cv}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-cost">Fees</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-cost"
                          placeholder="Enter Price"
                          {...getFieldProps("price")}
                          error={Boolean(touched.cost && errors.cost)}
                          helperText={touched.cost && errors.cost}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-price_per_unit">
                          Fee Per Unit
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-price_per_unit"
                          placeholder="Enter Price Per Unit"
                          {...getFieldProps("price_per_unit")}
                          error={Boolean(touched.cost && errors.cost)}
                          helperText={touched.cost && errors.cost}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-name">
                          Validity
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-package_validity"
                          placeholder="Enter Validity"
                          {...getFieldProps("package_validity")}
                          error={Boolean(
                            touched.package_validity && errors.package_validity,
                          )}
                          helperText={
                            touched.package_validity && errors.package_validity
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-package_duration">
                          Duration
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-package_duration"
                          placeholder="Enter Duration"
                          {...getFieldProps("package_duration")}
                          error={Boolean(
                            touched.package_duration && errors.package_duration,
                          )}
                          helperText={
                            touched.package_duration && errors.package_duration
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-order">Order</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-order"
                          placeholder="Enter Order"
                          {...getFieldProps("order")}
                          error={Boolean(touched.order && errors.order)}
                          helperText={touched.order && errors.order}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-note">
                          English Note
                        </InputLabel>
                        <TextArea
                          id="customer-note"
                          placeholder="Note"
                          {...getFieldProps("note")}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-note">
                          Arabic Note
                        </InputLabel>
                        <TextArea
                          id="customer-note"
                          placeholder="ادخل الملاحظة"
                          {...getFieldProps("note")}
                          dir="rtl" // Set the direction to RTL
                          style={{ textAlign: "right" }} // Set text alignment to left
                          maxLength={100} // Set maximum length
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton
                        onClick={() => setOpenAlert(true)}
                        size="large"
                        color="error"
                      >
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? "Edit" : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {customer && (
        <AlertCompanyDelete
          fetchData={fetchData}
          id={customer.id!}
          title={customer.id}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default FormResumeAdd;
