import { CardHeader, Stack } from "@mui/material";

import { useState } from "react";
import { Language } from "model/cv/language/language";

import { TableQuestion } from "./TableQuestion";

const QuestionSection = ({
  cvId,
  basicInfo,
  setLoading,
  loading,
  openAnswre,
  setOpenAnswer,
  fetchGetOneQues,
}: {
  fetchGetOneQues: any;
  cvId: any;
  basicInfo: any;
  setLoading: any;
  loading: any;
  openAnswre: any;
  setOpenAnswer: (open: boolean) => void;
}) => {
  const [rows, setRows] = useState<Language[]>([
    {
      resume_id: 0,
      language_id: 0,
      reading_level: "",
      conversation_level: "",
      writing_level: "",
      // Assuming it can be null and it's a string (URL?)
    },
  ]);

  return (
    <>
      <Stack
        direction={"row"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "17px",
        }}
      >
        <Stack direction={"column"}>
          <CardHeader title="Mini Interview" />
          <Stack style={{ paddingLeft: "10px" }}>
            <TableQuestion
              cvId={cvId}
              coursCertificates={basicInfo}
              setLoadingQues={setLoading}
              loadingQues={loading}
              fetchGetOneQues={fetchGetOneQues}
            />
          </Stack>
        </Stack>
        {/* Other education-related fields */}
      </Stack>
    </>
  );
};

export default QuestionSection;
