import { useEffect, useState, ChangeEvent } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

// third-party
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// project imports
import IconButton from "components/@extended/IconButton";
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets
import { DeleteFilled } from "@ant-design/icons";

// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";

import AlertCompanyDelete from "./AlertVacancyDelete";
import Checkbox from "@mui/material/Checkbox";
import { getAllVacancies } from "store/actions/main/packages/vacanciesPackages";
import { getAllCompanies } from "store/actions/main/companies";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  addNewVacancySub,
  editOneVacancySub,
} from "store/actions/main/subscription/vacancySub";
import { VacancyOrder } from "model/order";
import {
  addNewVacancyOrder,
  editOneVacancyOrder,
} from "store/actions/main/order/vacancyOrder";
import AlertVacancyDelete from "./AlertVacancyDelete";

// constant
const getInitialValues = (customer: VacancyOrder | null) => {
  const newCustomer = {
    user_id: "",
    urgent: false,
    vacancy_package_ids: [],
    show_logo: false,
    show_in_best_place: false,
    payment_id: "",
    show_in_app: false,
    amount_vacancies: "",
    price: "",
    status: false,
  };
  if (customer) {
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormVacancyAdd = ({
  customer,
  id,
  userName,
  closeModal,
  fetchData,
  Packages,
  loading,
  setLoading,
}: {
  setLoading: any;
  loading: any;
  userName: any;
  Packages: any;
  id: number;
  fetchData: any;
  customer: VacancyOrder | null;
  closeModal: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const CustomerSchema = Yup.object().shape({
    user_id: Yup.string().required("Company Name is required"),
    vacancy_package_ids: Yup.array().required("Vacancy Package is required"),
    //show_logo: Yup.boolean().required("Package Validation is required"),
    //status: Yup.string().required("Status is required"),
    price: Yup.number().required("Price is required"),
    amount_vacancies: Yup.number().required("Amount Vacancies is required"),
  });

  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      const vacancyPackageIdsArray = Array.isArray(values.vacancy_package_ids)
        ? values.vacancy_package_ids
        : [values.vacancy_package_ids];
      try {
        if (customer) {
          await dispatch(
            editOneVacancyOrder({
              id: customer.id || 0,
              data: {
                user_id: values.user_id,
                vacancy_package_ids: vacancyPackageIdsArray,
                show_logo: values.show_logo,
                show_in_best_place: values.show_in_best_place,
                payment_id: values.payment_id,
                show_in_app: values.show_in_app,
                amount_vacancies: values.amount_vacancies,
                price: values.price,
                urgent: values.urgent,
                status: values.status,
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "order/editOneVacancyOrder/fulfilled") {
              openSnackbar({
                open: true,
                message: "Vacancy Orders Updated successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            } else {
              openSnackbar({
                open: true,
                message: "some thing went wrong",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "error",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(
            addNewVacancyOrder({
              user_id: values.user_id,
              vacancy_package_ids: vacancyPackageIdsArray,
              show_logo: values.show_logo,
              show_in_best_place: values.show_in_best_place,
              payment_id: values.payment_id,
              show_in_app: values.show_in_app,
              amount_vacancies: values.amount_vacancies,
              price: values.price,
              urgent: values.urgent,
              status: values.status,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "order/addNewVacancyOrder/fulfilled") {
              openSnackbar({
                open: true,
                message: "Vacancy Orders deleted successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            } else {
              openSnackbar({
                open: true,
                message: "some thing went wrong",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "error",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  const { value, onChange, onBlur } = getFieldProps("");

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>
              {customer ? "Edit Vacancy Order" : "New Vacancy Order"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={10} marginLeft={"10%"}>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-user_id">
                          Company Name
                        </InputLabel>
                        <Select
                          id="customer-user_id"
                          displayEmpty
                          {...getFieldProps("user_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedUser = userName.find(
                              (user) => user.id === selectedId,
                            );
                            const selectedUserName = selectedUser
                              ? selectedUser.user_name
                              : "";
                            setFieldValue("user_id", selectedId);
                            setFieldValue("user_name", selectedUserName); // Set user name value to another field
                          }}
                          value={getFieldProps("user_id").value || ""}
                        >
                          {userName &&
                            userName.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.user_name}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.user_id && errors.user_id && (
                          <FormHelperText error>
                            {errors.user_id}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12}>
                        <InputLabel>Packages</InputLabel>
                        <FormGroup row>
                          <Grid container spacing={2}>
                            {Packages.map((pack: any) => (
                              <Grid item key={pack.id}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={formik.values.vacancy_package_ids.includes(
                                        pack.id,
                                      )}
                                      onChange={(event) => {
                                        const isChecked = event.target.checked;
                                        const packageId = pack.id;
                                        const updatedPackageIds = isChecked
                                          ? [
                                              ...formik.values
                                                .vacancy_package_ids,
                                              packageId,
                                            ]
                                          : formik.values.vacancy_package_ids.filter(
                                              (id: number) => id !== packageId,
                                            );
                                        formik.setFieldValue(
                                          "vacancy_package_ids",
                                          updatedPackageIds,
                                        );
                                      }}
                                    />
                                  }
                                  label={pack.type}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </FormGroup>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-amount_vacancies">
                          Count Vacancies
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-amount_vacancies"
                          placeholder="Amount Vacancies"
                          {...getFieldProps("amount_vacancies")}
                          error={Boolean(
                            touched.amount_vacancies && errors.amount_vacancies,
                          )}
                          helperText={
                            touched.amount_vacancies && errors.amount_vacancies
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-price">Price</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-price"
                          placeholder="Enter Price"
                          {...getFieldProps("price")}
                          error={Boolean(touched.price && errors.price)}
                          helperText={touched.price && errors.price}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-payment_id">
                          Payment
                        </InputLabel>
                        <Select
                          id="customer-payment_id"
                          displayEmpty
                          {...getFieldProps("payment_id")}
                          onChange={(event: SelectChangeEvent<boolean>) => {
                            // Convert true to '1' and false to '0'
                            const selectedValue = event.target.value;
                            // Set the field value to the converted value
                            setFieldValue("payment_id", selectedValue);
                          }}
                          // rest of the code
                        >
                          <MenuItem value={"1"}>1</MenuItem>
                          <MenuItem value={"1"}>1</MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-type">Status</InputLabel>
                        <Select
                          id="customer-status"
                          displayEmpty
                          {...getFieldProps("status")}
                          onChange={(event: SelectChangeEvent<boolean>) => {
                            // Convert true to '1' and false to '0'
                            const selectedValue = event.target.value;

                            // Set the field value to the converted value
                            setFieldValue("status", selectedValue);
                          }}
                          // rest of the code
                        >
                          <MenuItem value={true}>ACTIVE</MenuItem>
                          <MenuItem value={false}>INACTIVE</MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel>Urgent</InputLabel>
                        <RadioGroup
                          row
                          value={getFieldProps("urgent").value}
                          onChange={(event) => {
                            setFieldValue("urgent", event.target.value);
                          }}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="true"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="false"
                          />
                        </RadioGroup>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel>Show in App</InputLabel>
                        <RadioGroup
                          row
                          value={getFieldProps("show_in_app").value}
                          onChange={(event) => {
                            setFieldValue("show_in_app", event.target.value);
                          }}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="true"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="false"
                          />
                        </RadioGroup>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel>Show Logo</InputLabel>
                        <RadioGroup
                          row
                          value={getFieldProps("show_logo").value}
                          onChange={(event) => {
                            setFieldValue("show_logo", event.target.value);
                          }}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="true"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="false"
                          />
                        </RadioGroup>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel>Show in Best Place</InputLabel>
                        <RadioGroup
                          row
                          value={getFieldProps("show_in_best_place").value}
                          onChange={(event) => {
                            setFieldValue(
                              "show_in_best_place",
                              event.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="true"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="false"
                          />
                        </RadioGroup>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton
                        onClick={() => setOpenAlert(true)}
                        size="large"
                        color="error"
                      >
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? "Edit" : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {customer && (
        <AlertVacancyDelete
          fetchData={fetchData}
          id={customer.id!}
          title={customer.id}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default FormVacancyAdd;
